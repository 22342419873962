:root {
  /* --primary-color: #403f83;
  --primary-color-rgb: 64, 63, 131;

  --secondary-color: #8364E2;
  --secondary-color-rgb: 131, 100, 226; */

  /* --secondary-color: rgb(255 184 0 / 80%); */
  --secondary-color: #D63384;
  --secondary-color-rgb: 38, 59, 128;

  --primary-color: #50FDDB;
  --primary-color-rgb: 80, 253, 219;

  --button-primary-bg: linear-gradient(94.51deg, #FFB800 -15.25%, #F35AF6 122.2%);
}
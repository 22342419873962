/* @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  
  .rotating-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .rotating-image {
    animation: rotate 1s linear infinite; 
  }
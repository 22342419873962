/* ================================================== */

/* GIGALAND
/* created by designesia

@charset "utf-8";

/* ================================================== */

/* import custom fonts */

/* ================================================== */

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap'); */

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/tt-commons/TT-Commons-Regular.woff2') format('woff2'),
    url('../fonts/tt-commons/TT-Commons-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/tt-commons/TT-Commons-Medium.woff2') format('woff2'),
    url('../fonts/tt-commons/TT-Commons-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/tt-commons/TT-CommonsBold.woff2') format('woff2'),
    url('../fonts/tt-commons/TT-Commons-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
    font-family: 'TT Commons Regular';
    src: url("../fonts/tt-commons/TT-Commons-Regular.woff") format('woff');
  }
@font-face {
    font-family: 'TT Commons Medium';
    src: url("../fonts/tt-commons/TT-Commons-Medium.woff") format('woff');
  }
@font-face {
    font-family: 'TT Commons Bold';
    src: url("../fonts/tt-commons/TT-Commons-Bold.woff") format('woff');
  } */

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@200;300;400;500;600;700;800&display=swap'); */

/* ================================================== */

/* import fonts icon */

/* ================================================== */

@import url('../fonts/font-awesome/css/font-awesome.css');
/* @import url("../fonts/elegant_font/HTML_CSS/style.css");
@import url("../fonts/et-line-font/style.css"); */
@import url('../fonts/icofont/icofont.min.css');
/* @import url("../fonts/brockmann/stylesheet.css"); */
@import url('./footer.css');

* {
  --body-font: 'TT Commons';
  --title-font: 'TT Commons';
  --dark-scheme-color: #181818;
  /* --dark-scheme-color: rgb(25, 28, 31); */
  --dark-scheme-color-2: #0d1250;
  --dark-scheme-font-color: #999999;
  --light-bg: #fcfcfc;
  --light-bg-hover: #f5f5f5;
  --stroke-clr: #f0f0f0;
  --body-txt-clr: #9b9b9b;
  --radius-lg: 24px;
  --radius-md: 16px;
  --radius-sm: 12px;
  --primary-brand: #03d9af;
  --move-mint: #50fddb;
  --pot-black: #a02f2f;
  --verified-black: #242424;
  --verified-bg-black: #242424;
  --dark-tone-ink: #121212;
  --vanta-black: #010101;
  --light-house: #f4f4f4;
  --snow-flake: #f0f0f0;
  --white-smoke: #f1f1f1;
  /* --accent-hover: rgb(255 184 0 / 80%); */
  --accent-hover: #d63384;
  --cascading-white: #f6f6f6;
  --emptiness: #fcfcfc;
  --hydrargyrum: #9b9b9b;
  --warpfiend-gray: #6c6d74;
  --orochimaru: #d9d9d9;
  --white: #fff;
  --black: #000;
  --input-border: rgba(238, 241, 249, 0.1);
  --black-rgb: 0, 0, 0;
  --white-rgb: 255, 255, 255;
  --hydrargyrum-rgb: 155, 155, 155;
  --verified-black-rgb: 36, 36, 36;
  --box-shadow-color: rgba(20, 20, 20, 0.1);
  /* +++ THEME BASE COLOR PALETTE +++ */
  --font-clr: var(--verified-black);
  --background-bg: var(--emptiness);
}

.dark-scheme {
  --font-clr: var(--cascading-white);
  --background-bg: var(--vanta-black);
}

/* ================================================== */

/* body */

/* ================================================== */

/* html {
    padding: 0;
    margin: 0;
    background: #fff;
} */

html {
  scroll-behavior: smooth;
  scrollbar-width: auto;
  /* scrollbar-color: #263B80 #111; */
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  border-radius: 8px;
  box-shadow:
    inset 2px 2px 2px linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%),
    inset -2px -2px 2px linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg, 111, 111 1px, #ffb800 -15.25% 0, #f35af6 122.2%);
}

body {
  overflow-x: hidden;
  overflow: overlay;
  scroll-behavior: smooth;
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 400;
  color: #727272;
  line-height: 30px;
  padding: 0;
  line-height: 26px;
  word-spacing: 0px;
}

.dark-scheme {
  color: var(--dark-scheme-font-color);
}

body.dark-scheme {
  background: var(--dark-scheme-color);
}

.container {
  max-width: 1660px !important;
  padding: 0;
}

.col-left-side {
  flex: 0 0 auto;
  width: 380px;
}

.col-right-side {
  flex: 1;
}

.col-item-card {
  flex: 0 0 auto;
  width: 20%;
  margin-bottom: 16px;
  position: relative;
}
.col-item-card.card_width {
  width: 25%;
}

.col-item-card:hover,
.d-item:hover {
  transform: translateY(-5px);
}
.selected-nft .nft__item{
  border:2px solid #F673B3;
}
.col-item-card .hover_contract,
.d-item .hover_contract,.listing_list .hover_contract{
  opacity: 0;
  position: absolute;
  top: 16px;
  right: 28px;
  background: rgb(112 112 112 / 70%);
  border-radius: 100px;
  width: max-content;
  padding: 4px 10px;
  color: #fff;
}
.listing_list .hover_contract{
  top: 18px;
  right: 60%;
  min-width: 87px;
  text-align: center;
}
.col-item-card:hover .hover_contract,
.d-item:hover .hover_contract,.listing_list:hover .hover_contract {
  opacity: 1;
}

.col-item-card,
.d-item {
  transition: all 0.2s linear;
}
.disabled_cart {
  opacity: 0.7;
  pointer-events: none;
}
.disabled_none {
  opacity: 0.7;
  pointer-events: none;
}
.see_more {
  color: #9247ff;
}

body.boxed {
  margin: 30px;
  overflow: hidden;
  border: solid 1px #eee;
}

body .owl-stage .owl-item {
  margin-bottom: 15px;
}

#wrapper {
  overflow: clip;
}

.btn-main:hover {
  background: linear-gradient(94.51deg, rgb(255 184 0 / 80%) -15.25%, rgb(243 90 246 / 80%) 122.2%);
}
.btn-main:disabled {
  opacity: 0.75;
  pointer-events: none;
  /* background: linear-gradient(
    94.51deg,
    rgba(211, 208, 201, 0.8) -15.25%,
    rgba(202, 178, 202, 0.8) 122.2%
  ); */
}
.dark-scheme .btn-main:disabled {
  opacity: 0.75;
  pointer-events: none;
}
.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

.de-navbar-left #wrapper {
  overflow: visible;
}

a,
a:hover {
  text-decoration: none;
}

a.a-underline {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

a.a-underline span {
  display: block;
  position: relative;
  left: 0;
  bottom: 0;
  border-bottom: solid 1px #333;
  width: 0;
}

a.a-underline:hover span {
  width: 100%;
}

.list-none {
  margin: 0;
  padding: 0;
  list-style: none;
}

.glb-viewer-style {
  width: 100%;
  height: 100%;
  padding: 50px;
}

.inner-content label {
  font-weight: 600;
  color: #000;
  margin: 0 0 6px;
}

.inner-content input {
  height: 50px;
  border-radius: 8px;
  border: 1.5px solid #e0e9f2;
  background: rgba(241, 245, 249, 0.2);
}

.dark-scheme .inner-content input {
  border: 1.5px solid rgba(238, 241, 249, 0.1);
  background: rgba(241, 245, 249, 0.05);
  color: #fff;
}

.dark-scheme .inner-content label {
  color: #fff;
}

.w-600 {
  max-width: 600px;
  width: 100%;
}
.w-500 {
  max-width: 500px;
  width: 100%;
}

.h-600 {
  height: 600px;
}

.w-560 {
  max-width: 560px;
  width: 100%;
}

.modal-content {
  border-radius: 20px !important;
}

.transparent-bg-modal {
  background-color: #00000066;
  border-radius: 0 !important;
}

.add-btn {
  height: 44px;
}

.trait-holder {
  background: #eee;
  border-radius: 8px;
  height: 50px;
}

.dark-scheme .trait-holder {
  background: rgba(241, 245, 249, 0.05);
  border-radius: 8px;
}

.ml-auto {
  margin-left: auto;
}

/* ================================================== */

/*  header  */

/* ================================================== */

.top-menu-bar {
  position: fixed;
  overflow-y: auto;
  padding: 0 20px 15px !important;
  width: 100%;
  left: -100%;
  height: 100% !important;
  top: 0;
  background: var(--white) !important;
  transition: 0.25s;
  z-index: 1031;
}

.dark-scheme .top-menu-bar {
  background: #070a15 !important;
}

.top-menu-bar #logo svg {
  margin-right: 20px;
}

.top-menu-bar.active {
  top: 0;
  left: 0;
  transition: 0.25s;
}

.dark-scheme .top-menu-bar #logo svg path {
  fill: #fff;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.listing-nav-mob {
  height: calc(100% - 100px);
}

header {
  width: 100%;
  position: relative;
  left: 0;
  z-index: 1001;
  background: #2b313f;
  margin: 0;
  top: 0;
  position: sticky;
  transition: 0.5s linear;
}

header.smaller.has-topbar {
  height: 105px;
}

header.header-s1 {
  top: 0;
}

header .info {
  display: none;
  color: #fff;
  padding: 10px 0 10px 0;
  width: 100%;
  z-index: 100;
  background: #242424;
}

header .info .col {
  display: inline-block;
  font-size: 12px;
}

header .info .col {
  padding-right: 30px;
}

header .info .col:last-child {
  padding-right: 0px;
}

header .info i {
  font-size: 14px;
  margin-right: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

header .info .social.col {
  border: none;
}

header .info .social i {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  float: none;
  padding: 0 10px 0 10px;
  margin: 0;
}

header .info strong {
  font-size: 12px;
}

header.header-bg {
  background: rgba(0, 0, 0, 0.5);
}

header.transparent {
  background: none;
}

body:not(.side-layout) header:not(.smaller):not(.header-mobile).header-light.transparent {
  background: rgba(255, 255, 255, 0);
}

header.autoshow {
  top: -120px;
  height: 70px !important;
}

header.autoshow.scrollOn,
header.autoshow.scrollOff {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

header.autoshow.scrollOn {
  top: 0;
}

header.autoshow.scrollOff {
  top: -120px;
}

header div#logo {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.dark-scheme header .logo {
  display: block;
}

header .logo-scroll {
  display: none;
}

header .logo,
header .logo-2 {
  font-family: var(--body-font);
  color: #fff;
  font-size: 40px;
  font-weight: 300;
}

header .logo-2 {
  color: #333;
}

header.header-full nav {
  float: left;
}

header nav a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

header nav a:hover {
  color: #555;
}

header.smaller #mainmenu ul ul {
  top: 0px;
}

header.smaller {
  background: rgba(0, 0, 0, 0.8);
  /* top: 0px; */
  position: sticky;
  /* padding: 0; */
  /* position: fixed; */
  /*
    -webkit-box-shadow: 0 4px 6px 0 rgba(10,10,10, 0.05);
    -moz-box-shadow: 0 4px 6px 0 rgba(10,10,10, 0.05);
    box-shadow: 0 4px 6px 0 rgba(10,10,10, 0.05);
    */
  border: 0;
}

.dark-scheme .smaller {
  backdrop-filter: blur(20px);
  /* background: rgba(25, 28, 31, 0.85) !important; */
  background: rgb(24 24 24 / 50%) !important;
}

/* header.smaller.header-light,
header.header-mobile.header-light {
    border-bottom: solid 1px #dddddd;
} */
.dark-scheme .smaller.header-light,
header.header-mobile.header-light {
  border-bottom: none;
}

header.smaller .btn-rsvp {
  height: 100px;
  padding-top: 55px;
}

.boxed header.smaller {
  margin-top: 0px;
}

header.smaller.scroll-light {
  background: rgb(255 255 255 / 50%);
  backdrop-filter: blur(20px);
}

header.smaller.scroll-dark {
  background: var(--dark-scheme-color);
}

header.header-light .h-phone,
header.smaller.scroll-light .h-phone {
  color: #202020;
}

header:not(.header-light).smaller.scroll-light div#logo .logo {
  display: none;
}

header.smaller.scroll-light div#logo .logo-2 {
  display: inline-block;
}

header.smaller.scroll-light div#logo .logo-scroll {
  display: inline-block;
}

/* header.smaller.header-light div#logo .logo {
    display: none;
} */

header.smaller.scroll-light .social-icons i {
  color: #333;
}

header.smaller.scroll-light #mainmenu li li a:hover {
  color: #ffffff;
}

.wide header {
  padding-left: 30px;
  padding-right: 30px;
}

.logo-smaller div#logo {
  width: 150px;
  height: 30px;
  line-height: 65px;
  font-size: 30px;
}

.suggest_box_holder strong {
  font-size: 21px;
  color: #000;
}

.dark-scheme .suggest_box_holder strong {
  color: #fff;
}

.suggest_box_holder a {
  color: #727273e5 !important;
}

.dark-scheme .suggest_box_holder a {
  color: #fff !important;
}

.dark-scheme .suggest_box_holder > div > div {
  background: #212121 !important;
  border: 1.289px solid rgba(238, 241, 249, 0.1) !important;
}

.suggest_box_holder > div > div::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.dark-scheme .suggest_box_holder > div > div li:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.dark-scheme .suggest_box_holder > div > div li {
  background: transparent !important;
}

.logo-smaller div#logo img {
  font-size: 30px;
}

.logo-smaller div#logo .logo-1 {
  display: none;
}

.logo-smaller div#logo .logo-2 {
  display: inline-block;
}

.logo-small {
  margin-bottom: 20px;
}

header.de_header_2 {
  height: 125px;
}

header.de_header_2.smaller {
  height: 70px;
}

header.de_header_2.smaller .info {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

header.header-full {
  padding-left: 40px;
  padding-right: 40px;
}

header .header-row {
  text-align: center;
}

header .header-col {
  position: relative;
  height: 100%;
}

/* left col header */
header .header-col.left {
  float: left;
  padding: 0 30px 0 0;
  /* border-right:solid 1px rgba(255,255,255,.2); */
}

/* middle col header */
header .header-col.mid {
  float: right;
}

header .header-col.mid .social-icons {
  float: right;
  /* border-left:solid 1px rgba(255,255,255,.2); */
  padding: 0 30px 0 30px;
}

.header-col-right {
  text-align: right;
}

.header-fullwidth {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.de-navbar-left {
  background: #151618;
}

.de-navbar-left #logo {
  padding: 40px;
  text-align: center;
  width: 100%;
}

.de-navbar-left header {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  background: none;
  padding: 30px;
  padding-right: 20px;
}

.de-navbar-left header #mainmenu {
  float: none;
  margin: 0;
  padding: 0;
}

.de-navbar-left header #mainmenu > li {
  display: block;
  margin: 0;
  float: none;
  text-align: center;
  font-family: var(--body-font);
  letter-spacing: 5px;
  font-size: 14px;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.de_light.de-navbar-left header #mainmenu > li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.de-navbar-left header #mainmenu > li > a {
  padding: 0;
  opacity: 0.5;
}

.de_light.de-navbar-left header #mainmenu > li > a {
  color: #222;
}

.de-navbar-left header #mainmenu > li a:hover {
  opacity: 1;
}

.de-navbar-left header #mainmenu > li > a:after {
  content: none;
}

.de-navbar-left header nav {
  float: none;
}

/* subheader */
.collection-detail-holder {
  margin: 0 0 20px;
}

#subheader {
  text-align: center;
  overflow: hidden;
  padding: 90px 0;
}

#subheader .top-content {
  text-align: left;
}

.top-content h3 {
  margin: 0;
}

.explore_page_social {
  position: absolute;
  right: 80px;
  bottom: 30px;
}

.top-content img {
  max-width: 160px;
  min-width: 160px;
  width: 100%;
  max-height: 160px;
  min-height: 160px;
  height: 100%;
  background: #e8e8e8;
  align-self: self-start;
  border-radius: 100px;
  margin: 0 0 14px;
  object-fit: cover;
  object-position: center;
  margin-right: 20px;
  margin-top: -80px;
  border: 4px solid #fff;
}
.dark-scheme .top-content img {
  background: #181818;
  border: 4px solid #181818;
}

.opacity-5 {
  opacity: 0.75;
}

.text-heading-md {
  font-size: 28px;
}

.text-md {
  font-size: 16px;
  line-height: 16px;
}

#subheader h1 {
  margin-top: 50px;
  margin-bottom: 10px;
}

#subheader.dark {
  background-color: #1a1c26;
}

#subheader.dark h1 {
  color: #fff;
}

.de-navbar-left #subheader {
  padding-bottom: 120px;
}

#subheader .crumb {
  font-size: 14px;
  letter-spacing: 3px;
}

#subheader.text-light .crumb {
  color: #fff;
}

#subheader .small-border-deco {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

#subheader .small-border-deco span {
  display: block;
  margin: 0 auto;
  height: 2px;
  width: 100px;
}

#subheader.dark h2,
#subheader.dark h4 {
  color: #fff;
}

#subheader.dark h2 {
  font-size: 48px;
}

#subheader.s2 {
  background: rgba(var(--secondary-color-rgb), 0.1);
  text-align: left;
  padding: 120px 0 60px;
  border: solid 1px #eee;
  border-left: none;
  border-right: none;
}

#subheader.s2 h1,
#subheader.s2 .crumb {
  margin: 0;
  padding: 0;
}

#subheader.s2 .crumb {
  float: right;
  margin-top: 15px;
}

.de-navbar-left.de_light #subheader h1 {
  color: #222;
}

#profile_banner {
  min-height: 360px;
}

.banner-overlay-color {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.collection-detail-page .description {
  color: #4c4c4c;
  /* text-align: left; */
  max-width: 100%;
  flex: 1;
  font-size: 19px;
}

.w-65 {
  width: 65%;
}

.dark-scheme .collection-detail-page .description {
  color: rgb(255 255 255 / 70%);
}

.single_collection_detail {
  max-width: 630px;
  margin: 0 auto;
}

.single_collection_detail input.switch_btn {
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 35px !important;
  height: 20px;
  border-radius: 30px;
  cursor: pointer;
  background: #ccc;
  transition:
    box-shadow 0.3s ease-in-out,
    padding 0.25s ease-in-out;
  transition-delay: 0.1s, 0s;
}

.single_collection_detail input.switch_btn_btn:focus {
  border: 0;
  outline: 0;
}

.single_collection_detail input.switch_btn:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 2.62857em #03d9af !important;
  padding-left: 15px;
}

.single_collection_detail input.switch_btn:before {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 3px;
  background-color: #fff;
  border-radius: 2.1em;
  transition: all 0.1s ease 0.1s;
}

.single_collection_detail input.switch_btn:before,
input.switch_btn:after {
  content: '';
  display: block;
}

.single_collection_detail input.switch_btn:active::before {
  transition: all 0.1s ease 0.05s;
}

.marketplace_trade {
  padding: 14px;
  border: 1px solid #eee;
  border-radius: 14px;
}

h5.text-danger.fw-normal.m-0.mt-2.p-0 {
  min-height: 21.59px;
  font-size: 18px;
}

.dark-scheme .marketplace_trade {
  border: 1px solid rgba(155, 155, 155, 0.3);
  color: #fff;
}

.border-1 {
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}

.dark-scheme .border-1 {
  border-bottom: 1px solid rgba(155, 155, 155, 0.3);
  padding-bottom: 6px;
}

.image-holder-collection {
  max-width: 350px;
  height: 250px;
  margin: 0 auto;
}

.image-holder-collection img {
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  object-fit: contain;
  object-position: center;
}

.collection-detail-page .description_overflow {
  overflow-y: auto;
  /* min-height: 160px; */
  max-height: 160px;
  /* height: 100%; */
  white-space: pre-wrap;
  padding-right: 8px;
}

.custom_toastify_1 {
  max-width: 440px;
  width: 100%;
}

.custom_toastify_1 .Toastify__progress-bar {
  background: #ffb800 !important;
}

.custom_toastify_1 .Toastify__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 2px solid #727273;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  display: flex;
  padding-left: 2px;
  align-items: center;
  justify-content: center;
}

.dark-scheme .custom_toastify_1 .Toastify__close-button {
  border: 2px solid #fff;
}

.dark-scheme .custom_toastify_1 .Toastify__close-button > svg {
  fill: #fff;
}

.custom_toastify_1 .Toastify__close-button > svg {
  fill: #727273;
}

.custom_toastify_1 .Toastify__close-button--light {
  opacity: 0.8;
}

.custom_toastify {
  border-radius: 5px;
  border: 1.236px solid rgba(229, 232, 240, 0.9);
  background: #eee;
  color: #727273;
  font-size: 18px;
  padding: 24px;
}

.dark-scheme .custom_toastify {
  background: #1f222c;
  color: #fff;
  border: 1px solid rgba(238, 241, 249, 0.01);
}

.collection-detail-page .description_overflow::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.detail_page_description::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.collection-detail-page .description_overflow::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb,
.detail_page_description::-webkit-scrollbar-thumb {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  border-radius: 8px;
  box-shadow:
    inset 2px 2px 2px linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%),
    inset -2px -2px 2px linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
}

.collection-detail-page .description_overflow::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track,
.detail_page_description::-webkit-scrollbar-track {
  background: linear-gradient(90deg, 111, 111 1px, #ffb800 -15.25% 0, #f35af6 122.2%);
}

.collection-detail-page #subheader {
  position: absolute;
  background: black;
  top: 0;
  bottom: 0;
  height: 100%;
}

.collection-detail-page .collection-detail_image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  object-fit: cover;
  opacity: 0.8;
  object-position: center center;
}

.text-heading-sm {
  font-size: 20px;
  min-height: 26px;
  color: #121212;
  font-weight: 700;
}

.dark-scheme .text-heading-sm {
  color: #fff;
}

.collection-detail-page .meta-info {
  /* align-items: flex-end; */
  margin: 0 0 12px;
  padding-top: 15px;
}

.nft_name_list {
  width: 70%;
  min-height: 43px;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.collection-detail-page .meta-info ul {
  gap: 30px;
  color: #828282;
  font-weight: 600;
  margin: 0 0 10px;
}

.pl-0 {
  padding-left: 0;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

/* ================================================== */

/* navigation */

/* ================================================== */

#mainmenu {
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto;
  float: none;
}

#mainmenu ul {
  margin: 0px 0px;
  padding: 0px;
  height: 30px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
}

#mainmenu ul:not(.mega) {
  -webkit-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  -moz-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}

#mainmenu ul.explore_list {
  height: auto;
  background: transparent;
  box-shadow: none;
  position: relative;
  visibility: visible;
  opacity: 1;
}
.dark-scheme #mainmenu ul.explore_list {
  background: transparent;
}
#mainmenu ul.explore_list li {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0 10px 5px;
}
#mainmenu ul.explore_list li a {
  color: #000 !important;
  font-weight: 600 !important;
}
.dark-scheme #mainmenu ul.explore_list li a {
  color: #fff !important;
}
#mainmenu ul.explore_list li:hover {
  background-color: #f5f5f5;
  border-radius: 6px;
}
.dark-scheme #mainmenu ul.explore_list li:hover {
  background-color: rgba(255, 255, 255, 0.02);
}
.dark-scheme #mainmenu ul.explore_list li {
  color: #fff;
}
.dark-scheme #mainmenu ul.explore_list li svg path {
  fill: #fff;
}
.dark-scheme #mainmenu ul.explore_list li svg path.users_path {
  stroke: #fff;
  fill: transparent;
}
.soon-btn {
  padding: 2px 10px !important;
  font-size: 13px !important;
  line-height: 1 !important;
}
#mainmenu ul.explore_list li:last-child {
  margin: 0;
}
/* #mainmenu .explore_dropdown{
  display: none;
} */
.explore_dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: #444444;
  border-radius: 8px;
  border: 1.236px solid rgba(229, 232, 240, 0.9);
  background: #fff;
  padding: 12px 20px;
  font-weight: 500;
  width: 218px;
  display: none;
}
.explore_dropdown ul li a{
  padding: 0 !important;
  color: #000 !important;
}
.dark-scheme .explore_dropdown {
  background: #212121;
  border: 1.289px solid rgb(19 19 19 / 0.1%);
}
#mainmenu .explore_item:hover .explore_dropdown {
  display: block;
}
.userprofile_dropdown{
  position: relative;
  height: 100%;
  border: 2px solid transparent;
}
.userprofile_dropdown .explore_dropdown {
  top: 41px !important;
  right: -70px !important;
}

.userprofile_dropdown:hover .explore_dropdown {
  display: block;
}
.dark-scheme #mainmenu ul {
  background: #21273e;
}

#mainmenu li {
  margin: 0px 0px;
  padding: 0px 0px;
  display: inline;
  list-style: none;
  position: relative;
}

#mainmenu li.has-child:after {
  font-family: FontAwesome;
  content: '\f078';
  color: rgba(255, 255, 255, 0.5);
  padding-left: 5px;
  font-size: 8px;
  position: relative;
  top: -2px;
}

#mainmenu .mega li.has-child:after {
  display: none;
}

.rtl #mainmenu li.has-child:after {
  left: 12px;
}

.header-light #mainmenu li.has-child:after {
  color: rgba(0, 0, 0, 0.5);
}

#mainmenu li > a {
  padding-right: 6px;
  font-weight: 800;
}

#mainmenu li.has-child > a {
  padding-right: 0px;
}

#mainmenu a {
  position: relative;
  display: inline-block;
  padding: 26px 18px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  outline: none;
}

#mainmenu a span {
  position: relative;
  width: 0%;
  color: #ffffff;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  border-bottom: solid 2px #ffffff;
}

#mainmenu li:hover a span {
  width: 100%;
}

#mainmenu li li a span {
  float: right;
  margin-top: 5px;
}

#mainmenu ul li a strong {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1em;
  font-size: 10px;
  margin-right: 5px;
  padding: 3px 4px;
  padding-bottom: 2px;
  border: solid 1px #ffffff;
  display: inline-block;
  color: #ffffff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

#mainmenu a.active span {
  width: 100%;
  margin-left: 0%;
}

/* .header-light {
    border-bottom: solid 1px rgba(255, 255, 255, 0);
} */
.dark-scheme .header-light {
  border-bottom: none;
}

.header-light #mainmenu > li > a {
  color: #222;
}

.dark-scheme .header-light #mainmenu > li > a {
  color: #fff;
}

#mainmenu li li {
  font-family: var(--body-font);
  font-size: 14px;
}

#mainmenu li li:last-child {
  border-bottom: none;
}

#mainmenu li li a {
  padding: 5px 15px;
  border-top: none;
  color: #606060;
  width: 100%;
  border-left: none;
  text-align: left;
  font-weight: normal;
  /*border-bottom: solid 1px rgba(255, 255, 255, .1);*/
}

#mainmenu li:last-child > a {
  border-bottom: none;
}

.dark-scheme #mainmenu li li a {
  color: #ffffff;
}

.rtl #mainmenu li li a {
  text-align: right;
}

#mainmenu li li a:hover {
  color: #111;
}

#mainmenu li li a:after {
  content: none;
}

#mainmenu li li li a {
  padding: 2px 15px 2px 15px;
  background: #171a21;
}

/* #mainmenu li li a:hover,
#mainmenu ul li:hover > a {
  color: #fff;
  background: #1a8b49;
} */

#mainmenu li ul {
  width: 180px;
  height: auto;
  position: absolute;
  left: 0px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
}

#mainmenu li li {
  font-size: 14px;
  display: block;
  float: none;
  text-transform: none;
}

#mainmenu li:hover > ul {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
}

#mainmenu li ul ul {
  left: 100%;
  top: 0px;
}

#mainmenu li ul ul li a {
  background: #ffffff;
}

.dark-scheme #mainmenu li ul ul li a {
  background: #21273e;
}

#mainmenu li:hover > ul {
  color: #1a8b49;
}

#mainmenu select {
  padding: 10px;
  height: 36px;
  font-size: 14px;
  border: none;
  background: #ff4200;
  color: #eceff3;
}

#mainmenu select option {
  padding: 10px;
}

#mainmenu .btn-type {
  padding: 0;
  margin: 0;
}

#mainmenu .btn-type a {
  background: #eee;
  padding: 0;
  margin: 0;
}

#mainmenu > li ul.mega {
  position: fixed;
  left: 0;
  height: 0%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  padding: 10px 0;
  background: none;
  top: 75px;
}

#mainmenu ul.mega ul {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

#mainmenu ul.mega li.menu-title {
  font-weight: bold;
  font-size: 15px;
  color: #0d0c22;
  margin-left: 15px;
  margin-bottom: 5px;
}

.dark-scheme #mainmenu ul.mega li.menu-title {
  color: #ffffff;
}

#mainmenu li:hover ul.mega {
  visibility: visible;
  opacity: 1;
}

#mainmenu li ul.mega > li,
#mainmenu li ul.mega > li a {
  width: 100%;
}

#mainmenu li ul.mega ul {
  padding-bottom: 20px;
}

#mainmenu li ul.mega li.title {
  padding: 0px 10px 15px 10px;
  text-align: left;
  color: #fff;
  font-weight: bold;
}

#mainmenu li ul.mega > li ul {
  position: static;
  visibility: visible;
  opacity: 1;
  left: 0;
  float: none;
  width: 100%;
}

#mainmenu ul.mega > li ul li {
  margin: 0;
  padding: 0;
}

#mainmenu li ul.mega .menu-content {
  margin-top: 0px;
  background: #ffffff;
  padding: 20px 15px;
  padding-bottom: 0;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(20, 20, 20, 0.1);
  -moz-box-shadow: 2px 2px 10px 0px rgba(20, 20, 20, 0.1);
  box-shadow: 2px 2px 10px 0px rgba(20, 20, 20, 0.1);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: solid 1px #eeeeee;
}

.dark-scheme #mainmenu li ul.mega .menu-content {
  background: #21273e;
}

.dark-scheme #mainmenu li ul.mega .menu-content {
  border-color: rgba(255, 255, 255, 0.1);
}

header.header-mobile #mainmenu > li ul.mega {
  visibility: visible;
  opacity: 1;
}

header:not(.header-mobile) #mainmenu > li:hover ul.mega {
  height: auto;
}

/* menu toggle for mobile */

#menu-btn {
  display: none;
  float: right;
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  cursor: poInter;
  color: #000;
  background: transparent !important;
  margin-left: 20px;
}

.dark-scheme #menu-btn {
  color: #fff;
}

.rtl #menu-btn {
  margin-left: 0;
  margin-right: 20px;
}

#menu-btn:before {
  font-family: FontAwesome;
  content: '\f0c9';
  font-size: 20px;
}

#menu-btn:hover {
  background: #fff;
}

#menu-btn:hover:before {
  color: #222;
}

.rtl #menu-btn {
  float: left;
}

header.header-mobile.header-light,
header.header-mobile.header-light #menu-btn,
header.header-mobile.header-light #menu-btn:hover {
  background: none;
}

header.header-mobile.header-light #menu-btn {
  color: #222;
}

.dark-scheme .header-mobile.header-light #menu-btn {
  color: #fff;
}

.de-login-menu {
  position: relative;
}

.de-menu-profile {
  cursor: pointer;
}

.de-menu-profile img {
  width: 38px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  margin-left: 5px;
  margin-bottom: 2px;
}

#de-click-menu-notification,
#de-click-menu-cart {
  position: relative;
}

#de-click-menu-notification .d-count,
#de-click-menu-cart .d-count {
  display: inline-block;
  position: absolute;
  z-index: 100;
  background: #ffb800;
  line-height: 1em;
  padding: 6px;
  width: 22px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  top: -10px;
  right: -10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 10px;
}

.de-menu-notification {
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 0px 2px #727273 inset;
  text-align: center;
  line-height: 32px;
  border-radius: 30px;
  padding-top: 1px;
  margin-left: 5px;
}

.dark-scheme .de-menu-notification {
  background: transparent;
  color: #ffffff;
  box-shadow: 0px 0px 0px 2px rgba(238, 241, 249, 0.6) inset;
}

.dark-scheme .de-menu-notification svg path {
  fill: #eef1f9;
}

.rtl .de-menu-notification {
  margin-right: 5px;
}

.de-submenu {
  text-align: left;
  opacity: 0;
  position: absolute;
  right: 0;
  background: #ffffff;
  padding: 15px 20px;
  margin: 0;
  margin-top: 10px;
  width: 250px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  z-index: 1000;
}

.dark-scheme .de-submenu {
  background: var(--dark-scheme-color);
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.rtl .de-submenu {
  right: auto;
  left: 0;
}

.de-submenu.open {
  opacity: 1;
}

.de-submenu .d-balance {
  font-size: 14px;
}

.de-submenu h4 {
  font-weight: bold;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 16px;
  margin-top: 5px;
}

.rtl .de-submenu h4,
.rtl .d-name,
.rtl .d-balance {
  text-align: right;
}

.de-submenu-profile {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rtl .de-submenu-profile {
  text-align: right;
}

.de-submenu-profile a {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  display: block;
}

.dark-scheme .de-submenu-profile a {
  color: #fff;
}
.mobile_dropdown li{
  padding: 0 !important;
}
.mobile_dropdown li a{
  font-size: 18px !important;
}

.de-submenu-profile li {
  padding: 5px 0;
  border: none;
  margin: 0 0 10px;
  border-radius: 10px;
  /* text-align: center; */
  /* background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%); */
}

/* .dark-scheme .de-submenu-profile li {
  border: 1px solid rgba(241, 245, 249, 0.05);
  background: rgba(241, 245, 249, 0.05);
} */

.de-submenu-profile li.de-line {
  border-top: solid 1px #bbbbbb;
  margin: 10px 0 8px 0;
  padding: 0;
}

.de-submenu-profile i {
  padding: 7px 9px;
  background: #eee;
  border-radius: 30px;
  width: 28px;
  margin-right: 5px;
}

.dark-scheme .de-submenu-profile i {
  background: rgba(255, 255, 255, 0.1);
}

.de-submenu .d-wallet {
  position: relative;
}

.de-submenu .d-wallet .d-wallet-address {
  margin: 0;
  padding: 0;
  display: block;
  max-width: 69%;
  overflow: hidden;
  position: relative;
}

.de-submenu .d-wallet #btn_copy {
  right: 0;
  bottom: 4px;
}

.rtl .de-submenu .d-wallet #btn_copy {
  right: auto;
  left: 0;
}

#de-submenu-notification ul {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  list-style: none;
}

#de-submenu-notification li {
  line-height: 20px;
  margin-bottom: 15px;
}

#de-submenu-notification li .d-time {
  display: block;
  font-size: 14px;
  color: #727272;
}

.dark-scheme #de-submenu-notification li .d-time {
  color: #999999;
}

#de-submenu-notification li a {
  color: #0d0c22;
}

.dark-scheme #de-submenu-notification li a {
  color: #ffffff;
}

#de-submenu-notification li .d-desc {
  margin-left: 45px;
}

.rtl #de-submenu-notification li .d-desc {
  text-align: right;
  margin-left: 0;
  margin-right: 45px;
}

#de-submenu-notification li img {
  width: 32px;
  height: 32px;
  position: absolute;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.rtl #de-submenu-notification li img {
  right: 20px;
}

#de-submenu-notification li:last-child {
  margin-bottom: 0;
}

.de-submenu .d-line {
  margin: 15px 0 15px 0;
  border-top: solid 1px #bbbbbb;
}

.dark-scheme .de-submenu .d-line {
  border-top-color: rgba(255, 255, 255, 0.1);
}

/* ================================================== */

/* content */

/* ================================================== */

#content {
  width: 100%;
  /* background: #fff; */
  padding: 50px 0 0;
  z-index: 100;
}

.top-header-holder {
  margin: 0 0 60px;
}

.top-header-holder h2 {
  color: #000;
  font-size: 54px;
  line-height: 1.2;
  margin: 0;
}

.top-header-holder p {
  color: #000;
  font-size: 18px;
  margin: 0;
  letter-spacing: -0.72px;
}

.dark-scheme .top-header-holder p {
  color: #fff;
}

.de_light #content {
  background: #ffffff;
}

/* #content {
  background: #ffffff;
} */

.dark-scheme #content {
  /* background: var(--dark-scheme-color); */
}

/* ================================================== */

/* section */

/* ================================================== */

section {
  /* padding: 90px 0 90px 0; */
  position: relative;
}

.no-item-display {
  background: #fcfcfc;
  border-radius: 8px;
  min-height: 330px;
  color: rgb(173 173 173);
  font-weight: 500;
  display: flex;
  font-size: 24px;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.dark-scheme .no-item-display {
  background: rgb(58 58 58 / 10%);
}

#section-trending {
  padding: 80px 0 0;
}

.sections_tab {
  background: #eaeaea;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
}
.list_view {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  background: transparent;
  border: 2px solid #f0f0f0;
  border-radius: 100px;
}
.dark-scheme .list_view {
  border: 2px solid rgba(238, 241, 249, 0.2);
}
.list_view p {
  color: rgb(36 36 36 / 70%);
  font-weight: 600;
  margin: 0 18px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.dark-scheme .list_view p {
  color: rgba(238, 241, 249, 0.2);
}
.list_view p.active {
  color: #242424;
}
.dark-scheme .list_view p.active {
  color: #fff;
}
.list_view p.active svg g {
  opacity: 1;
}
.dark-scheme .list_view p svg path {
  fill: rgba(238, 241, 249, 0.2);
}
.dark-scheme .list_view p.active svg path {
  fill: #fff;
}
.sections_tab.detail_tab {
  padding: 4px;
}
.dark-scheme .sections_tab {
  background: rgba(231, 244, 255, 0.1);
}

.sections_tab p {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 8px 35px;
  position: relative;
  z-index: 10;
  color: #000;
  cursor: pointer;
}
.know_more {
  color: #f8818e;
  /* text-decoration: underline; */
}
.know_more:hover {
  text-decoration: underline;
}
.sections_tab.seller p {
  padding: 8px 15px;
}
.dark-scheme .sections_tab p {
  color: #fff;
}
.sections_tab p.active {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  border-radius: 10px;
}
.dark-scheme .sections_tab p.active {
  color: #000;
}
.visibility-hidden {
  visibility: hidden;
}
/* .sections_tab p.active::before{
  position: absolute;
  content: "";
  background-image: linear-gradient(to right, rgb(255 184 0 / 20%) 0%, rgb(243 90 246 / 40%) 100%);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
} */

.apexcharts-tooltip.apexcharts-theme-light {
  border: none !important;
  background: #fff !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 9.376522064208984px 0px #0000001a !important;
}

.arrow_box {
  padding: 16px;
  text-align: center;
}
.arrow_box img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
}
.arrow_box.bubble {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}
.arrow_box.bubble h5 {
  color: #000;
}

.arrow_box p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: #9b9b9b;
}
.arrow_box h5 {
  margin: 0 0 4px;
  font-size: 15px;
  color: #000 !important;
}
.arrow_box.bubble h5 {
  margin: 0;
}

.chart_holder {
  box-shadow: 0px 0px 9.376522064208984px 0px #0000001a;
  background: #fff;
  border-radius: 22px;
  padding: 20px;
  margin: 0 0 30px;
  width: 50%;
}
.height_loader {
  height: 540px;
}
.chart_holder.on_sale_chart {
  width: 100%;
}
.chart_listing_view .chart_holder{
  width: 100%;
  height: calc(50% - 34px);
}
.chart_holder.on_sale_chart .center-content {
  height: calc(100% - 46px);
}
.dark-scheme .chart_holder {
  background: rgb(255 255 255 / 10%);
}
#myProgress {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 0 4px 4px 0;
}
.dark-scheme #myProgress {
  background-color: rgb(255 255 255 / 10%);
}

#myBar {
  width: 10%;
  height: 30px;
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  border-radius: 0 4px 4px 0;
}
.list_col {
  box-shadow: 0px 0px 9.376522064208984px 0px #0000001a;
  padding: 15px;
  border-radius: 20px;
}
.dark-scheme .list_col {
  background: rgb(255 255 255 / 10%);
}
.list_col p {
  color: #242424;
  font-weight: 600;
  font-size: 16px;
}
.scroll_section {
  overflow-y: auto;
  height: 530px;
  padding: 0 10px 0 0;
}
.scroll_section::-webkit-scrollbar,
.y-auto::-webkit-scrollbar,
.scroll_owner::-webkit-scrollbar,
.collection-detail-page .description_overflow::-webkit-scrollbar,
.detail_page_description::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll_section::-webkit-scrollbar-thumb,
.scroll_owner::-webkit-scrollbar-thumb {
  background: #000;
}

.scroll_section::-webkit-scrollbar-track,
.scroll_owner::-webkit-scrollbar-track {
  background: #e0e0e0;
}
.dark-scheme .scroll_section::-webkit-scrollbar-thumb,
.dark-scheme .scroll_owner::-webkit-scrollbar-thumb {
  background: #fff;
}

.dark-scheme .scroll_section::-webkit-scrollbar-track,
.dark-scheme .scroll_owner::-webkit-scrollbar-track {
  background: #7c6e6e;
}
.scroll_owner {
  overflow-y: auto;
  max-height: 429px;
  padding: 6px 5px;
}
.dark-scheme .list_col p {
  color: #999999;
}
.sale_listing {
  min-height: 47px;
  display: flex;
  align-items: center;
}
.listing_view_img {
  width: 62px;
  height: 62px;
  object-fit: cover;
  border-radius: 10px;
  background: #e8e8e8;
}
.listing_view_img.on_sale {
  width: 82px;
  height: 82px;
}
.dark-scheme .listing_view_img {
  background: #181818;
}

.gradient-text {
  /* Fallback: Set a background color. */
  /* background-color: red; */

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
/* .dark-scheme #section-trending,
.dark-scheme #section-news {
  background: rgb(19 19 19 / 20%);
} */

#section-category {
  padding: 100px 0 0;
}

#section-news {
  padding: 100px 0;
}

#section-category {
  padding: 80px 0 0;
}

.grid_col {
  grid-template-columns: 62px 1fr;
  grid-template-rows: 62px;
}
.max_250 {
  max-width: 200px;
}
.mb-35 {
  margin-bottom: 30px;
}

.mb-90 {
  margin-bottom: 90px;
}

.trending_holder_section {
  /* gap: 30px; */
  margin: 0 -10px;
}

.trending_holder_section .col-sec {
  width: 25%;
  padding: 0 10px;
  margin-bottom: 16px;
}

.nft__item {
  position: relative;
  border-radius: 18px;
  /* margin-bottom: 30px; */
  padding: 8px 8px 15px;
  /* padding: 14px 14px 24px; */
  /* background: #f9f9f9; */
  background: #f4f4f4;
}

.nft__item_wrap {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background: #e8e8e8;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.nft__item_wrap.trending_colection {
  height: 220px;
}

/* .slider_image_width {
  height: 245px;
} */

/* .nft__item_wrap.collection_img_wrap {
  height: 245px;
  border-radius: 0;
} */

.dark-scheme section.filter-tab {
  background: rgba(241, 245, 249, 0.05);
  border: 1.289px solid rgba(241, 245, 249, 0.001);
}

.dark-scheme .filter-tab.active {
  background: linear-gradient(to right, #ffb800, #f35af6) border-box;
}

section.no-bg,
#content.no-bg {
  background: none !important;
}

.bgcolor-variation {
  background: #f6f6f6;
}

.overlay-gradient {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  padding: 90px 0 90px 0;
}

.text-gradient,
.de_countdown.s2,
.dark-scheme .de_countdown.s2 {
  background: linear-gradient(45deg, var(--secondary-color-2), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.de-navbar-left .container-fluid .container {
  width: 100%;
}

.de-navbar-left section {
  padding: 70px;
}

.de_light section {
  background: #ffffff;
}

.section-fixed {
  position: fixed;
  width: 100%;
  min-height: 100%;
  height: auto;
}

.full-height {
  overflow: hidden;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.text_top {
  position: relative;
  z-index: 1000;
}

/* ================================================== */

/* footer */

/* ================================================== */

footer {
  /*background:url('../images/logo-big.png') -50% center no-repeat var(--primary-color) !important;*/
  color: var(--dark-scheme-font-color);
  padding: 80px 0 0 0;
}

footer.footer-light {
  background: #ffffff !important;
  border-top: solid 1px #eeeeee;
  color: #595d69;
}

footer.footer-light a {
  color: #595d69;
}

.dark-scheme footer a {
  color: var(--dark-scheme-font-color);
}

footer.footer-black {
  background: #222222;
}

footer h5,
.de_light footer h5 {
  color: #fff;
  font-size: 18px;
  text-transform: none;
}

footer a {
  font-weight: 400;
  color: #ffffff;
  text-decoration: none !important;
}

.de-navbar-left footer {
  padding: 70px 70px 0 70px;
}

.subfooter {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  margin-top: 40px;
  padding: 20px 0 20px 0;
}

.footer-light .subfooter {
  border-top: solid 1px #eeeeee;
}

.de-navbar-left .subfooter {
  background: none;
}

header.side-header {
  border-bottom: none;
  margin: 0;
}

.no_graph_data {
  height: calc(100% - 30px);
}

header.side-header .social-icons-2 {
  margin: 0 auto;
  margin-top: 50px;
  float: none;
  text-align: center;
}

header.side-header .social-icons-2 a {
  display: inline-block;
  margin: 0 2px 0 2px;
  background: #555;
  width: 36px;
  height: 36px;
  padding-top: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  opacity: 0.2;
}

.de_light header.side-header .social-icons-2 a {
  background: #fff;
  color: #333;
}

header.side-header .social-icons-2 a:hover {
  opacity: 1;
}

.de-navbar-left.de_light header,
.de-navbar-left.de_light {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

header.header-light-transparent {
  background: rgba(255, 255, 255, 0.5);
}

header.header-solid.header-light #mainmenu > li > a {
  color: #555;
}

header.header-solid.header-light .info {
  background: #eee;
  color: #555;
}

header.header-solid.header-light .info .social i {
  color: #222;
}

header.header-solid {
  background: #fff;
}

header.header-light {
  background: #ffffff;
}

header.smaller.header-dark {
  background: #222222;
  border: none;
}

header.smaller.header-dark #mainmenu a {
  color: #fff;
}

header.s2 {
  background: #ffffff !important;
  border-bottom: solid 1px #eee;
  top: 0;
}

header.s2 #mainmenu > li > a {
  padding-top: 18px;
  padding-bottom: 18px;
}

.side-layout header.smaller.header-dark #mainmenu > li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.side-layout header.smaller.header-dark #mainmenu > li:last-child {
  border-bottom: none;
}

header.header-mobile {
  position: absolute;
  background: #fff;
  top: 0;
  height: 90px;
}

.dark-scheme header.header-mobile {
  background: var(--dark-scheme-color);
}

header.header-mobile.has-topbar {
  height: 100px;
}

header.header-mobile.has-topbar .header-row,
header.header-mobile.has-topbar .header-col {
  margin-top: 10px;
}

header.header-mobile.has-topbar #topbar {
  margin-top: -15px;
  padding: 0;
}

header.header-mobile nav {
  float: none;
}

header.header-mobile .logo {
  display: none;
}

header .logo-2 {
  display: none;
}

.switch-scheme.dark-scheme header .logo {
  display: block;
}

.switch-scheme.dark-scheme header .logo-2 {
  display: none;
}

header.header-mobile .btn-rsvp {
  padding-top: 40px;
  height: 90px;
}

header.header-mobile .logo-2 {
  display: inline-block;
}

header.header-mobile.header-light {
  background: #fff;
}

.dark-scheme .header-mobile.header-light {
  background: var(--dark-scheme-color);
}

header.header-mobile #mainmenu ul {
  padding: 0;
}

header.header-solid.header-light {
  background: #fff;
}

header.header-light .logo {
  display: inline-block;
  max-width: 120px;
  width: 100%;
  max-height: 120px;
  height: 100%;
}

/* .dark-scheme .header-light svg.logo path {
    fill: #fff;
} */

/* header.header-light .logo-2 {
    display: inline-block;
    max-width: 120px;
    width: 100%;
} */

/* topbar start here */

#topbar {
  z-index: 1000;
  width: 100%;
  padding: 0 30px;
  overflow: hidden;
  top: 0;
}

#topbar.topbar-dark {
  background: #202020;
}

#topbar a {
  text-decoration: none;
  color: #606060;
}

#topbar.text-light a {
  color: #ffffff;
}

.topbar-left,
.topbar-right {
  display: flex;
}

.topbar-right {
  float: right;
}

.topbar-solid {
  background: #ffffff;
}

.tb-light {
  background: #ffffff;
}

#topbar:not(.topbar-noborder) .topbar-right .topbar-widget {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-right span:last-child {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right .topbar-widget {
  border-left: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right span:last-child {
  border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right .topbar-widget {
  border-left: solid 1px #eeeeee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right span:last-child {
  border-right: solid 1px #eeeeee;
}

/* topbar left content */
.topbar-left {
  float: left;
}

#topbar:not(.topbar-noborder) .topbar-left .topbar-widget {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-left .topbar-widget {
  border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-left span:first-child {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left .topbar-widget {
  border-right: solid 1px #eeeeee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left span:first-child {
  border-left: solid 1px #eeeeee;
}

.h-phone {
  position: relative;
  font-weight: bold;
  color: #ffffff;
  font-family: var(--body-font);
  padding-left: 40px;
  font-size: 18px;
  padding-top: 20px;
}

.h-phone span {
  display: inline-block;
  position: absolute;
  left: 40px;
  top: 0;
  font-size: 12px;
  font-weight: 500;
}

.h-phone i {
  display: inline-block;
  position: absolute;
  font-size: 32px;
  left: 0;
  top: 12px;
}

/* topbar widget */
.topbar-widget {
  font-size: 13px;
  display: flex;
  padding: 6px 15px;
  font-weight: 400;
  height: 40px;
}

.topbar-widget i {
  font-size: 16px;
  margin-top: 8px;
  margin-right: 10px;
}

.h-sub .topbar-widget {
  padding: 12px;
}

.topbar-widget:before {
  float: left;
  position: relative;
  font-family: 'FontAwesome';
  font-size: 16px;
  margin-right: 10px;
}

.topbar-widget.tb-phone:before {
  content: '\f095';
}

.topbar-widget.tb-email:before {
  content: '\f003';
}

.topbar-widget.tb-opening-hours:before {
  content: '\f017';
}

.topbar-widget.tb-social {
  padding: 0;
}

.topbar-widget.tb-social a {
  font-size: 14px;
  display: flex;
  padding: 5px;
  padding-left: 15px;
  display: inline-block;
  text-align: center;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a,
#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a:first-child {
  border-left: solid 1px #eeeeee;
}

#topbar:not(.topbar-noborder) .topbar-widget.tb-social a {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

.tb-light .topbar-widget.tb-social a {
  border-left: solid 1px #eeeeee;
}

.topbar-widget.tb-social a:first-child {
  border: none;
}

/* topbar end here */

#de-sidebar {
  padding: 0px;
}

/* ================================================== */

/* blog */

/* ================================================== */

.blog-list {
  margin: 0;
  padding: 0;
}

/* blog list */

.blog-list > div {
  list-style: none;
  line-height: 1.7em;
  margin-top: 0px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.blog-list img {
  margin-bottom: 20px;
}

.de_light .blog-list li {
  border-bottom: solid 1px #ddd;
}

.blog-list h4 {
  margin-top: 20px;
}

.blog-list h4 a {
  color: #fff;
}

.de_light .blog-list h4 a {
  color: #222222;
}

.de_light .text-light .blog-list h4 a {
  color: #fff;
}

.blog-list .btn-more {
  padding: 3px 25px 3px 25px;
  color: #111;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  float: right;
}

.blog-list .btn-more:hover {
  background: #fff;
}

.de_light .blog-list .btn-more:hover {
  background: #222;
  color: #fff;
}

.blog-list .post-text {
  padding-left: 80px;
}

.bloglist .post-text a.btn-main {
  display: inline-block;
  margin-top: 0px;
  padding: 5px 25px;
}

.blog-list .blog-slider {
  margin-bottom: -60px;
}

.blog-list .date-box {
  position: absolute;
  text-align: center;
  text-shadow: none;
}

.blog-list .date-box .day,
.blog-list .date-box .month {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  z-index: 100;
}

.de_light .blog-list .date-box .month {
  color: #222222;
}

.blog-list .date-box {
  text-align: center;
  background: #f6f6f6;
  padding: 10px;
}

.blog-list .date-box .month {
  font-size: 14px;
}

.blog-list .date-box .day {
  font-family: var(--body-font);
  color: #222222;
  font-weight: bold;
  font-size: 28px;
}

.blog-list .date-box .month {
  font-family: var(--body-font);
  color: #fff;
  font-size: 13px;
  letter-spacing: 3px;
}

.de_light .blog-list .date-box .month {
  border-bottom-color: #ddd;
}

.de_light .text-light .blog-list .date-box .month {
  color: #fff;
}

.blog-snippet li {
  border-bottom: none;
  margin-bottom: 0;
}

#blog-carousel .item.item {
  width: 100%;
}

/* blog comment */

#blog-comment {
  margin-top: 40px;
}

#blog-comment h5 {
  margin-bottom: 10px;
}

#blog-comment ul,
#blog-comment li {
  list-style: none;
  margin-left: -40px;
  padding-left: 0;
}

#blog-comment li {
  min-height: 70px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #eee;
}

.rtl #blog-comment li {
  margin-right: -30px;
}

.dark-scheme #blog-comment li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

#blog-comment li .avatar {
  position: absolute;
  margin-top: 5px;
}

#blog-comment .comment {
  margin-left: 85px;
}

.rtl #blog-comment .comment {
  margin-left: 0;
  margin-right: 85px;
}

#blog-comment li {
  min-height: 70px;
  font-size: 14px;
  line-height: 1.6em;
}

#blog-comment li li {
  margin-left: 55px;
  padding-bottom: 0px;
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  border-bottom: none;
}

.rtl #blog-comment li li {
  margin-right: 40px;
}

.dark-scheme #blog-comment li li {
  border-top-color: rgba(255, 255, 255, 0.1);
}

#blog-comment li li .avatar {
  position: absolute;
}

.comment-info {
  margin-left: 85px;
  margin-bottom: 5px;
}

.rtl .comment-info {
  margin-left: 0;
  margin-right: 85px;
}

.comment-info .c_name {
  display: block;
  font-weight: 700;
  color: #555555;
}

.dark-scheme .comment-info .c_name {
  color: #ffffff;
}

.comment-info .c_reply {
  padding-left: 20px;
  margin-left: 20px;
  border-left: solid 1px #ddd;
  font-size: 12px;
}

.dark-scheme .comment-info .c_reply {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.comment-info .c_date {
  font-size: 12px;
}

.blog-list h4 {
  color: #333;
}

.blog-read h4 {
  color: #333;
  letter-spacing: normal;
  font-size: 24px;
  text-transform: none;
}

.blog-carousel ul,
.blog-carousel li {
  margin: 0;
  list-style: none;
}

.blog-carousel h4 {
  margin-bottom: 0px;
}

.blog-carousel p {
  margin-bottom: 10px;
}

.blog-carousel .post-date {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 11px;
  color: #888;
}

.post-content {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-content p {
  margin-bottom: 20px;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.dark-scheme .post-content p {
  color: #fff;
}

.post-content img {
  border-radius: 18px;
}

.post-text {
  padding-top: 15px;
}

.post-text h4 {
  font-size: 32px;
  margin-bottom: 0;
  line-height: 36px;
}

@media all and (max-width: 768px) {
  .post-text h4 {
    font-size: 26px;
    line-height: 30px;
  }
  .order_2 {
    order: 2;
  }
}

.post-text h4 a {
  color: #1a1c26;
  text-decoration: none;
  font-size: 34px;
  font-weight: bold;
}

.dark-scheme .post-text h4 a {
  color: #ffffff;
}

.post-text .tags {
  font-size: 10px;
  text-transform: uppercase;
}

.p-tagline {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
}

.p-tagline,
.p-title {
  font-family: var(--body-font);
  background: rgba(var(--primary-color-rgb), 0.2);
  border-radius: 3px;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 10px;
  padding: 7px 10px 6px 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.p-tagline,
.p-title.invert {
  background: var(--secondary-color);
}

.p-title {
  font-size: 12px;
  margin-bottom: 20px;
}

.post-text .p-date {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.dark-scheme .post-text .p-date {
  color: #fff;
}

.post-info {
  border-top: solid 1px #eeeeee;
  padding-top: 15px;
}

.blog-read img {
  margin-bottom: 20px;
}

.blog-read .post-text {
  padding: 0;
}

.post-date,
.post-comment,
.post-like,
.post-by,
.post-author {
  margin-right: 20px;
  font-size: 13px;
  color: #999;
  font-family: var(--body-font);
}

.dark-scheme .post-date,
.dark-scheme .post-comment,
.dark-scheme .post-like,
.dark-scheme .post-by,
.dark-scheme .post-author {
  color: #ffffff;
}

.post-author {
  color: #777;
}

.post-date:before,
.post-comment:before,
.post-like:before,
.post-by:before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}

/*.post-date:before{
    content: "\f133";
}*/
.post-comment:before {
  content: '\f0e5';
}

.post-like:before {
  content: '\f08a';
}

.post-author:before {
  content: 'By: ';
}

/* blog comment form */

#commentform label {
  display: block;
}

#commentform input {
  width: 290px;
}

#commentform input:focus {
  border: solid 1px #999;
  background: #fff;
}

#commentform textarea {
  width: 97%;
  padding: 5px;
  height: 150px;
  color: #333;
}

#commentform textarea:focus {
  border: solid 1px #999;
  background: #fff;
}

#commentform input.btn {
  width: auto;
}

.post-meta {
  margin: 0px 0 10px 0px;
  border: solid 1px #eee;
  border-left: none;
  border-right: none;
  border-left: none;
  font-size: 11px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.post-meta a {
  color: #888;
}

.post-meta span {
  display: table-cell;
  padding: 10px 0 10px 0;
  text-align: center;
}

.post-meta span i {
  float: none;
}

.post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0px;
}

.blog-slide {
  padding: 0;
  margin: 0;
}

.blog-item {
  margin-bottom: 60px;
}

.blog-item img.preview {
  width: 100%;
  margin-bottom: 30px;
}

.post-image {
  position: relative;
  height: 300px;
}

.post-image .post-info {
  position: absolute;
  bottom: 0;
  padding: 0;
  border: none;
  width: 100%;
}

.post-image .post-info .inner {
  margin: 0 30px 0 30px;
  padding: 3px 20px;
  border-radius: 10px 10px 0 0;
}

.post-image .post-date,
.post-image .post-author {
  color: #ffffff;
}

/* ================================================== */

/* products */

/* ================================================== */

.products {
  padding: 0;
  list-style: none;
  text-align: center;
}

.products li {
  margin-bottom: 40px;
}

.product .price {
  margin-bottom: 10px;
  font-size: 20px;
  color: #222;
}

.de_light .product h4 {
  font-size: 16px;
  margin: 0;
  margin-top: 20px;
}

.product img {
  padding: 2px;
  background: #333;
  border: solid 1px #fff;
}

.de_light .product img {
  padding: 0;
  border: solid 1px #eee;
}

.ratings {
  color: #f86e4e;
}

.product .ratings {
  margin: 5px 0 5px 0;
}

/* ================================================== */

/* contact form */

/* ================================================== */

.error {
  clear: both;
  display: none;
  color: #e7505a;
  padding-top: 20px;
}

.success {
  clear: both;
  display: none;
  color: #96c346;
  padding-top: 20px;
}

.error img {
  vertical-align: top;
}

.full {
  width: 98%;
}

#contact_form textarea {
  height: 195px;
}

#contact_form.s2 textarea {
  height: 180px;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

.form-underline input[type='text'],
.form-underline textarea,
.form-underline input[type='email'],
.form-underline select {
  padding: 10px 0 10px 0;
  margin-bottom: 20px;
  border: none;
  border-bottom: solid 2px #bbbbbb;
  background: none;
  border-radius: 0;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.form-underline select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  /* background: url('../images/ui/arrow-down-form.png') no-repeat 98% 60%; */
  /* background: url(../images/ui/) no-repeat 98% 60%; */
}

.form-underline input[type='text'],
.form-underline textarea,
.form-underline input[type='email'],
.form-underline select {
  color: #333;
  border-bottom: solid 1px #bbb;
}

.form-default input[type='text'],
.form-default textarea,
.form-default input[type='email'],
.form-default select {
  padding: 10px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  font-weight: 400;
  font-size: 18px;
}

#contact_form.form-default textarea {
  padding: 10px;
  height: 170px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

#form_subscribe input[type='text'] {
  padding: 7px 12px 7px 12px;
  width: 80%;
  float: left;
  display: table-cell;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border: none;
  border-right: none;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
}

#form_search_big input {
  font-size: 20px;
  font-weight: 300;
  padding: 10px;
  border: none;
}

#form_search_big input {
  background: rgba(0, 0, 0, 0.05);
}

.dark-scheme #form_search_big input,
.text-light #form_search_big input {
  background: #ffffff;
}

#form_search_big #btn-submit {
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.rtl #form_search_big #btn-submit {
  top: 12px;
  right: auto;
  left: 15px;
}

.dark-v {
  display: none;
}

.dark-scheme .dark-v {
  display: block;
}

.dark-scheme .light-v {
  display: none;
}

.d-create-file {
  padding: 85px 50px;
  border-radius: 10px;
  border: dashed 1.2px #bbb;
  text-align: center;
  position: relative;
}
.d-create-file .title_text {
  padding: 0 12px;
  color: #727272;
}
.single_collection {
  width: 310px;
  height: 310px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.close_image_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededed;
  background: #fff;
  cursor: pointer;
}
.close_image_icon:hover {
  border: 1px solid #cfcfcf;
  background: #cfcfcf;
}
.close_image_icon.top_1 {
  top: 45px;
}

.contract_holder {
  gap: 20px;
}
.contract_holder h6 {
  font-size: 18px;
}
.contract_bg {
  border: 2px solid #f0f0f0;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}
.contract_bg:hover {
  background:
    linear-gradient(#f3f2ede3 0 0) padding-box,
    linear-gradient(90deg, #ffb800, #f35af6) border-box;
  border: 2px solid #0000;
  border-radius: 12px;
}
.dark-scheme .contract_bg:hover {
  background:linear-gradient(#363636e3 0 0) padding-box,
  linear-gradient(90deg, #ffb800, #f35af6) border-box;
  border: 2px solid #0000;
}
.dark-scheme .contract_bg {
  border: 2px solid rgba(238, 241, 249, 0.1);
}
.contract_bg.active {
  background:
    linear-gradient(#f3f2ede3 0 0) padding-box,
    linear-gradient(90deg, #ffb800, #f35af6) border-box;
  border: 2px solid #0000;
  border-radius: 12px;
}
.dark-scheme .contract_bg.active {
  background:
    linear-gradient(#363636e3 0 0) padding-box,
    linear-gradient(90deg, #ffb800, #f35af6) border-box;
}

.gap-100 {
  gap: 100px;
}

.dark-scheme .close_image_icon {
  border: 1px solid #1d1d1d;
  background: #1d1d1d;
}
.dark-scheme .close_image_icon:hover {
  border: 1px solid rgb(48 48 48 / 90%);
  background: rgb(48 48 48 / 90%);
}

.dark-scheme .close_image_icon svg path {
  fill: #fff;
}

.w_600 {
  width: 600px;
}

.d_file_holder {
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; */
}
.d_file_holder.banner_height {
  height: 254px;
}

.dark-scheme .d-create-file label svg path {
  fill: #fff;
}

.dark-scheme .d-create-file {
  border: dashed 1.2px var(--input-border);
}

.dark-scheme .d-create-file {
  border-color: rgba(238, 241, 249, 0.1);
}

.dark-scheme .d-create-file p {
  color: #ffffff;
}

#form_sb input[type='text'] {
  padding: 21px 12px 21px 12px;
  width: 85%;
  height: 30px;
  float: left;
  display: table-cell;
  border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  border: none;
  background: rgba(255, 255, 255, 0.7);
}

#form_sb input[type='text']:focus {
  background: #ffffff;
}

#form_sb #btn-submit i {
  text-align: center;
  font-size: 30px;
  float: left;
  width: 15%;
  background: #171a21;
  color: #ffffff;
  display: table-cell;
  padding: 5px 0 6px 0;
  border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -webkit-border-radius: 0 8px 8px 0;
}

footer:not(.footer-light) #form_subscribe input[type='text']::-moz-input-placeholder {
  color: #ffffff;
}

footer:not(.footer-light) #form_subscribe input[type='text']::-webkit-input-placeholder {
  color: #ffffff;
}

footer:not(.footer-light) #form_subscribe.form-dark input[type='text'] {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.footer-light #form_subscribe input[type='text'] {
  border: solid 1px #333333;
  border-right: none;
  padding: 6px 12px;
}

#form_subscribe input[type='text']:focus {
  background: rgba(255, 255, 255, 0.2);
}

#form_subscribe #btn-subscribe i {
  text-align: center;
  font-size: 28px;
  float: left;
  width: 20%;
  background: #171a21;
  color: #ffffff;
  display: table-cell;
  padding: 5px 0 5px 0;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
}

#form_quick_search {
  font-family: var(--body-font);
  display: inline-block;
  margin-right: 0px;
  margin-bottom: -16px;
}

#form_quick_search input[type='text'] {
  padding: 8px 12px;
  width: 200px;
  float: left;
  display: table-cell;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border: solid 1px #ddd;
  border-right: none;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  font-size: 16px;
}

.dark-scheme #form_quick_search input[type='text'] {
  color: #ffffff;
  background: none;
  border-color: rgba(255, 255, 255, 0.2);
}

#form_quick_search #btn-submit i {
  text-align: center;
  font-size: 16px;
  float: left;
  width: 60px;
  background: #171a21;
  color: #ffffff;
  display: table-cell;
  padding: 13px 0;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
}

.text-light #form_quick_search input[type='text'] {
  color: #ffffff;
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.text-light #form_quick_search input[type='text'] {
  color: #ffffff;
}

.text-light #form_quick_search input[type='text']::-moz-input-placeholder {
  color: #ffffff;
}

.text-light #form_quick_search input[type='text']::-webkit-input-placeholder {
  color: #ffffff;
}

/* .header-light #quick_search {
    background: rgba(var(--secondary-color-rgb), .1);
} */
.mobile_search #quick_search {
  display: none !important;
}

.form-default input[type='text']:focus,
.form-default textarea:focus,
.form-default input[type='email']:focus,
.form-default select:focus {
  background: #f6f6f6;
}

.error_input {
  border-bottom: solid 1px #ff0000 !important;
}

/* ================================================== */

/* coming soon page */

/* ================================================== */

.coming-soon .logo {
  margin: 0;
  padding: 0;
}

.coming-soon h2 {
  margin-top: 0;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}

.coming-soon .social-icons i {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon .social-icons i:hover {
  background: none;
  color: rgba(255, 255, 255, 0.2);
}

.arrow-up,
.arrow-down {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #fff;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: poInter;
  z-index: 101;
}

.arrow-down {
  border-top: 40px solid #fff;
  border-bottom: none;
  position: fixed;
  z-index: 102;
  top: -40px;
}

.arrow-up:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  content: '\f067';
  color: #222;
  position: relative;
  top: 12px;
  margin: 0 0 0 -8px;
}

.arrow-down:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  content: '\f068';
  color: #222;
  position: relative;
  top: -35px;
  margin: 0 0 0 -8px;
}

/* ================================================== */

/* elements */

/* ================================================== */

/* address */

/* ------------------------------ */
address {
  margin-top: 20px;
}

address span {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 14.5px;
  background: #f5f5f5;
  border-radius: 300px;
}

address span i {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 15px;
}

.rtl address span i {
  margin-left: 15px;
  margin-right: 5px;
}

.text-light address span a {
  color: #ffffff;
}

.text-white address span a,
.text-white address span a:hover {
  color: #fff;
}

address.s1 span {
  background: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.7em;
  margin-bottom: 5px;
}

address.s1 span i {
  font-size: 15px;
}

address span strong {
  display: inline-block;
}

.de_light #sidebar .widget address span {
  border-bottom: solid 1px #ddd;
}

.de_light #sidebar .widget address a {
  color: #333;
}

/* border */

/* ------------------------------ */

.small-border {
  width: 50px;
  height: 2px;
  background: rgba(0, 0, 0, 0.5);
  border-left: none;
  border-right: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.small-border.sm-left {
  margin-left: 0;
}

.rtl .small-border.sm-left {
  margin-right: 0;
}

.text-light .small-border {
  background: rgba(255, 255, 255, 0.2);
}

.tiny-border {
  height: 1px;
  background: #333;
  width: 40px;
  margin: 20px 0 20px 0;
  display: block;
}

.tiny-border.center {
  margin-left: auto;
  margin-right: auto;
}

.small-border.white {
  border-top: solid 2px #ffffff;
}

/* breadcrumb */

/* ------------------------------ */

.crumb {
  color: #777;
  margin: 0;
  padding: 0;
}

.de-navbar-left.de_light #subheader .crumb {
  color: #333;
}

.crumb li {
  display: inline;
  text-decoration: none;
  letter-spacing: 0;
}

.crumb li:after {
  font-family: 'FontAwesome';
  content: '\f054';
  font-size: 10px;
  margin: 0 10px 0 10px;
  opacity: 0.5;
}

.crumb li:last-child:after {
  display: none;
}

.crumb a {
  text-decoration: none;
}

.slider_holder {
  margin-top: 100px;
}

.crumb li .active {
  font-weight: bold;
}

.de_light .crumb a:hover {
  color: #fff;
}

/* box icon */

.icon_box .icon_wrapper {
  margin-bottom: 20px;
}

.icon_box .icon_wrapper i {
  font-size: 60px;
}

.icon_box.icon_left .icon_wrapper {
  position: absolute;
  width: 80px;
}

.icon_box.icon_left .desc_wrapper {
  margin-left: 80px;
}

.icon_box.icon_right .icon_wrapper {
  position: absolute;
  right: 0;
  width: 80px;
}

.icon_box.icon_right .desc_wrapper {
  margin-right: 80px;
  text-align: right;
}

.icon-big {
  border-radius: 100%;
  text-align: center;
  font-size: 56px;
  display: inline-block;
  padding: 16px 0;
  width: 96px;
  height: 96px;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
  -moz-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
  box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), 0.3);
}

.icon-box {
  display: block;
  text-align: center;
  border: solid 1px #dddddd;
  padding: 20px 0 20px 0;
}

.icon-box.s2 {
  margin: 1%;
}

.category-holder {
  margin: 0 -19px;
}

.section-col {
  width: 20%;
  padding: 0 18px;
  margin-bottom: 19px;
}

.section-col .icon-box:hover {
  background: #fefefe;
  transform: translateY(-5px);
}

.dark-scheme .icon-box.style-2 {
  background: transparent;
  border: 3px solid rgba(241, 245, 249, 0.05);
}

/* a.icon-box.style-2 svg {
  margin: 0 0 15px;
  width: 180px;
  height: 180px;
} */

.icon-box.style-2 {
  border-radius: 12px;
  border: 3px solid #eef1f9;
  background: #fefefe;
  display: flex;
  padding: 30px;
  column-gap: 20px;
  min-height: 150px;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.dark-scheme .icon-box.style-2 svg path,
.dark-scheme .icon-box.style-2 svg circle,
.dark-scheme .icon-box.style-2 svg polygon,
.dark-scheme .icon-box.style-2 svg rect {
  fill: #fff;
}

.icon-box.style-2 i {
  margin-bottom: 0;
}

.text-light .icon-box {
  border: solid 2px rgba(255, 255, 255, 0.1);
}

.icon-box i {
  display: block;
  font-size: 40px;
  margin-bottom: 10px;
}

.icon-box span {
  display: block;
  color: #141416;
  font-weight: 600;
  font-size: 26px;
  /* letter-spacing: 1px; */
  line-height: 1.4em;
}

.dark-scheme .icon-box span {
  color: #ffffff;
}

.text-light .icon-box span {
  color: #ffffff;
}

/* ------------------------------ */

a {
  text-decoration: none;
}

.de_light a {
  color: #888;
}

.de_light a:hover {
  color: #555;
}

a.btn-text {
  text-decoration: none;
  display: inline-block;
  color: #111;
  font-weight: 600;
  padding: 0;
}

a.btn-text:after {
  font-family: FontAwesome;
  content: '\f054';
  padding-left: 10px;
}

a.btn-text {
  color: #fff;
}

a.btn-big {
  font-size: 14px;
  color: #eceff3;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: bold;
  text-transform: uppercase;
  border: solid 1px #fff;
  padding: 10px 30px 10px 30px;
}

a.btn-big:after {
  font-family: FontAwesome;
  content: '\f054';
  margin-left: 20px;
}

a.btn,
.btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

a.btn:before {
  content: '';
  background: rgba(0, 0, 0, 0);
  width: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
}

a.btn-fx:after {
  font-family: FontAwesome;
  content: '\f178';
  margin-left: 15px;
  position: absolute;
  right: -20px;
  margin-top: 0px;
}

a.btn-fx {
  font-size: 14px;
  color: #eceff3;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: bold;
  text-transform: uppercase;
  border: solid 1px #fff;
  padding: 10px 30px 10px 30px;
}

a.btn-fx:hover {
  padding-left: 20px;
  padding-right: 40px;
}

.deploy_header {
  justify-content: center !important;
}

.deploy_header .modal-title {
  padding-top: 30px !important;
}

.deploy_header .btn-close {
  position: absolute !important;
  top: 20px !important;
  right: 20px !important;
}

.collection_filter {
  flex-wrap: wrap;
}
.filter_collections {
  margin-bottom: 50px;
}

a.btn-fx:before {
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.preview_image_holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

a.btn-fx:hover:after {
  right: 15px;
}

a.btn-fx:hover:before {
  width: 100%;
  background: rgba(0, 0, 0, 1);
}

a.btn-fx.light:hover:before {
  width: 100%;
  background: rgba(255, 255, 255, 1);
}

.btn-fullwidth {
  width: 100%;
}

.btn-main.btn-fullwidth {
  display: block;
}

#loadmore.btn-main {
  border-radius: 16px;
  background: #f4f4f4;
  color: #626273;
  padding: 15px 24px;
}
.list_view_grid ul li {
  width: 160px;
  text-align: center;
  color: #242424;
  /* min-width: 40%; */
}
.dark-scheme .list_view_grid ul li {
  color: #fff;
}
.list_view_grid {
  font-weight: 600;
}
.listing_list{
  padding: 6px 10px;
  cursor: pointer;
  position: relative;
}
.listing_list:hover{
  background: #eee;
  border-radius: 10px;
}
.dark-scheme .listing_list:hover{
  background: rgba(255, 255, 255, 0.1);
}
.list_view_grid .title {
  margin-left: 12px;
  color: #242424;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dark-scheme .list_view_grid .title {
  color: #fff;
}
.transaction_address {
  text-decoration: underline;
  color: #D63384;
  padding: 5px 8px 5px 0;
  cursor: pointer;
  border-radius: 0;
  border: none;
  background: none;
}
.blockchain_link{
  text-decoration: underline;
}
.blockchain_link:hover{
  text-decoration: none;
}
.transaction_address:hover {
  text-decoration: none;
}
.list_view_grid ul li img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  margin-left: 60px;
  background: #e8e8e8;
}
.dark-scheme .list_view_grid ul li img {
  background: rgb(238 238 238 / 10%);
}
.list_view_grid ul li:first-child {
  flex: 1;
  text-align: inherit;
}

.dark-scheme #loadmore.btn-main {
  background: rgba(241, 245, 249, 0.08);
  color: #fff;
}

#loadmore.btn-main:hover {
  box-shadow: none;
  background: rgba(22, 22, 26, 0.1);
}
.dark-scheme #loadmore.btn-main:hover {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.1);
}

a.btn-slider {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
  border: solid 2px #fff;
  padding: 10px 30px 10px 30px;
  border-radius: 60px;
}

a.btn-slider:hover {
  color: #222;
  background: #fff;
  border-color: #000;
  border: solid 2px #fff;
}

.btn-heder-address {
  color: #000;
  background-color: rgb(254, 240, 6);
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  align-items: center;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(var(--secondary-color-rgb), 0.5);
  -moz-box-shadow: 2px 2px 20px 0px rgba(var(--secondary-color-rgb), 0.5);
  box-shadow: 2px 2px 20px 0px rgba(var(--secondary-color-rgb), 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.btn-heder-address:hover {
  color: #555;
  cursor: pointer;
}

a.btn-slider:hover:after {
  color: #222;
}

a.btn-id,
a.btn-id:hover {
  border: none;
}

a.btn-light.btn-id {
  color: #222;
}

a.btn-dark.btn-id {
  color: #fff;
}

.btn-main.btn-small {
  padding: 5px 20px 5px 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-fx.btn-main {
  text-transform: normal;
}

a.btn-bg-dark {
  background: #222;
}

a.btn-text-light {
  color: #fff;
}

.btn-icon-left i {
  margin-right: 12px;
  color: #fff;
}

.btn-add_to_cart,
a.btn-add_to_cart {
  border: solid 1px #bbb;
  font-size: 12px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 3px;
  padding-left: 40px;
  padding-right: 20px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #555;
}

.cart-aside {
  position: fixed;
  top: 24px;
  right: 24px;
  bottom: 0px;
  border-radius: 16px;
  z-index: 1010;
  padding: 20px;
  height: calc(((100% - 22px) - 0px) - 0px);
  background-color: #fff;
  overflow: auto;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  transition:
    transform 0.2s ease 0s,
    opacity 0.2s ease 0s;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  width: 375px;
  color: var(--white);
  overflow: hidden;
}

.dark-scheme .cart-aside {
  background-color: #212121;
}

.cart-aside img {
  width: 72px;
  height: 72px;
  border-radius: 8px;
}

.copy-right {
  position: absolute;
  top: 17px;
  right: 10px;
}

/* .cart-aside h4, .cart-aside h4{
    color: var(--black);
}
.dark-scheme .cart-aside h4, .dark-scheme .cart-aside h4{
    color: var(--white);
} */
.cart-aside p.title {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  color: var(--black);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cart-aside .fa {
  color: var(--black);
}
.cart-aside .fa:hover {
  color: #9b9b9b;
}

.dark-scheme .cart-aside .fa {
  color: #9b9b9b;
}
.dark-scheme .cart-aside .fa:hover {
  color: #fff;
}

.dark-scheme .cart-aside p.title {
  color: var(--white);
}

.cart-aside span.title,
.cart-aside label {
  color: var(--black);
}

.dark-scheme .cart-aside span.title,
.dark-scheme .cart-aside label {
  color: var(--white);
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.flex-auto {
  flex: 0 0 auto;
}

.text-secondary {
  opacity: 0.8;
  color: var(--verified-bg-black);
}

.dark-scheme .text-secondary {
  color: #fff !important;
}

.mr-4 {
  margin-right: 1rem;
}

.cart-listing {
  width: 100%;
  border-radius: 12px;
  border-width: 0;
  overflow: hidden;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.y-auto {
  overflow-y: auto;
}

.no-scroll-padding {
  padding-right: 10px;
}

.pointer {
  cursor: pointer;
}

.cart-listing:hover {
  /* background: var(--primary-brand); */
  background: rgba(187, 187, 187, 0.2);
  border: 1px solid rgba(187, 187, 187, 0.2);
}

.dark-scheme .cart-listing:hover {
  /* background: var(--primary-brand); */
  background: rgba(241, 245, 249, 0.05);
  border: 1px solid rgba(241, 245, 249, 0.05);
}

.cart-listing:hover .last-col .d-block {
  display: none !important;
}

.cart-listing:hover .last-col .d-none {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 17px;
}

.cart-listing:hover .last-col {
  margin-left: auto;
}

.w-\[calc\(100\%\+48px\)\] {
  width: calc(100% + 48px);
  height: 1px;
}

.explore-btn {
  padding: 12px 34px !important;
}

a.explore-btn:hover {
  box-shadow: none !important;
}

.bg-border-1 {
  background-color: #eef1f9;
}

.dark-scheme .bg-border-1 {
  background-color: rgba(255, 255, 255, 0.2);
}

.react-multiple-carousel__arrow--right {
  right: calc(1.5% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0.5% + 1px) !important;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.btn-add_to_cart:before,
a.btn-add_to_cart:before {
  font-family: 'FontAwesome';
  content: '\f07a';
  position: absolute;
  left: 20px;
}

a.btn-main,
a.btn-main:active,
a.btn-main:focus,
a.btn-main:visited,
.btn-main,
input[type='button'].btn-main,
a.btn-line {
  display: inline-block;
  line-height: 1.2em;
  text-align: center;
  color: #000;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  outline: 0;
  font-weight: 800;
  text-decoration: none;
  padding: 12px 24px;
  font-size: 18px;
  border: none;
}

/* a.btn-main:hover{
    color: #fff !important;
} */

.explore_collection { 
  color: #000 !important;
}
 
.btn-main.active-btn {
  color: #000;
  box-shadow: none;
  border-radius: 100px;
}
.btn-main.support_btn{
  background: #000;
  color: #fff;
  margin-left: 10px;
}
.dark-scheme .btn-main.support_btn{
  background: var(--button-primary-bg);
  color: #000;
}
.dark-scheme .btn-main.support_btn:hover{
  background: linear-gradient(94.51deg, rgb(255 184 0 / 80%) -15.25%, rgb(243 90 246 / 80%) 122.2%);
}
.btn-main.support_btn:hover{
  background: #494949;
}
.btn-main.listing_btn {
  border: 0.94px solid #e7e7e7;
  background: #fff;
  color: #000;
  box-shadow: none;
  border-radius: 15px;
  font-size: 16px;
}
.dark-scheme .btn-main.listing_btn {
  border: 1.289px solid rgba(241, 245, 249, 0.001);
  background: #f1f5f90d;
  color: #fff;
}
.btn-main.listing_btn.active {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  color: #000;
  border: none;
}
.btn-main.analytics_btn {
  background: transparent;
  color: #242424;
  height: 54px;
  border-radius: 100px;
  position: relative;
}
.btn-main.analytics_btn.transfer{
  cursor: auto;
}
.btn-main.analytics_btn.transfer .primary{
  color: #F88093;
}
.btn-main.analytics_btn.transfer:hover .transfer_hover{
  display: block;
}
/* .transfer_hover{
  position: absolute;
  bottom: 3px;
  left: 2px;
  font-size: 10px;
  right: 0;
  font-size: 10px;
} */
.transfer_hover{
  position: absolute;
  top: -13px;
  /* left: 30px; */
  display: none;
  /* content: "▼"; */
  right: -54px;
  /* width: max-content; */
  /* line-height: 16px; */
  /* padding: 0 6px; */
  /* font-size: 12px; */
  /* background: linear-gradient(94.51deg, #FFB800 -15.25%, #F35AF6 122.2%); */
  /* border-radius: 100px; */
}
.dark-scheme .btn-main.analytics_btn {
  color: #727272;
}
.btn-main.analytics_btn.active {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  color: #000;
}

.dark-scheme .btn-main.active-btn {
  color: #000;
}

a.btn-lg {
  font-size: 16px !important;
  padding: 12px 40px !important;
}

.form-control:focus {
  border-color: rgb(255 184 0 / 80%) !important;
}

a.btn-main.transparent,
.btn-main.transparent {
  color: #131521;
  border-radius: 8px;
  line-height: 1;
  border: 1px solid #131521;
  background: transparent;
}
a.btn-main.transparent_bg,
.btn-main.transparent_bg {
  color: #131521;
  border-radius: 100px;
  line-height: 1;
  border: none;
  background: transparent;
}
.dark-scheme .btn-main.transparent_bg,
.dark-scheme .btn-main.transparent_bg {
  color: #727272;
}

a.btn-main.transparent:hover,
.btn-main.transparent:hover {
  border: 1px solid rgb(255 184 0 / 80%);
  color: rgb(255 184 0 / 100%);
}

.dark-scheme .btn-main.transparent:hover,
.dark-scheme .btn-main.transparent:hover {
  border: 1px solid rgb(255 184 0 / 80%);
  color: rgb(255 184 0 / 100%);
}

.dark-scheme .btn-main.transparent {
  color: #fff;
  border: 1.289px solid rgb(255 255 255 / 50%);
}

a.btn-line,
a.btn-line:hover {
  background: none;
  color: #0d0c22;
  border: solid 2px rgba(0, 0, 0, 0.1);
}

a.btn-line:hover {
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0) !important;
}

header:not(.header-light) a.btn-line,
.dark-scheme a.btn-line,
.dark-schem a.btn-line:hover {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

a.btn-main.btn-white {
  background: #ffffff;
}

.user_dp {
  border-radius: 20px;
  border-radius: 20px;
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_dp:hover .user_wallet_icon svg {
  display: none;
}
.user_dp .user_wallet_icon .dot-svg {
  display: none;
}
.user_dp:hover .user_wallet_icon .dot-svg {
  display: block;
}

.connect_address {
  font-size: 13px;
  line-height: 1.5;
}

header a.btn-main,
header a.btn-line {
  padding: 6px 20px !important;
}

header a.btn-main i {
  display: none;
}

.col-right a.btn-main {
  font-size: 14px;
  text-transform: none;
}

a.btn-border {
  border: solid 2px rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  min-width: 120px;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

a.btn-border:hover {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0);
  margin-top: -2px;
  margin-bottom: 2px;
  box-sizing: border-box;
}

a.btn-border.light {
  border: solid 1px #ffffff;
  color: #ffffff;
}

a.btn-border.light:hover {
  background: #ffffff;
}

a.btn-border:hover a {
  color: #ffffff !important;
}

.d-btn-close {
  color: #ffffff;
  cursor: poInter;
  text-align: center;
  display: block;
  text-align: center;
  width: 60px;
  height: 60px;
  background: #333;
  font-size: 32px;
  font-weight: bold;
  text-decoration: none;
  margin: 0 auto;
  padding-top: 12px;
  position: absolute;
  left: 0;
  right: 0;
}

a.btn-link {
  display: block;
  text-decoration: none;
  margin-top: 10px;
}

.cover a.btn-link:hover {
  color: #fff;
}

/*
    a.btn-rsvp:before{
    font-size:32px;
    font-family:"FontAwesome";
    content:"\f073";
    margin-right:15px;
    display:block;
    margin:0 auto;
    margin-bottom:10px;
    }
    */

.play-button {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #fff;
  text-decoration: none;
  border: solid 5px rgba(255, 255, 255, 0.3);
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 22px;
  padding-left: 5px;
}

.play-button:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  content: '\f04b';
  position: relative;
  color: #fff;
}

.play-button:hover {
  border: solid 5px rgba(255, 255, 255, 1);
}

.play-button.dark {
  color: #222;
  border: solid 5px rgba(0, 0, 0, 0.1);
}

.play-button.dark:before {
  color: #222;
}

.play-button.dark:hover {
  border: solid 5px rgba(0, 0, 0, 0.5);
}

.text-light .play-button {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #fff;
  text-decoration: none;
  border: solid 5px rgba(255, 255, 255, 0.3);
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 22px;
  padding-left: 5px;
}

.text-light .play-button:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  content: '\f04b';
  position: relative;
  color: #fff;
}

.text-light .play-button.dark {
  color: #fff;
  border: solid 5px rgba(255, 255, 255, 0.3);
}

.text-light .play-button.dark:before {
  color: #fff;
}

.text-light .play-button.dark:hover {
  border: solid 5px rgba(255, 255, 255, 1);
}

/* columns */

/* ------------------------------ */

/* counter */

/* ------------------------------ */

.de_count {
  padding: 20px 0 10px 0;
  background: none;
}

.de_count h3 {
  font-size: 36px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  line-height: 0.5em;
}

.de_count h5 {
  font-size: 14px;
  font-weight: 500;
}

.de_count h3 span {
  font-size: 36px;
}

.de_count.s1 h3,
.de_count.s1 h3 span {
  font-size: 26px;
}

.de_count.style-2 {
  /* background: url(../images/background/16.jpg); */
  padding: 60px 0 30px 0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border: solid 1px #dddddd;
}

.dark-scheme .de_count.style-2 {
  /* background: url(../images/background/18.jpg); */
}

.de_count.style-2 h3,
.de_count.style-2 h3 span {
  font-size: 48px;
}

.de_count.big h3 {
  font-size: 120px;
}

.de_count span {
  font-size: 12px;
  text-transform: uppercase;
}

.de_count i {
  display: block;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 0px;
  width: 100%;
  font-weight: lighter;
}

.de_count.small h4 {
  font-size: 28px;
  margin-bottom: 0;
}

/* divider */

/* ------------------------------ */

.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

.spacer-30 {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-40 {
  width: 100%;
  height: 40px;
  display: block;
  clear: both;
}

.spacer-50 {
  width: 100%;
  height: 50px;
  display: block;
  clear: both;
}

.spacer-60 {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;
}

/* dropcap */

/* ------------------------------ */

.dropcap {
  display: inline-block;
  font-size: 48px;
  margin: 10px 15px 15px 0;
  color: #eceff3;
  padding: 20px 10px 20px 10px;
}

/* form */

/* ------------------------------ */

.form-transparent input[type='text'],
.form-transparent textarea,
.form-transparent input[type='email'] {
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0;
  height: auto;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.gap-16 {
  gap: 16px;
}

.dark-scheme .tick_depoly_icon svg {
  fill: #fff;
}

.first_col {
  width: 400px;
  overflow: hidden;
}

.form-border input[type='text'],
.form-border textarea,
.form-underline input[type='email'],
.form-border input[type='password'],
.form-border input[type='date'] {
  padding: 8px;
  margin-bottom: 0;
  border: none;
  border: 1.5px solid #e0e9f2;
  background: rgba(241, 245, 249, 0.2);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
}

.form-border select {
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border);
  padding: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
    98% / 14px no-repeat #fff !important;
}

.dark-scheme .form-border input[type='text'],
.dark-scheme .form-border textarea,
.dark-scheme .form-underline input[type='email'],
.dark-scheme .form-border input[type='password'],
.dark-scheme .form-border input[type='date'],
.dark-scheme .form-border input[type='number'] {
  color: #ffffff;
  border: 1.289px solid var(--input-border);
  background: rgba(241, 245, 249, 0.05);
}

.dark-scheme .form-border input:disabled {
  background: rgba(241, 245, 249, 0.05) !important;
  opacity: 0.7;
}
.dark-scheme .form-border input.btn-main:disabled {
  background: linear-gradient(94.51deg, #FFB800 -15.25%, #F35AF6 122.2%) !important;
  opacity: 0.4;
}

.form-border input[type='text'],
.form-underline input[type='email'],
.form-border input[type='password'],
.form-border input[type='date'],
.form-border input[type='number'],
.form-border select {
  height: 50px;
  border-radius: 8px;
  border: 1.5px solid #e0e9f2;
  background: rgba(241, 245, 249, 0.2);
}

.dark-scheme .form-border select {
  color: #fff;
  border-color: var(--input-border);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
    98% / 14px no-repeat #232324 !important;
}

.dark-scheme ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dark-scheme .form-border input::-moz-input-placeholder,
.dark-scheme .form-border textarea::-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-scheme .form-border input::-webkit-input-placeholder,
.dark-scheme .form-border textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* .form-border input[type=text]:focus,
.form-border textarea:focus,
.form-underline input[type=email]:focus,
.form-border input[type=date]:focus,
.form-border select:focus {
    background: rgba(var(--secondary-color-rgb), .1);
} */

.form-subscribe {
  display: table;
  margin: 0 auto;
}

.form-subscribe input[type='text'] {
  display: table-cell;
  border: solid 1px rgba(255, 255, 255, 0.3);
  border-right: none;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 20px 6px 20px;
  border-radius: 60px 0 0 60px;
  height: 40px;
  vertical-align: middle;
}

.form-subscribe .btn-main {
  display: table-cell;
  border-radius: 0 60px 60px 0;
  height: 40px;
  vertical-align: middle;
}

.form-subscribe input[type='text']:hover {
  background: rgba(255, 255, 255, 0.3);
}

.field-set label {
  color: #fff;
  font-weight: 500;
}

.collection_fieldset {
  max-width: 1046px;
  margin: 0 auto;
  padding: 0 15px;
}

.dark-scheme .field-set label {
  color: #ffffff;
}

#upload_file {
  display: none;
}

/* #form-create-item input {
    height: 50px;
} */

.de_form input[type='radio'],
.de_form input[type='checkbox'] {
  /* hide the inputs */
  display: none;
}

.de_form input[type='radio'] + label:before {
  content: '\f111';
  font-family: 'FontAwesome';
  margin-right: 10px;
  border: solid 1px #dddddd;
}

.de_form input[type='checkbox'] + label:before {
  position: absolute;
  content: '';
  display: inline-block;
  font-family: 'FontAwesome';
  margin-right: 10px;
  border: solid 2px rgba(0, 0, 0, 0.2);
  width: 26px;
  height: 26px;
  /* margin-top: 1px; */
  left: 0;
  top: 0;
  font-size: 11px;
  padding: 1px 3px 0 3px;
  line-height: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-scheme .de_form input[type='checkbox'] + label:before {
  border: solid 2px rgba(255, 255, 255, 0.2);
}

.de_checkbox {
  position: relative;
  display: block;
  padding-left: 35px;
  /* padding-bottom: 20px; */
  margin: 0 0 18px 12px;
  /* border-bottom: 1px solid #eee; */
}

.de_form input[type='radio'] + label,
.de_form input[type='checkbox'] + label {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  font-size: 17px;
}

.no-bg input[type='radio'] + label,
.de_form.no-bg input[type='checkbox'] + label {
  padding: 4px 0px;
  border: none;
  background: none;
}

.de_form input[type='radio']:checked + label,
.de_form input[type='checkbox']:checked + label {
  /* style for the checked/selected state */
  border: none;
}

.de_form input[type='checkbox']:checked + label:before {
  content: '\f00c';
  color: #ffffff;
  border: rgba(0, 0, 0, 0);
}

.item_filter_group {
  border: solid 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
  padding: 30px;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}

.dark-scheme .item_filter_group {
  border: solid 1px rgba(255, 255, 255, 0.075);
}

/* .dark-scheme .de_checkbox {
    border-bottom: solid 1px rgba(255, 255, 255, .075);
} */

.item_filter_group h4 {
  font-size: 18px;
}

.seperator {
  height: 58px;
  display: flex;
  align-items: end;
  color: #000;
  font-weight: 700;
}
.dark-scheme .seperator {
  color: #fff;
}
.label-text{
  font-size: 18px;
  font-weight: 600;
}
.transfer_nft input{
  width: 300px;
}
.search_transfer{
  margin-left: auto;
}
.search_transfer input{
  width: 360px;
}
.transfer_nft_holder{
  padding-bottom: 38px;
  border-bottom: 2px solid #2424241A;
  margin-bottom: 30px;
}

input[type=checkbox] {
  position: relative;
	border: 2px solid #000;
	border-radius: 4px;
	background: none;
	cursor: pointer;
	line-height: 0;
	margin: 0 0 0 10px;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 20px;
	width: 20px;
	-webkit-appearance: none;
  opacity: 1;
}
.dark-scheme [type=checkbox] {
	border: 2px solid rgb(255 255 255 / 20%);
}

input[type=checkbox]:hover {
  opacity: 1;
}

input[type=checkbox]:checked {
  background: linear-gradient(94.51deg, rgb(255 184 0 / 80%) -15.25%, rgb(243 90 246 / 80%) 122.2%);
  border: transparent;
  opacity: 1;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  /* border: solid #FFF; */
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
input[type=checkbox]:checked::before {
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translate(-50%, -42%);
}
.checked-mark{
  position: absolute;
  top: 18px;
  right: 18px;
}
/* heading */

/* ------------------------------ */

h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: 700;
  color: #0d0c22;
}

.dark-scheme h1,
.dark-scheme h2,
.dark-scheme h4,
.dark-scheme h3,
.dark-scheme h5,
.dark-scheme h6,
.dark-scheme .h1_big,
.dark-scheme .h1,
.dark-scheme .h2,
.dark-scheme .h3,
.dark-scheme .h4,
.dark-scheme .h5,
.dark-scheme .h6 {
  color: #ffffff;
}

footer h1,
footer h2,
footer h4,
footer h4,
footer h5,
footer h6 {
  color: #fff;
}

h2.text-light,
footer h4,
footer h4,
footer h5,
footer h6 {
  color: #fff;
}

h1,
.h1 {
  font-size: 50px;
  margin-bottom: 20px;
  line-height: 1.2em;
  letter-spacing: -1px;
}

h1 .label {
  display: inline-block;
  font-size: 36px;
  padding: 0 6px;
  margin-left: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

h1.s1 {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -2px;
}

h1.s2 {
  font-size: 60px;
  line-height: 68px;
  letter-spacing: -1px;
}

h1 .small-border {
  margin-top: 30px;
}

h1.big,
.h1_big {
  font-size: 64px;
  margin: 0;
  line-height: 70px;
}

h1.very-big {
  font-size: 120px;
  letter-spacing: -5px;
}

h1.ultra-big {
  font-size: 140px;
  line-height: 120px;
  letter-spacing: -6px;
  font-weight: 700;
  margin-bottom: 0;
}

h1.ultra-big span {
  display: inline-block;
}

h1.ultra-big span.underline span {
  display: block;
  border-bottom: solid 12px #fff;
  position: relative;
  margin-top: -5px;
}

h1.very-big-2 {
  font-size: 90px;
  letter-spacing: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.h2_title {
  font-size: 28px;
  display: block;
  margin-top: 0;
  line-height: 1.2em;
}

h2 .small-border {
  margin-left: 0;
  margin-bottom: 15px;
  width: 40px;
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 46px;
}

h2.style-2 {
  font-size: 30px;
  margin-bottom: 20px;
}

h2.big {
  font-size: 48px;
  line-height: 1.3em;
  margin-bottom: 0;
}

h2 .uptitle {
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-weight: 500;
}

h2.s1,
.h2_s1 {
  font-size: 24px;
}

h2.deco-text span {
  font-family: 'Parisienne';
  display: block;
  line-height: 0.85em;
  font-weight: lighter;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h2.deco-text .md {
  font-size: 80px;
}

h2.deco-text .lg {
  font-size: 120px;
}

h2.deco-text .xl {
  font-size: 150px;
}

h3 {
  font-size: 22px;
  margin-bottom: 25px;
}

h4 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
}

h4.teaser {
  font-weight: 300;
  font-size: 22px;
}

.subtitle.s2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.subtitle.s2 span {
  margin-right: 20px;
  padding-left: 20px;
}

.subtitle.s2 i {
  margin-right: 10px;
}

.subtitle.s2 span:first-child {
  padding-left: 0;
}

h4.s1 {
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 16px;
}

h4.s2 {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 200;
  line-height: 1.8em;
}

h4.s3 {
  font-family: 'Parisienne';
  font-size: 60px;
  font-weight: lighter;
}

h4.s3 {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 15px;
}

.call-to-action h4 {
  text-transform: none;
  font-size: 20px;
}

h6.s1 {
  letter-spacing: 12px;
}

h1.slogan_big {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -2px;
  padding: 0;
  margin: 0px 0 30px 0;
}

h1.title {
  font-size: 64px;
  letter-spacing: 10px;
}

h1.title strong {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

h1.hs1 {
  font-family: 'Parisienne';
  font-size: 96px;
  display: inline-block;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h1.hs2 {
  font-family: 'Parisienne';
  font-size: 72px;
  display: inline-block;
  font-weight: lighter;
}

h2.subtitle {
  margin-top: 0;
}

h2.name {
  font-family: var(--body-font);
  color: #fff;
  font-size: 84px;
  line-height: 50px;
}

h2.name span {
  display: block;
  font-size: 32px;
}

h2.name-s1 {
  font-family: var(--body-font);
  color: #fff;
  font-size: 84px;
  font-weight: 700;
  line-height: 50px;
}

h2.name-s1 span {
  display: block;
  font-size: 32px;
}

h2.hw {
  display: block;
  font-family: 'Parisienne';
  font-size: 48px;
  text-transform: none;
  font-weight: lighter;
}

h2.deco {
  font-family: var(--body-font);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

h2.deco span {
  display: inline-block;
  position: relative;
}

h2.deco span:before,
h2.deco span:after {
  content: '';
  position: absolute;
  border-top: 1px solid #bbb;
  top: 10px;
  width: 100px;
}

h2.deco span:before {
  right: 100%;
  margin-right: 15px;
}

h2.deco span:after {
  left: 100%;
  margin-left: 15px;
}

h2.hs1 {
  font-family: var(--body-font);
  font-size: 40px;
}

h2.hs1 i {
  font-size: 48px;
  position: relative;
  top: 10px;
  color: #ff0042;
  margin: 0 10px 0 10px;
}

h2.hs1 span {
  font-size: 48px;
  position: relative;
  top: 10px;
  font-family: 'Miama';
  margin: 0 15px 0 10px;
  font-weight: normal;
}

h2 .de_light .text-light h2 {
  color: #fff;
}

.text-light h2.deco span:before,
.text-light h2.deco span:after {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

h2.s2 {
  font-family: var(--body-font);
  font-weight: 400;
}

h2.s3 {
  font-size: 36px;
  margin-bottom: 20px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

h4.style-2 {
  font-size: 18px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h5.s2 {
  font-family: var(--body-font);
  letter-spacing: 1px;
  font-size: 14px;
}

h5 {
  font-size: 18px;
}

.form-border h5 {
  font-size: 22px;
}

.content-page h5 {
  font-size: 28px;
}
.content-page span.tiles {
  font-size: 20px;
  font-family: var(--title-font);
  font-weight: 700;
  color: #0d0c22;
}
.dark-scheme .content-page span.tiles {
  color: #fff;
}

h4.s1 {
  font-size: 12px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

span.teaser {
  font-family: 'Georgia';
  font-style: italic;
  font-size: 18px;
}

.wm {
  font-size: 200px;
  opacity: 0.2;
  position: absolute;
  left: 0;
  z-index: 0;
  letter-spacing: -0.05em;
}

.text-light {
  color: #ffffff;
}

.text-dark {
  color: #223044;
}

.pricing-s2.bg.text-light .top p {
  color: #fff;
}

.text-white,
.de_light .text-white {
  color: #fff;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6 {
  color: #fff;
}

.de_light .text-light h1,
.de_light .text-light h2,
.de_light .text-light h4,
.de_light .text-light h4,
.de_light .text-light h5,
.de_light .text-light h6 {
  color: #fff;
}

.text-white h1,
.text-white h2,
.text-white h4,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #fff;
}

.de_light .text-white h1,
.de_light .text-white h2,
.de_light .text-white h4,
.de_light .text-white h4,
.de_light .text-white h5,
.de_light .text-white h6 {
  color: #fff;
}

.de_light h1,
.de_light h2,
.de_light h4,
.de_light h4,
.de_light h5,
.de_light h6 {
  color: #202020;
}

/* feature box style 1 */

.feature-box .inner {
  position: relative;
  overflow: hidden;
  padding: 40px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.feature-box.s1 .inner {
  background: #ffffff;
}

.feature-box.s1:hover .inner i {
  background: none;
  color: #303030;
}

.text-light .feature-box .inner {
  color: #fff;
}

.feature-box i {
  font-size: 40px;
  margin-bottom: 20px;
}

.max-content {
  width: max-content;
}

.input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}

.dark-scheme .input-group-text {
  color: #fff;
  background-color: #e9ecef33;
  border: 1px solid #e9ecef33;
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}

.feature-box.left i {
  position: absolute;
}

.feature-box.left .text {
  padding-left: 70px;
}

.feature-box.center {
  text-align: center;
}

.feature-box i.circle,
.feature-box i.square {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

.feature-box i.square {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.feature-box i.circle {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

i.font60 {
  font-size: 60px;
}

.feature-box i.wm {
  font-size: 800px;
  position: absolute;
  top: 0%;
  width: 100%;
  left: 50%;
}

.feature-box:hover .inner i.wm {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 0.05);
  z-index: 0;
}

.feature-box:hover .inner i.circle {
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
}

.feature-box.style-3 {
  position: relative;
  overflow: hidden !important;
}

.feature-box.style-3 i {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  padding: 14px 0;
  width: 56px;
  height: 56px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;

  /*-webkit-box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);
    box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);*/
}

.feature-box.style-3 i.wm {
  position: absolute;
  font-size: 240px;
  background: none;
  width: auto;
  height: auto;
  color: rgba(var(--secondary-color-rgb), 0.5);
  right: 0;
  top: 30%;
}

.feature-box.style-3:hover i.wm {
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.feature-box.style-4 i {
  position: relative;
  z-index: 2;
  padding: 10px;
  min-width: 60px;
}

.feature-box.style-4 .wm {
  display: block;
  position: absolute;
  font-size: 160px;
  background: none;
  width: 100%;
  height: auto;
  right: 0;
  top: 120px;
  z-index: 2;
  font-weight: bold;
}

.feature-box.style-4 .text {
  margin-top: -50px;
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 30px;
  padding-top: 50px;
}

.feature-box.f-boxed {
  overflow: none;
  padding: 50px;
  background: rgba(var(--secondary-color-rgb), 0.1);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.text-light .feature-box.f-boxed {
  background: rgba(0, 0, 0, 0.1);
}

.feature-box.f-boxed:hover {
  color: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.feature-box.f-boxed:hover h4 {
  color: #ffffff;
}

.feature-box i.i-circle {
  border-radius: 60px;
}

.feature-box i.i-boxed {
  border-radius: 5px;
}

.feature-box-small-icon.center {
  text-align: center;
}

.dark .feature-box-small-icon .inner:hover > i {
  color: #fff;
}

.feature-box-small-icon .text {
  padding-left: 80px;
}

.feature-box-small-icon.center .text {
  padding-left: 0;
  display: block;
}

.feature-box-small-icon i {
  text-shadow: none;
  font-size: 40px;
  width: 68px;
  height: 68px;
  text-align: center;
  position: absolute;
  text-align: center;
}

.feature-box-small-icon i.hover {
  color: #333;
}

.feature-box-small-icon .border {
  height: 2px;
  width: 30px;
  background: #ccc;
  display: block;
  margin-top: 20px;
  margin-left: 85px;
}

.feature-box-small-icon .btn {
  margin-top: 10px;
}

.feature-box-small-icon.center i {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.feature-box-small-icon.center .fs1 {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.feature-box-small-icon i.dark {
  background-color: #1a1c26;
}

.feature-box-small-icon h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-small-icon.no-bg h2 {
  padding-top: 12px;
}

.feature-box-small-icon .number {
  font-size: 40px;
  text-align: center;
  position: absolute;
  width: 70px;
  margin-top: -5px;
}

.feature-box-small-icon .number.circle {
  border-bottom: solid 2px rgba(255, 255, 255, 0.3);
  padding: 20px;
  width: 70px;
  height: 70px;
}

.feature-box-small-icon.style-2 .number {
  font-size: 32px;
  color: #fff;
  padding-top: 22px;
  background: #253545;
  width: 70px;
  height: 70px;
}

.feature-box-small-icon.no-bg .inner,
.feature-box-small-icon.no-bg .inner:hover {
  padding: 0;
  background: none;
}

.f-hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

/* feature-box style 2 */

.feature-box-small-icon-2 {
  margin-bottom: 30px;
}

.feature-box-small-icon-2 .text {
  padding-left: 48px;
}

.feature-box-small-icon-2 i {
  text-shadow: none;
  color: #eceff3;
  font-size: 24px;
  text-align: center;
  position: absolute;
}

.feature-box-small-icon-2 i.dark {
  background-color: #1a1c26;
}

.feature-box-small-icon-2 h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-small-icon-2.no-bg h2 {
  padding-top: 12px;
}

.feature-box-small-icon-2.no-bg .inner,
.feature-box-small-icon-2.no-bg .inner:hover {
  padding: 0;
  background: none;
}

/* feature-box image style 3 */

.feature-box-image-2 {
  margin-bottom: 30px;
}

.feature-box-image-2 .text {
  padding-left: 100px;
}

.feature-box-image-2 img {
  position: absolute;
}

.feature-box-image-2 i {
  background-color: #1a1c26;
}

.feature-box-image-2 h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.feature-box-image-2.no-bg h2 {
  padding-top: 12px;
}

.feature-box-image-2.no-bg .inner,
.feature-box-image-2.no-bg .inner:hover {
  padding: 0;
  background: none;
}

/* with image */

.feature-box-image img {
  width: 100%;
  margin-bottom: 10px;
  height: auto;
}

.feature-box-image h4 {
  line-height: normal;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

/* feature-box image style 4 */

.feature-box-big-icon {
  text-align: center;
}

.feature-box-big-icon i {
  float: none;
  display: inline-block;
  text-shadow: none;
  color: #eceff3;
  font-size: 40px;
  padding: 40px;
  width: 120x;
  height: 120x;
  text-align: center;
  border: none;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -web-kit-border-radius: 10px;
  background: #333;
  margin-bottom: 40px;
  cursor: default;
}

.feature-box-big-icon i:after {
  content: '';
  position: absolute;
  margin: 75px 0 0 -40px;
  border-width: 20px 20px 0 20px;
  /*size of the triangle*/
  border-style: solid;
}

.feature-box-big-icon:hover i,
.feature-box-big-icon:hover i:after {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feature-box-big-icon:hover i {
  background: #333;
}

.feature-box-big-icon:hover i:after {
  border-color: #333 transparent;
}

.feature-box-type-1 {
  position: relative;
  background: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

.feature-box-type-1.s2 {
  border: solid 1px #eeeeee;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.feature-box-type-1 i {
  text-align: center;
  position: absolute;
  font-size: 36px;
  color: #ffffff;
  padding: 12px;
  border-radius: 0 0 7px 0;
}

.feature-box-type-1.s2 i,
.feature-box-type-1.s2:hover i {
  background: rgba(var(--secondary-color-rgb), 0.1);
  color: #111111;
}

.feature-box-type-1 .text {
  padding: 30px;
  padding-left: 80px;
}

.feature-box-type-2 {
  padding: 50px;
  padding-bottom: 30px;
  border-radius: 3px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 2px 10px 30px 0px rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 2px 10px 30px 0px rgba(10, 10, 10, 0.1);
  box-shadow: 2px 10px 30px 0px rgba(10, 10, 10, 0.1);
}

.feature-box-type-2 i {
  font-size: 60px;
  margin-bottom: 20px;
}

.feature-box-type-2:hover i {
  text-shadow: 0px 0px 0px #ffffff;
}

.feature-box-type-2 .wm {
  font-size: 200px;
  top: 50%;
  right: -50%;
  opacity: 0.1;
}

.de-card-icon i {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  padding: 14px 0;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  margin-bottom: 20px;
}

.f-box i {
  font-size: 36px;
}

.f-box.f-icon-rounded i {
  display: block;
  text-align: center;
  padding: 22px;
  width: 80px;
  height: 80px;
  border-radius: 3px;
}

.f-box.f-border i {
  border: solid 10px rgba(255, 255, 255, 0.1);
  padding: 12px;
}

.f-box.f-icon-rounded:hover i {
  border-radius: 3px;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
  -moz-box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
  box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
}

.f-box.f-icon-shadow i {
  -webkit-box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), 0.3);
  -moz-box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), 0.3);
  box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), 0.3);
}

.f-box.f-icon-circle i {
  display: block;
  text-align: center;
  padding: 22px 0;
  padding-top: 12px;
  min-width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
}

.f-box.f-icon-left {
  display: flex;
}

.f-box.f-icon-left i {
  margin-right: 30px;
}

.f-box.f-box-s1 {
  padding: 40px;
  padding-left: 0;
  margin-left: 20px;
  background: rgba(var(--primary-color-rgb), 0.1);
}

.f-box.f-box-s2 {
  background: rgba(255, 255, 255, 0.1);
}

.f-box.f-box-s1 i {
  margin-left: -20px;
}

.f-box.f-box-s1 p {
  margin: 0;
}

.de-card {
  padding: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.de-card h3 {
  margin-bottom: 20px;
}

.de-card p {
  margin-bottom: 0;
}

.de-card i {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  padding: 14px 0;
  width: 56px;
  height: 56px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  margin-bottom: 20px;
}

.de-card.has-border {
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.dark-scheme .de-card.has-border {
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.rtl .f-box.f-icon-left i {
  margin-right: -60px;
  margin-left: 30px;
}

.d-gallery-item {
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.d-gallery-item img {
  width: 100%;
  height: 100%;
}

.d-gallery-item .dgi-1 {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.d-gallery-item .dgi-2 {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
}

.d-gallery-item .dgi-3 {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.d-gallery-item:hover .dgi-1 {
  opacity: 0.8;
}

.d-gallery-item:hover img {
  transform: scale(1.2);
}

.exp-box {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.exp-box h5 {
  border: solid 2px rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 6px 10px 5px 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.box-fx .inner {
  position: relative;
  overflow: hidden;
  border-bottom: solid 2px;
}

.box-fx .inner {
  border-bottom: solid 2px;
}

.box-fx .front {
  padding: 60px 0 60px 0;
}

.box-fx .front span {
  font-size: 14px;
}

.box-fx .info {
  position: absolute;
  padding: 60px 30px 60px 30px;
  background: #fff;
  color: #fff;
}

.box-fx i {
  font-size: 72px;
  margin-bottom: 20px;
}

.box-fx .btn-line,
.box-fx .btn-line a.btn-line {
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: #fff;
}

.box-fx .btn-line:hover,
.box-fx a.btn-line:hover {
  background: #fff;
  color: #111;
  border-color: #fff;
}

.box-fx .btn-line:after,
.box-fx a.btn-line:after {
  color: #fff;
}

.box-fx .btn-line:hover:after,
.box-fx a.btn-line:hover:after,
.box-fx a.btn-line.hover:after {
  color: #111;
}

.box-fx .bg-icon {
  left: 50%;
  bottom: 50px;
  color: #f6f6f6;
  position: absolute;
  font-size: 190px;
  z-index: -1;
}

.box-icon-simple i {
  color: #222;
}

.box-icon-simple.right {
  margin-right: 70px;
  text-align: right;
}

.box-icon-simple.right i {
  font-size: 42px;
  right: 0;
  position: absolute;
}

.box-icon-simple .num {
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: #333333;
  width: 48px;
  height: 48px;
  padding: 12px 12px;
  position: absolute;
  text-align: center;
  font-weight: bold;
  border-radius: 30px;
}

.box-icon-simple.right .num {
  right: 10px;
}

.box-icon-simple.left .text {
  margin-left: 70px;
}

.box-icon-simple.left i {
  font-size: 42px;
  position: absolute;
}

.box-number .number {
  display: block;
  font-size: 48px;
  color: #222;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 25px;
  border-radius: 60px;
  position: absolute;
}

.box-number .text {
  margin-left: 100px;
}

.box-border {
  border: solid 2px #333;
}

/* list */

.activity-list,
.activity-list li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity-list li {
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 32.4%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.activity-list li .containerStyle {
  width: 136px;
  height: 136px;
  border-radius: 10px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transaction_image_holder .containerStyle {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 18px;
  background: #e8e8e8;
  cursor: pointer;
}
.dark-scheme .transaction_image_holder .containerStyle {
  background: #eeeeee1a;
}

.dark-scheme .error_message_modal p {
  color: #fff;
}

.transaction_image_holder {
  display: flex;
  align-items: center;
}

.transaction_image_holder .containerStyle video,
.transaction_image_holder .containerStyle img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.dark-scheme .activity-list li .containerStyle {
  background: rgb(255 255 255 / 2%);
}

.activity-list li .containerStyle img {
  max-width: 90%;
  max-height: 90%;
}

.activity-list li .containerStyle video {
  max-width: 90%;
  max-height: 90%;
}

.rtl .activity-list li {
  padding-right: 20px;
  padding-left: 100px;
}

.activity-list li:after {
  font-family: 'FontAwesome';
  margin-right: 15px;
  float: right;
  position: absolute;
  right: 20px;
  top: 30%;
  font-size: 26px;
  background: #fff;
  height: 48px;
  width: 48px;
  padding: 12px 0;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.rtl .activity-list li:after {
  right: auto;
  left: 30px;
}

.activity-list li.act_follow:after {
  content: '\f00c';
}

.activity-list li.act_like:after {
  content: '\f004';
}

.activity-list li.act_sale:after {
  content: '\f291';
}

.activity-list li.act_offer:after {
  content: '\f0e3';
}

.activity-filter,
.activity-filter li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.transaction_activity_filter .activity-filter {
  flex: 1;
  margin-right: 100px;
  /* border-bottom: 1px solid rgba(38, 59, 128, 0.1); */
}

/* .dark-scheme .transaction_activity_filter .activity-filter {
  border-bottom: 1px solid rgba(241, 245, 249, 0.05);
} */

.transaction_activity_filter .activity-filter li {
  padding: 11px 22px;
  margin: 0;
  border-radius: 100px;
  margin-right: 15px;
  color: #141416;
  font-weight: 600;
  border: 1.2px solid #eef1f9;
  background: #fefefe;
}
.transaction_activity_filter .activity-filter li:last-child {
  margin-right: 0;
}

.activity-filter li {
  display: inline-block;
  padding: 8px 10px;
  border: solid 1px #dddddd;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: max-content;
  font-weight: bold;
  cursor: pointer;
  font-family: var(--title-font);
}

.no_history_text {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
}

.dark-scheme .activity-filter li {
  border-color: rgba(255, 255, 255, 0.2);
}

.activity-filter li:nth-child(2) {
  /* margin-right: 0; */
}

.activity-filter i {
  height: 32px;
  width: 32px;
  padding: 9px 0;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background: #eee;
  margin-right: 10px;
}

.rtl .activity-filter i {
  margin-right: 0;
  margin-left: 10px;
}

.activity-filter li.active {
  color: #000 !important;
  border-radius: 100px;
  background: var(--button-primary-bg) !important;
}

.dark-scheme .transaction_activity_filter .activity-filter li {
  background: rgba(241, 245, 249, 0.05);
  border: none;
  color: #fff;
}

.activity-filter li.active i {
  background: #ffffff;
}

/* .activity-list img {
    width: 80px;
    position: absolute;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
} */

.activity-list .act_like img,
.activity-list .act_sale img,
.activity-list .act_offer img {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.activity-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.2%;
}

.act_list_text {
  padding-left: 20px;
}

.transaction-tag {
  /* position: absolute; */
  background: #eaeaea;
  /* top: 10px; */
  /* right: 10px; */
  width: max-content;
  color: #121212;
  font-size: 16px;
  border-radius: 100px;
  font-weight: 600;
  padding: 5px 20px;
}
.dark-scheme .transaction-tag {
  background: rgba(241, 245, 249, 0.05);
  color: #fff;
}
.transaction-tag span {
  display: inline-block;
}

.rtl .act_list_text {
  padding-left: 0;
  padding-right: 100px;
}

.dark-scheme .act_list_text a {
  color: #ffffff;
}

.act_list_text h4 {
  margin-bottom: 5px;
  font-size: 18px;
}

.act_list_date {
  display: block;
}

.transaction_listing .reference p {
  color: #a5a5a5;
}

.transaction_listing .reference {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

/* .transaction_listing .reference span {
  color: #263b80;
} */

.counter_number {
  background: #eaeaea;
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter_number.cart_counter {
  background: transparent;
}
.dark-scheme .counter_number {
  background: rgba(231, 244, 255, 0.1);
}
.dark-scheme .counter_number.cart_counter {
  background: transparent;
}

.counter_number span {
  cursor: pointer;
}
.counter_number .minus,
.counter_number .plus {
  width: 44px;
  height: 45px;
  background: transparent;
  font-size: 36px;
  line-height: 40px;
  border-radius: 0;
  border: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #000;
}
.counter_number .minus.disabled,
.counter_number .plus.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.counter_number.cart_counter .minus,
.counter_number.cart_counter .plus {
  width: 25px;
  height: 25px;
  line-height: 20px;
  font-size: 32px;
}
.dark-scheme .counter_number .minus,
.dark-scheme .counter_number .plus {
  color: #9b9b9b;
}
.counter_number .minus:hover,
.counter_number .plus:hover {
  color: #9b9b9b;
}
.dark-scheme .counter_number .minus:hover,
.dark-scheme .counter_number .plus:hover {
  color: #fff;
}
.counter_number .minus {
  border-right: 1px solid #dddddd;
}
.counter_number .plus {
  border-left: 1px solid #dddddd;
}
.counter_number.cart_counter .minus {
  border-right: none;
}
.counter_number.cart_counter .plus {
  border-left: none;
}
.dark-scheme .counter_number .minus {
  border-right: 1px solid #f1f5f90d;
}
.dark-scheme .counter_number .plus {
  border-left: 1px solid #f1f5f90d;
}
.dark-scheme .counter_number.cart_counter .minus {
  border-right: none;
}
.dark-scheme .counter_number.cart_counter .plus {
  border-left: none;
}
.counter_number input {
  height: 34px;
  width: 65px;
  text-align: center;
  font-size: 20px;
  border: none;
  background: transparent;
  border-radius: 0;
  display: inline-block;
  vertical-align: middle;
}
.counter_number.cart_counter input {
  width: 45px;
  font-size: 18px;
}
.dark-scheme .counter_number input {
  color: #fff;
}
.counter_number input:focus {
  outline: none;
}

.put_sale_max {
  top: 12px;
  right: 10px;
  color: #ffb800;
  cursor: pointer;
}

.referesh_meta_icon {
  position: absolute;
  top: 12px;
  right: 10px;
  background: #eaeaea;
  width: 40px;
  height: 40px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.dark-scheme .referesh_meta_icon {
  background: rgb(238 238 238 / 10%);
}
.dark-scheme .referesh_meta_icon svg path {
  fill: #fff;
}

.ul-style-2 {
  padding: 0;
  list-style: none;
}

.ul-style-2 li {
  margin: 5px 0 5px 0;
}

.ul-style-2 li:before {
  font-family: 'FontAwesome';
  content: '\f00c';
  margin-right: 15px;
}

/* pagination */

/* ======================================== */

.pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.pagination li a {
  font-size: 14px;
  color: #888;
  border: solid 1px #dddddd;
  border-right: none;
  background: none;
  padding: 15px 20px 15px 20px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;

  background: #ffffff;
}

.dark-scheme .pagination li:not(.active) a {
  border-color: rgba(255, 255, 255, 0.1);
  background: none;
  color: #ffffff;
}

.pagination li:last-child a {
  border-right: solid 1px #dddddd;
}

.dark-scheme .pagination li:last-child a {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.pagination > .active > a {
  color: #ffffff;
}

/* pricing table */

/* ================================================== */

.pricing-box {
  color: #888;
}

.pricing-2-col {
  border-right: solid 1px #eee;
}

.pricing-2-col .pricing-box {
  width: 49%;
}

.pricing-3-col .pricing-box {
  width: 32.33%;
}

.pricing-4-col .pricing-box {
  width: 24%;
}

.pricing-5-col .pricing-box {
  width: 19%;
}

.pricing-box {
  float: left;
  text-align: center;
  margin: 5px;
  padding: 0;
  opacity: 0.8;
}

.pricing-box ul {
  margin: 0;
  padding: 0;
}

.pricing-box li {
  list-style: none;
  margin: 0;
  padding: 15px 0 15px 0;
  background: #eee;
}

.pricing-box li h4 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #888;
}

.pricing-box li h1 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 5px;
  color: #1a8b49;
  letter-spacing: 0px;
}

.pricing-box li.title-row {
  padding: 15px 0 15px 0;
}

.pricing-box li.price-row {
  padding: 15px 0 15px 0;
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-box li.deco {
  background: #f6f6f6;
}

.pricing-box a.btn {
  color: #eceff3;
}

.pricing-box li.price-row span {
  display: block;
  font-weight: 600;
}

.pricing-box li.btn-row {
  background: #ddd;
}

.pricing.pricing-box ul {
  margin: 0;
  padding: 0;
}

.pricing.pricing-box li {
  background: #3b3b3b;
  color: #bbb;
  list-style: none;
  margin: 0;
  padding: 15px 0 15px 0;
}

.pricing.pricing-box li.deco {
  background: #333;
}

.pricing.pricing-box li h4 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #eceff3;
}

.pricing.pricing-box li h1 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  color: #eceff3;
}

.pricing.pricing-box li.title-row {
  background: #323232;
  padding: 15px 0 15px 0;
}

.pricing.pricing-box li.price-row {
  background: #2b2b2b;
  padding: 15px 0 15px 0;
}

.pricing.pricing-box li.price-row h1 {
  font-weight: 400;
}

.pricing.pricing-box li.price-row h1 span {
  display: inline-block;
  font-weight: 600;
}

.pricing.pricing-box li.price-row span {
  display: block;
  font-weight: 600;
}

.pricing.pricing-box li.btn-row {
  background: #202220;
}

.pricing-featured {
  position: relative;
}

.pricing-featured li {
  padding-top: 17px;
  padding-bottom: 17px;
}

.pricing-s1 {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.dark-scheme .pricing-s1 {
  background: #21273e;
}

.text-light .pricing-s1 {
  background: rgba(0, 0, 0, 0.1);
}

.pricing-s1 .top {
  padding: 30px;
  text-align: center;
}

.pricing-s1 .top h2 {
  font-size: 32px;
  letter-spacing: normal;
  line-height: 1em;
  margin-bottom: 0;
}

.dark-scheme .pricing-s1 .top h2 {
  color: #ffffff;
}

.pricing-s1 .top p.plan-tagline {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.top .num {
  font-size: 40px;
  font-weight: 500;
}

.opt-2 {
  display: none;
}

.pricing-s1 .top > * {
  margin: 0px;
}

.pricing-s1 .top .price {
  margin-top: 40px;
  margin-bottom: 30px;
}

.pricing-s1 .top .price .currency {
  font-size: 24px;
  vertical-align: top;
  display: inline-block;
}

.pricing-s1 .top .price b {
  color: #fff;
  font-family: var(--body-font);
  font-weight: 500;
}

.pricing-s1 .top .price .month {
  color: #fff;
  font-size: 14px;
}

.pricing-s1 .top .price .txt {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}

.pricing-s1 .m,
.pricing-s1 .y {
  font-size: 48px;
  font-weight: 500;
}

.pricing-s1 .top .y {
  display: none;
}

.pricing-s1 .mid {
  padding: 40px;
  padding-bottom: 10px;
  text-align: center;
}

.pricing-s1 .bottom {
  border-top: 0px;
  text-align: center;
}

.pricing-s1 .bottom ul {
  display: block;
  list-style: none;
  list-style-type: none;
  margin: 30px 0;
  padding: 0px;
  text-align: left;
  overflow: hidden;
}

.rtl .pricing-s1 .bottom ul {
  text-align: right;
}

.pricing-s1 .bottom ul li {
  padding: 10px 30px;
  color: #505050;
}

.dark-scheme .pricing-s1 .bottom ul li {
  color: #ffffff;
}

.text-light .pricing-s1 .bottom ul li {
  color: #ffffff;
}

.pricing-s1 .bottom ul li > span {
  color: #16a085;
  font-size: 20px;
  margin-right: 20px;
}

.pricing-s1.zebra .bottom ul li:nth-child(odd) {
  background: #ffffff;
}

.pricing-s1.zebra .bottom ul li:nth-child(even) {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-s1 .bottom > a {
  margin: 40px;
  width: auto;
}

.pricing-s1 .bottom i {
  margin-right: 10px;
}

.rtl .pricing-s1 .bottom i {
  margin-right: 0px;
  margin-left: 10px;
}

.pricing-s1 .ribbon {
  width: 200px;
  position: absolute;
  top: 25px;
  left: -55px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

.pricing-s1.light {
  color: #222;
}

.pricing-s1.light .top {
  background: rgba(var(--secondary-color-rgb), 0.1);
  color: #222;
}

.pricing-s1.light .top h2 {
  color: #222;
}

.pricing-s1.light .top .price b,
.pricing-s1.light .top .price .txt {
  color: #222;
}

.pricing-s1.light .top .price .txt {
  color: #555;
}

.pricing-s1.light .top .price .month {
  color: #555;
}

.pricing-s1.light .top .price .currency {
  color: #555;
}

.pricing-s1.light .bottom {
  background: #eee;
}

.pricing-s1.light .bottom ul li > span {
  color: #16a085;
}

.pricing-s1.light .bottom ul li:nth-child(odd) {
  background: #ffffff;
}

.pricing-s1.light .bottom ul li:nth-child(even) {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-s1 .ribbon {
  color: #fff;
}

.pricing-s1.rec {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);
}

.pricing-s1 .action {
  text-align: center;
  padding: 40px 0;
  border-top: solid 1px #eeeeee;
}

.dark-scheme .pricing-s1 .action {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}

.text-light .pricing-s1 .action {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.pricing-s2 {
  background: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
}

.pricing-s2 .top {
  padding: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.pricing-s2 .top h2 {
  font-size: 22px;
  margin-bottom: 5px;
  letter-spacing: normal;
}

.pricing-s2 .top p {
  margin-bottom: 30px;
}

.pricing-s2 .top > * {
  margin: 0px;
}

.pricing-s2 .top .price {
  margin-bottom: 30px;
  color: #111111;
}

.pricing-s2.text-light .top .price,
.pricing-s2.text-white .top .price {
  color: #ffffff;
}

.pricing-s2 .top .price .currency {
  font-size: 24px;
  vertical-align: top;
  display: inline-block;
}

.pricing-s2 .top .price b {
  font-size: 40px;
  font-family: var(--body-font);
  font-weight: 500;
}

.pricing-s2 .top .price .month {
  font-size: 14px;
}

.pricing-s2 .top .price .txt {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}

.pricing-s2 .top a.btn-main {
  display: block;
  width: 100%;
  padding: 6px 10px;
}

.pricing-s2 .bottom {
  border-top: 0px;
  text-align: center;
}

.pricing-s2 .bottom ul {
  display: block;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  text-align: left;
  overflow: hidden;
}

.pricing-s2 .bottom ul li {
  padding: 15px 30px;
  color: #505050;
}

.pricing-s2 .bottom ul li > span {
  color: #16a085;
  font-size: 20px;
  margin-right: 20px;
}

.pricing-s2 .bottom ul li:nth-child(odd) {
  background: #ffffff;
}

.pricing-s2 .bottom ul li:nth-child(even) {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.pricing-s2 .bottom > a {
  margin: 40px;
  width: auto;
}

.pricing-s2 .bottom i {
  margin-right: 10px;
}

.pricing-s2 .ribbon {
  width: 200px;
  position: absolute;
  top: 25px;
  left: -55px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

.pricing-s2 .inner {
  padding-bottom: 10px;
}

.pricing-s2.bg .inner {
  background: rgba(var(--primary-color-rgb), 0.8);
}

.pricing-s2.bg-2 .inner {
  background: rgba(255, 88, 55, 0.8);
}

.pricing-s2.bg .top p {
  color: #cccccc;
}

.pricing-s2.bg .top h2,
.pricing-s2.bg .top .price,
.pricing-s2.bg .top .price .currency,
.pricing-s2.bg .top .month,
.pricing-s2.bg-2 .top h2,
.pricing-s2.bg-2 .top .price,
.pricing-s2.bg-2 .top .price .currency,
.pricing-s2.bg-2 .top .month {
  color: #ffffff;
}

.spinner {
  display: table-cell;
  vertical-align: middle;
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* preloader */

/* ================================================== */

#preloader {
  display: table;
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  text-align: center;
}

.preloader1 {
  width: 60px;
  height: 60px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 2px solid;
  border-top-color: rgba(0, 0, 0, 0.65);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  -webkit-animation: preloader1 1s ease-in infinite;
  animation: preloader1 1s ease-in infinite;
  position: absolute;
  margin-left: -30px;
  top: 45%;
}

@keyframes preloader1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes preloader1 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

/* progress bar */

/* ================================================== */

.de-progress {
  width: 100%;
  height: 8px;
  background: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  overflow: hidden;
}

.de-progress .progress-bar {
  background: #333;
  height: 8px;
  box-shadow: none;
}

.my_collection .nft__item_wrap {
  height: 245px;
}

.de-progress {
  background: #f5f5f5;
}

.skill-bar {
  margin-bottom: 40px;
}

.skill-bar.style-2 .de-progress {
  background: rgba(0, 0, 0, 0.1);
}

.skill-bar .value {
  color: #ccc;
  font-size: 12px;
  font-weight: bold;
  float: right;
  margin-top: -40px;
}

.rtl .skill-bar .value {
  float: left;
}

.skill-bar h4 {
  font-size: 16px;
}

/* social-icons */

/* ================================================== */

.social-icons {
  display: inline-block;
}

.social-icons i {
  text-shadow: none;
  color: #fff;
  padding: 12px 10px 8px 10px;
  width: 34px;
  height: 34px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 0 3px 0 3px;
}

.social-icons i.fa-google-plus {
  padding: 12px 14px 10px 6px;
}

.social-icons i:hover {
  background: #fff;
  border-color: #eceff3;
  color: #333;
}

.social-icons.big i {
  font-size: 26px;
  width: auto;
  height: auto;
  margin: 0 15px 0 15px;
}

.social-icons.big i:hover {
  background: none;
}

/*
.social-icons .fa-facebook{background:#3e5a9b;}
.social-icons .fa-twitter{background:#55aded;}
.social-icons .fa-google-plus{background:#df4f37;}
.social-icons .fa-linkedin{background:#0075ad;}
.social-icons .fa-rss{background:#fb6506;}
.social-icons .fa-skype{background:#28A8EA;}
*/

.social-icons.big i {
  margin: 0 15px 0 15px;
}

.social-icons.big i:hover {
  background: none;
}

.social-icons.s1 i {
  border: none;
  font-size: 16px;
  margin: 3px;
}

.social-icons.s1:hover i {
  background: none;
}

.social-icons.s1 {
  line-height: 0.5;
  text-align: center;
}

.social-icons.s1 .inner {
  display: inline-block;
  position: relative;
}

.social-icons.s1 .inner:before,
.social-icons.s1 .inner:after {
  content: '';
  position: absolute;
  height: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  top: 0;
  width: 100%;
}

.social-icons.s1 .inner:before {
  right: 100%;
  margin-right: 15px;
  margin-top: 20px;
}

.social-icons.s1 .inner:after {
  left: 100%;
  margin-left: 15px;
  margin-top: 20px;
}

.col-right {
  display: inline-block;
  margin-top: 22px;
}

/* ================================================== */
/* accordion
------------------------------ */

.accordion {
  overflow: hidden;
}

.accordion-section-title {
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  cursor: poInter;
}

.accordion-section-title {
  font-family: var(--body-font);
  color: #111111;
  width: 100%;
  padding: 15px;
  display: inline-block;
  font-size: 16px;
  transition: all linear 0.5s;
  text-decoration: none;
  margin-bottom: 10px;
  border: solid 1px #efefef;
  font-weight: 600;
}

.dark-scheme .react-loading-skeleton {
  --base-color: rgba(255, 255, 255, 0.1) !important;
  --highlight-color: rgba(255, 255, 255, 0.1) !important;
  background-color: var(--base-color) !important;
}

.accordion-section-title:before {
  font-family: 'FontAwesome';
  content: '\f107';
  float: right;
  color: #fff;
  text-align: center;
  padding: 0 8px 0 8px;
  font-size: 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.accordion-section-title.active:before {
  content: '\f106';
  float: right;
}

.accordion-section-title.active {
  margin-bottom: 0px;
  text-decoration: none;
  color: #fff !important;
}

.accordion-section-title:hover {
  text-decoration: none;
}

.accordion-section-content {
  padding: 15px;
  padding-bottom: 5px;
  display: none;
  margin-bottom: 10px;
}

/* bs */

.accordion-item h2 {
  line-height: 24px;
  display: block;
}

.expand-list .expand-custom:nth-child(even) {
  background: #ffffff;
}

.expand-custom {
  background: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
}

.expand-custom h4 {
  margin: 0;
  margin-bottom: 5px;
}

.expand-custom p {
  margin: 0;
}

.expand-custom .table {
  display: table;
}

.expand-custom .toggle {
  display: block;
  margin-top: 10px;
  float: right;
  cursor: poInter;
}

.rtl .expand-custom .toggle {
  float: left;
}

.expand-custom .toggle:before {
  font-family: 'FontAwesome';
  content: '\f107';
  font-size: 32px;
}

.expand-custom .toggle.clicked:before {
  content: '\f106';
}

.expand-custom .c1,
.expand-custom .c2,
.expand-custom .c3 {
  display: table-cell;
  vertical-align: middle;
}

.expand-custom .c1,
.expand-custom .c2 {
  padding-right: 30px;
}

.expand-custom .c1 img {
  width: 80px;
}

.expand-custom .c1 {
  width: 10%;
}

.expand-custom .c2 {
  width: 80%;
}

.expand-custom .c3 {
  width: 10%;
}

.expand-custom .details {
  display: none;
  margin-top: 30px;
}

.expand-custom .box-custom {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.de_tab .de_nav {
  overflow: hidden;
  padding-left: 0;
  margin: 0;
  padding: 0;
  font-weight: bold;
  /* border-bottom: 2px solid #e6e8ec; */
}

/* .dark-scheme .de_tab .de_nav {
  border-bottom: 2px solid rgb(241 245 249 / 10%);
} */

.de_tab .de_nav li {
  float: left;
  list-style: none;
  background: #fff;
  /* margin-right: 10px;
    margin-bottom: 10px; */
  /* border-radius: 5px 5px 0 0; */
}

.dark-scheme .de_tab .de_nav li {
  background: transparent;
}

.rtl .de_tab .de_nav li {
  float: right;
}

.d_coll .de_tab .de_nav {
  text-align: center;
}

.d_coll .de_tab .de_nav li {
  float: none;
  display: inline-block;
  margin-right: 5px;
}

.de_tab .de_nav li span {
  border: solid 1px rgba(0, 0, 0, 0.2);
  display: block;
  text-decoration: none;
  color: #646464;
  font-size: 17px;
  cursor: poInter;
}

.dark-scheme .de_tab .de_nav li span {
  color: rgba(255, 255, 255, 0.3);
}

.de_tab.tab_methods .de_nav li span {
  border: solid 2px rgba(0, 0, 0, 0.1);
}

.de_tab.tab_methods .de_nav li {
  background: none;
  display: inline-block;
  float: none;
  margin: 0 10px 0 0;
}

.de_tab.tab_methods .de_nav li span {
  padding: 35px 10px;
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 12px;
  min-width: 140px;
}

.dark-scheme .de_tab.tab_methods .de_nav li span {
  color: rgba(255, 255, 255, 0.6);
}

.dark-scheme .de_tab.tab_methods .de_nav li.active span {
  color: #ffffff;
}

.de_tab.tab_methods .de_nav li span i {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
}

.de_tab.tab_methods.style-2 .de_nav li span {
  background: none;
  border-radius: 0px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  font-size: 16px;
  color: #fff;
}

.de_tab.tab_methods.style-2.light .de_nav li span {
  background: #eee;
  color: #707070;
}

.de_tab.tab_methods.style-2 .de_nav li.active span {
  border: solid 2px rgba(255, 255, 255, 0.8);
  color: #222;
}

.de_tab.tab_methods .de_nav li .v-border {
  height: 60px;
  border-left: solid 1px rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin-bottom: -5px;
}

.de_tab.tab_methods.dark .de_nav li .v-border {
  border-left: solid 1px rgba(0, 0, 0, 0.3);
}

.de_tab .de_nav li span {
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
}

.de_tab .de_tab_content {
  padding-top: 40px;
}

.de_tab .de_tab_content .nft_attr:hover {
  background: #fff !important;
}

.dark-scheme .de_tab .de_tab_content .nft_attr:hover {
  background: transparent !important;
}

.dark-scheme svg .dark {
  fill: #fff;
}

/* .de_tab .de_tab_content .tab-1.tab_content {
    border: 1px solid rgba(155, 155, 155, 0.30);
    border-radius: 8px;
    padding: 20px;
} */
/* .dark-scheme .de_tab .de_tab_content .tab-1 {
    border: 1px solid rgba(255, 255, 255, .1);
} */

.de_tab.tab_methods .de_tab_content {
  background: 0;
  padding: 0;
  border: none;
  padding-top: 30px;
}

.de_tab.tab_methods.dark .de_tab_content {
  border-top: solid 1px rgba(0, 0, 0, 0.4);
}

.de_tab .de_nav li.active span {
  background: #fff;
  color: #222;
}

.de_tab .de_nav li.active span .v-border {
  display: none;
}

.de_tab .de_nav li.active span {
  background: #27282b;
}

.de_tab.tab_methods .de_nav li.active span {
  background: none;
}

.de_tab.tab_methods.dark .de_nav li.active span {
  background: none;
  color: #333;
  border: solid 1px rgba(0, 0, 0, 0.8);
}

/* new added */

.de_tab.tab_methods.tab_6 .de_nav li span {
  padding: 20px 0 20px 0;
  font-size: 14px;
  line-height: 70px;
  width: 120px;
  height: 120px;
}

.de_tab.tab_methods.style-2 .de_nav li .arrow {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  margin: 0 auto;
  margin-bottom: 20px;
}

.de_tab.tab_methods.style-2 .de_nav li.active .arrow {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid;
  margin: 0 auto;
}

.de_tab.tab_methods.style-2 .de_nav li {
  margin: 5px;
  display: inline-block;
}

.de_tab.tab_methods.style-2 .de_nav li span {
  font-family: var(--body-font);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  font-size: 16px;
  font-weight: lighter;
  color: #fff;
  opacity: 0.8;
  width: 120px;
  height: 100px;
  border: none;
  padding: 20px;
  border-radius: 300px;
  -moz-border-radius: 300px;
  -webkit-border-radius: 300px;
  -webkit-border-radius: 300px;
}

.de_tab.tab_methods.style-2 .de_nav li i {
  display: block;
  font-size: 36px;
  margin-bottom: 10px;
}

.de_tab.tab_methods.style-2.sub-style-2 .de_nav li span {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  opacity: 0.8;
}

.de_tab.tab_methods.style-2 .de_nav li.active span {
  color: #222;
  opacity: 1;
  border: none;
}

.de_tab.tab_methods.style-2 .de_nav li span:hover {
  border: none;
}

.de_tab.tab_methods.style-2 .de_tab_content {
  border: none;
}

.de_tab.tab_simple .de_nav li {
  min-width: 116px;
  text-align: center;
  margin-right: 15px;
}
.de_tab.tab_simple .de_nav li:last-child {
  margin-right: 0;
}

.de_tab.tab_simple .de_nav li span {
  /* padding: 8px 15px;
  border: none;
  color: #727272; */
  color: #141416;
  padding: 11px 22px;
  border-radius: 100px;
  border: 1.2px solid #eef1f9;
  background: #fefefe;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.44px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.dark-scheme .de_tab.tab_simple .de_nav li span {
  color: #fff;
  border: none;
  background: rgba(241, 245, 249, 0.05);
}

.de_tab.tab_simple .de_nav li.active span {
  /* color: #ffffff; */
  color: #000;
  /* background: rgba(241, 245, 249, 0.05); */
}

.dark-scheme .de_tab .de_nav li.active span {
  background: var(--button-primary-bg);
}

.de_tab.tab_simple .de_nav li span i {
  padding-right: 10px;
}

.rtl .de_tab.tab_simple .de_nav li span i {
  padding-right: 0;
  padding-left: 10px;
}

/* tab de_light */

.de_light .de_tab.tab_style_2 .de_tab_content {
  border: none;
  background: none;
  padding: 40px 0 0 0;
  border-top: solid 1px #ddd;
}

.text-light .de_tab.tab_style_2 .de_tab_content {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.de_light .de_tab.tab_style_2 .de_nav li {
  float: none;
  list-style: none;
  background: none;
  margin: 0 5px 0 5px;
  text-align: center;
  display: inline-block;
}

.de_light .de_tab.tab_style_2 .de_nav li img {
  width: auto;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.de_light .de_tab.tab_style_2 .de_nav li.active img {
  width: auto;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.de_light .de_tab.tab_style_2 .de_nav li span {
  background: none;
  display: block;
  text-decoration: none;
  font-size: 14px;
  cursor: poInter;
  border: none;
}

.de_light .de_tab.tab_style_2 .de_nav li.active span {
  border-bottom: solid 6px;
  color: #333;
  background: none;
}

.text-light .de_tab.tab_style_2 .de_nav li.active span {
  color: #fff;
}

/* tab de_light */

.de_light .de_tab.tab_style_3 .de_tab_content {
  border: none;
  background: none;
  padding: 40px 0 0 0;
  border-top: solid 1px #ddd;
}

.text-light .de_tab.tab_style_3 .de_tab_content {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.de_light .de_tab.tab_style_3 .de_nav {
  text-align: center;
  background: #eee;
  display: inline-block;
  border-radius: 60px;
  margin-bottom: 30px;
  padding: 0;
}

.de_light .de_tab.tab_style_3 .de_nav li {
  float: none;
  list-style: none;
  background: none;
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.de_light .de_tab.tab_style_3 .de_nav li img {
  width: auto;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.de_light .de_tab.tab_style_3 .de_nav li.active img {
  width: auto;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.de_light .de_tab.tab_style_3 .de_nav li span {
  background: none;
  display: block;
  text-decoration: none;
  font-size: 14px;
  cursor: poInter;
  border: none;
  padding: 5px 30px 5px 30px;
  min-width: 150px;
}

.de_light .de_tab.tab_style_3 .de_nav li:last-child span {
  border-radius: 0 60px 60px 0;
}

.de_light .de_tab.tab_style_3 .de_nav li:first-child span {
  border-radius: 60px 0 0 60px;
}

.de_light .de_tab.tab_style_3 .de_nav li span:hover {
  background: #ddd;
}

.de_light .de_tab.tab_style_3 .de_nav li.active span {
  background: #eee;
  color: #fff;
}

.text-light .de_tab.tab_style_3 .de_nav li.active span {
  color: #fff;
}

/* tab de_light */

.de_tab.tab_style_4 .de_tab_content {
  border: none;
  background: none;
  padding: 0;
  border-top: none;
}

.text-light .de_tab.tab_style_4 .de_tab_content {
  border-top: none;
}

.de_tab.tab_style_4 .de_nav {
  text-align: center;
  background: #eee;
  display: inline-block;
  border-radius: 60px;
  margin-bottom: 30px;
  padding: 0;
  border: solid 2px #333;
}

.de_tab.tab_style_4 .de_nav li img {
  width: auto;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.de_tab.tab_style_4 .de_nav li.active img {
  width: auto;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.de_tab.tab_style_4 .de_nav li {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  cursor: poInter;
  border: none;
  padding: 15px 30px 15px 30px;
  min-width: 150px;
  margin: 0;
  border-right: solid 2px #333;
}

.de_tab.tab_style_4 .de_nav li span {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  width: auto;
  display: inline-block;
}

.de_tab.tab_style_4 .de_nav li:last-child {
  border-radius: 0 60px 60px 0;
  border-right: none;
}

.de_tab.tab_style_4 .de_nav li:first-child {
  border-radius: 60px 0 0 60px;
}

.de_tab.tab_style_4 .de_nav li:hover {
  background: #ddd;
}

.de_tab.tab_style_4 .de_nav li.active,
.de_tab.tab_style_4 .de_nav li.active span {
  background: #333;
  color: #fff;
}

.text-light .de_tab.tab_style_4 .de_nav li.active {
  color: #fff;
}

.de_tab.tab_style_4 .de_nav li.active h4,
.de_tab.tab_style_4 .de_nav li.active h4,
.de_tab.tab_style_4 .de_nav li.active h4 span {
  color: #fff;
}

.tab_style_4 h4,
.de_tab.tab_style_4 .de_nav li span {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.tab_style_4 h4 {
  margin: 0;
  font-size: 12px;
}

.de_tab .de_tab_content.tc_style-1 {
  background: none;
  padding: 0;
  padding-top: 30px;
}

.tab-small-post ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-small-post ul li {
  min-height: 60px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid 1px #eeeeee;
}

.tab-small-post ul li {
  border-bottom: solid 1px #202220;
}

.tab-small-post ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.tab-small-post ul li img {
  position: absolute;
}

.tab-small-post ul li span {
  display: block;
  padding-left: 64px;
}

.tab-small-post ul li span.post-date {
  font-size: 11px;
}

.de_tab.timeline.nav_4 li {
  display: inline-block;
  text-align: center;
  background: none;
  width: 24%;
}

.de_tab.timeline.nav_4 li span {
  background: none;
  border: none;
  margin-top: 20px;
  font-family: var(--body-font);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.de_tab.timeline.nav_4 .dot {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 1;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 1px;
}

.de_tab.timeline.nav_4 .dot:before,
.de_tab.timeline.nav_4 .dot:after {
  content: '';
  position: absolute;
  height: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  top: 0;
  width: 24%;
}

.de_tab.timeline.nav_4 li:last-child .dot:before,
.de_tab.timeline.nav_4 li:last-child .dot:after {
  border-bottom: none;
}

.de_tab.timeline li .num {
  font-size: 20px;
}

.de_tab.timeline .de_tab_content {
  background: none;
  border: none;
  padding-top: 30px;
}

.de_tab .navigation_arrow {
  text-align: center;
}

.btn-left,
.btn-right {
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 10px 20px 10px 20px;
  margin: 5px;
  font-family: var(--body-font);
  text-transform: uppercase;
  letter-spacing: 5px;
}

.btn-right:after {
  font-family: FontAwesome;
  content: '\f054';
  padding-left: 10px;
}

.btn-left:before {
  font-family: FontAwesome;
  content: '\f053';
  padding-right: 10px;
}

a.btn-left,
a.btn-right {
  text-decoration: none;
}

/* team
    /* ================================================== */

.team {
  margin-bottom: 30px;
  text-align: center;
}

.team h5 {
  margin-bottom: 0;
}

.team_photo {
  margin-bottom: 10px;
}

.team img.team-pic {
  width: 100%;
  height: auto;
}

.pic-team img {
  width: 100%;
  height: auto;
  width: 150px;
  height: 150px;
  position: absolute;
}

.pic-team img.pic-hover {
  z-index: 100;
  display: none;
}

.team .team_desc {
  font-style: italic;
  margin-bottom: 10px;
}

.team .sb-icons {
  padding: 10px 0 0 0;
}

.team .sb-icons img {
  margin-right: 5px;
}

.team .columns {
  margin-bottom: 30px;
}

.team-list {
  min-height: 180px;
  margin-bottom: 40px;
}

.team-list .team-pic {
  width: 150px;
  height: 150px;
  position: absolute;
}

.team-list h5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.team-list.team_desc {
  font-style: italic;
  margin-bottom: 10px;
}

.team-list .small-border {
  float: none;
  margin: 15px 0 15px 0;
  background: #ddd;
  width: 50px;
}

.team-list .social {
  width: 150px;
  margin-top: 170px;
  text-align: center;
  position: absolute;
}

.team-list .social a {
  display: inline-block;
}

.team-list .info {
  padding-left: 170px;
}

.de-team-list {
  overflow: hidden;
}

.de-team-list .team-desc {
  padding: 20px;
  padding-bottom: 0;
  position: absolute;
  z-index: 100;
  top: 0;
  background: #111;
  color: #ccc;
}

.de-team-list .team-desc h4 {
  color: #fff;
}

.de-team-list .social a {
  display: inline-block;
  padding: 0 5px 0 5px;
}

.de-team-list p.lead {
  font-size: 14px;
}

.de-team-list .small-border {
  float: none;
  margin: 15px 0 15px 0;
  background: #ddd;
  width: 50px;
}

.de-team-list .social a:hover {
  color: #fff;
}

/* testimonial
    /* ================================================== */

.de_testi {
  display: block;
  margin-bottom: 10px;
}

.de_testi blockquote {
  margin: 0;
  border: none;
  padding: 20px 30px 30px 80px;
  background: rgba(var(--secondary-color-rgb), 0.1);
  font-style: normal;
  line-height: 1.6em;
  font-weight: 500;
}

.de_testi blockquote p {
  margin-top: 10px;
}

.de_testi blockquote:before {
  font-family: FontAwesome;
  content: '\f10d';
  padding-bottom: 10px;
  font-size: 20px;
  display: inline-block;
  padding: 10px 20px 10px 20px;
  font-style: normal;
  background: #000;
  position: absolute;
  left: 15px;
  font-weight: normal;
}

.de_testi blockquote {
  color: #eceff3;
}

.de_testi blockquote,
.de_testi blockquote p {
  font-size: 20px;
  line-height: 1.7em;
  font-weight: normal;
}

.de_testi_by {
  font-style: normal;
  font-size: 12px;
  display: flex;
  margin-top: -20px;
}

.de_testi_by span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.de_testi_by img {
  width: 50px !important;
  height: auto;
  margin-right: 10px;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}

.de_testi_pic {
  float: left;
  padding-right: 15px;
}

.de_testi_company {
  padding-top: 20px;
}

.de_testi.opt-2 blockquote {
  display: block;
  font-family: var(--body-font);
  border: none;
  color: #606060;
  font-weight: 300;
  padding: 30px 40px 30px 40px;
  background: rgba(var(--secondary-color-rgb), 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.de_testi.opt-2.no-bg blockquote {
  background: none;
}

.de_testi.opt-2 blockquote:before {
  font-family: 'FontAwesome';
  content: '\f10d';
  display: block;
  color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  font-size: 20px;
  background: none;
  left: 20px;
}

.de_testi.opt-2 i {
  font-size: 36px;
}

.de_testi.opt-2.review blockquote {
  padding: 50px;
}

.de_testi.opt-2.review blockquote:before {
  display: none;
}

.de_testi.opt-2.review .p-rating {
  font-size: 14px;
}

.de_testi.opt-2.review p {
  padding-top: 0;
}

.de_testi.opt-2.review h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.text-light .de_testi.opt-2 blockquote {
  color: #fff;
  background: rgba(20, 20, 20, 0.5);
}

.de_testi.opt-2 blockquote p {
  font-family: var(--body-font);
  font-size: 16px;
  padding: 40px 0 0 0;
  font-weight: 400;
  line-height: 1.7em;
}

.de_testi.opt-2 .de_testi_by {
  font-size: 14px;
  font-weight: normal;
}

.de_testi.opt-3 blockquote {
  background: none;
  color: #555;
  padding-top: 0;
  font-weight: 400;
}

.de_testi.opt-3 blockquote p {
  font-size: 14px;
}

.de_testi.opt-3 blockquote:before {
  color: #fff;
}

.de_testi.opt-3 .de_testi_by {
  font-size: 14px;
  color: #000;
}

.de_testi.de-border blockquote {
  border: solid 5px rgba(0, 0, 0, 0.05);
}

#testimonial-masonry .item {
  margin-bottom: 30px;
}

.testimonial-list p {
  font-style: italic;
}

.testimonial-list,
.testimonial-list li {
  list-style: none;
  padding: 0px;
  font-size: 16px;
  line-height: 1.7em;
  padding-left: 30px;
}

.testimonial-list span {
  font-weight: bold;
  display: block;
  margin-top: 20px;
  font-size: 14px;
}

.testimonial-list.big-font li {
  font-size: 28px;
}

.testimonial-list:before {
  font-family: FontAwesome;
  content: '\f10d';
  font-size: 40px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-left: -30px;
}

.testimonial-list.style-2,
.testimonial-list.style-2 li {
  padding-left: 0px;
}

.testimonial-list.style-2 {
  text-align: center;
}

.testimonial-list.style-2:before {
  margin: 0;
  position: static;
}

.testimonial-list.style-2 span {
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
}

/* timeline */

/* timeline */

.timeline {
  position: relative;
  padding: 10px;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 127px;
  height: 100%;
  width: 1px;
  background: #eee;
}

.text-light .timeline:before {
  background: rgba(255, 255, 255, 0.1);
}

.timeline .tl-block {
  position: relative;
  margin-top: 10px;
}

.timeline .tl-block:after {
  content: ' ';
  clear: both;
  display: block;
}

.timeline .tl-block .tl-time {
  float: left;
  padding: 10px 20px 0px 20px;
  margin-top: -15px;
  border: solid 1px #ddd;
}

.timeline .tl-block .tl-time:after {
  width: 50px;
  height: 1px;
  background: #ddd;
}

.timeline .tl-block .tl-bar {
  float: left;
  width: 5%;
  position: absolute;
  margin-left: 114px;
}

.timeline .tl-block .tl-line {
  background: #333;
  margin-left: 10px;
  border-radius: 10px;
  width: 8px;
  height: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
}

.timeline .tl-message {
  border-radius: 3px;
  float: left;
  margin-left: 150px;
  top: 0;
  margin-top: -50px;
  margin-bottom: 30px;
}

.timeline .tl-block .tl-message:after {
  display: block;
  content: ' ';
  clear: both;
}

.timeline .tl-block .tl-message .tl-main {
  float: left;
}

.timeline .tl-block .tl-message .tl-main .tl-content label {
  font-weight: bold;
  display: inline-block;
  width: 80px;
}

.timeline .tl-block .tl-message h4 {
  text-transform: none;
}

.timeline .tl-block .tl-time h4 {
  margin: 0 0 10px 0;
}

.timeline .tl-block .tl-main h4 {
  margin-top: -24px;
}

.timeline.exp:before {
  left: 214px;
}

.timeline.exp .tl-message {
  margin-left: 250px;
}

.timeline.exp .tl-block .tl-bar {
  margin-left: 200px;
}

/* timeline */

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 1px;
  background-color: #ddd;
  left: 50%;
}

.timeline > li {
  position: relative;
  margin-top: -50px;
}

.timeline > li:first-child {
  position: relative;
  margin-top: 0px;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  /* border: 1px solid #d4d4d4; */
  border-radius: 2px;
  position: relative;
  background: rgba(var(--secondary-color-rgb), 0.1);
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -14px;
  display: inline-block;
  border-top: 15px solid transparent;
  /* border-left: 15px solid #ccc;
  border-right: 0 solid #ccc; */
  border-bottom: 15px solid transparent;
  content: ' ';
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 43%;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #f5f5f5;
  border-right: 0 solid #f5f5f5;
  border-bottom: 14px solid transparent;
  content: ' ';
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 40px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  margin-left: -7px;
  background-color: #fff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 15px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-heading img {
  float: right;
  position: absolute;
  top: 30px;
  right: 30px;
}

.timeline .timeline-story {
  position: relative;
  padding: 40px;
}

.timeline-inverted .timeline-story {
  position: relative;
  padding: 40px;
  padding-left: 50px;
}

.fs_13 {
  font-size: 13px;
}
.fs_16 {
  font-size: 16px;
}
.analytics-section h5 {
  font-size: 16px;
}
.fs_18{
  font-size: 18px !important;
}
.analytics-section h5.truncate_name {
  font-size: 22px;
}

.timeline .wm {
  font-size: 200px;
  position: absolute;
  bottom: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.timeline h5 {
  background: #bbb;
  color: #ffffff;
  padding: 5px 20px 3px 20px;
  display: inline-block;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  margin-bottom: 15px;
}

.timeline-inverted h5 {
  float: left;
}

/* Timeline */
.container-timeline {
  position: relative;
}

.container-timeline ul {
  margin: 0;
  padding: 0;
  padding-left: 20px;
  display: inline-block;
  counter-reset: wa-process-counter;
}

.container-timeline ul li {
  list-style: none;
  margin: 0;
  border-left: 1px solid green;
  padding: 0 0 30px 40px;
  position: relative;
  counter-increment: wa-process-counter;
}

.container-timeline ul li:last-child {
  border: 0;
}

.container-timeline ul li::before {
  position: absolute;
  left: -21px;
  top: 0;
  content: counter(wa-process-counter);
  border: 1px solid green;
  border-radius: 5px;
  background: white;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 41px;
}

.timelines h2 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 32px;
}

.d-flex-2 {
  display: flex;
  align-items: center;
}

.timeline-area {
  padding: 80px 0;
}

.all-timelines {
  position: relative;
}

.timelines h2 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 40px;
}

.all-timelines::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 2px;
  background: #efa22f;
  top: 20px;
}

.single-timeline {
  margin-bottom: 22px;
}

.timeline-blank {
  width: 50%;
}

.timeline-text {
  width: 50%;
  padding-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.timeline-text h6 {
  color: #f0f1f2;
  font-weight: 900;
  display: inline-block;
  font-size: 1rem;
}

.timeline-text span {
  color: #f0f1f2;
  display: block;
  width: 100%;
}

.single-timeline:nth-child(even) .timeline-text span {
  text-align: right;
}

.t-circle {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: -6px;
  background: #efa22f;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.single-timeline:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.single-timeline:nth-child(even) .t-circle {
  right: -6px;
  left: unset;
}

.single-timeline:nth-child(even) .timeline-text {
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}

.preview_card {
  height: 100%;
  position: sticky;
  top: 100px;
  width: 24%;
  margin-left: auto;
}

.preview_card h5 {
  font-size: 22px;
}

@media all and (max-width: 768px) {
  .listing_view_img.on_sale {
    width: 62px;
    height: 62px;
  }
  .transaction_activity_filter .activity-filter li {
    padding: 8px 10px;
  }
  .d_file_holder {
    height: 220px;
  }
  .d_file_holder.banner_height {
    height: 220px;
  }
  .single_collection {
    height: 300px;
  }
  .w_600 {
    width: 100%;
  }
  a.icon-box.style-2 svg {
    width: 120px;
    height: 120px;
  }

  .transaction_activity_filter {
    flex-direction: column;
    gap: 20px;
    align-items: inherit !important;
  }

  .transaction_activity_filter .activity-filter {
    margin-right: 0;
  }

  h1,
  .h1 {
    font-size: 38px;
    line-height: 1.1em;
  }

  h2 {
    margin-top: 10px;
    font-size: 26px;
    line-height: 36px;
  }

  .all-timelines::before {
    right: unset;
    top: 0;
  }

  .single-timeline:nth-child(2n) .timeline-text {
    padding-left: 30px;
    padding-right: 0;
    text-align: left;
  }

  .single-timeline:nth-child(2n) .t-circle {
    left: -6px;
    right: unset;
  }

  .timeline-blank {
    display: none;
  }

  .timeline-text {
    width: 100%;
  }

  .single-timeline:nth-child(even) .timeline-text span {
    text-align: left !important;
  }
  .chart_holder {
    width: 100%;
    padding: 10px;
    margin: 0 0 16px;
  }
  .flex_chart_wrap {
    flex-wrap: wrap;
  }
  .flex_chart_wrap.gap-3 {
    gap: 0 !important;
  }
  .flex_chart_wrap .w-50 {
    width: 100% !important;
  }
}

@media all and (max-width: 360px) {
  .all-timelines::before {
    top: 32px;
  }
}

/* css attributes */

/* margin top */

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

/* margin bottom */

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml0 {
  margin-left: 0;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml100 {
  margin-left: 100px;
}

.mr0 {
  margin-right: 0;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mr80 {
  margin-right: 80px;
}

.mr90 {
  margin-right: 90px;
}

.mr100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: -100px;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.padding40 {
  padding: 40px;
}

.padding50 {
  padding: 50px;
}

.padding60 {
  padding: 60px;
}

.padding70 {
  padding: 70px;
}

.padding80 {
  padding: 80px;
}

.padding100 {
  padding: 100px;
}

.pt0 {
  padding-top: 0;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pb0 {
  padding-bottom: 0;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb80 {
  padding-bottom: 80px;
}

.pl130 {
  padding-left: 130px;
}

.pr20 {
  padding-right: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pr30 {
  padding-right: 30px;
}

/* margin top */

.mt-10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-50 {
  margin-top: -50px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-70 {
  margin-top: -70px;
}

.mt-80 {
  margin-top: -80px;
}

.mt-90 {
  margin-top: -90px;
}

.mt-100 {
  margin-top: -100px;
}

.absolute {
  position: absolute;
  z-index: 1;
}

.relative {
  position: relative;
  z-index: 1;
}

.z1000 {
  z-index: 1000;
}

.overflow-hidden {
  width: 100%;
  height: 100%;
  position: relative;
}

.width100 {
  width: 100%;
}

.border1 {
  border-width: 1px;
}

.bordertop {
  border-top: solid 1px #eee;
}

.borderbottom {
  border-bottom: solid 1px #eee;
}

.pos-top {
  position: relative;
  z-index: 1000;
}

/* ================================================== */

/* anim */

/* ================================================== */

.de_tab .de_nav li span,
.feature-box-small-icon i,
a.btn-line:after,
.de_count,
.social-icons a i,
.de_tab.tab_methods .de_nav li span,
.de_tab.tab_methods .de_nav li span:hover,
.de-gallery .overlay .icon i,
.de-gallery .overlay .icon i:hover,
.product img,
.product img:hover,
.mfp-close,
.mfp-close:hover,
.pic-hover .bg-overlay,
.pic-hover:hover > .bg-overlay,
i.btn-action-hide,
.pic-hover.hover-scale img,
.text-rotate-wrap,
.text-rotate-wrap .text-item,
.overlay-v,
.carousel-item .pf_text .title,
.carousel-item:hover .pf_text .title,
.carousel-item .pf_text .title .type,
.carousel-item:hover .pf_text .title .type,
.owl-slider-nav .next,
.owl-slider-nav .prev,
#back-to-top.show,
#back-to-top.hide,
.social-icons-sm i,
.social-icons-sm i:hover,
.overlay-v i,
.overlay-v i:hover,
.de_tab .de_nav li span,
#popup-box.popup-show,
#popup-box.popup-hide,
.form-underline input[type='text'],
.form-underline textarea,
.form-underline input[type='email'],
.form-underline select,
.btn-rsvp,
.d-btn-close,
.btn-main,
.overlay-v span,
.de_tab.tab_style_4 .de_nav li,
.form-subscribe input[type='text'],
#mainmenu a span,
#mainmenu a.active span,
.feature-box .inner,
.feature-box:hover .inner,
.feature-box .inner i,
.feature-box:hover .inner i,
.error_input,
.de_tab.tab_methods.style-2 .de_nav li.active .arrow,
.owl-item.active.center div blockquote,
.mask *,
.mask .cover,
.mask:hover .cover,
.mask img,
.mask:hover img,
.accordion .content,
a.btn-border,
.f_box span,
.f_box img,
.f_box.s2:hover img,
.mask .cover p,
.feature-box-type-1:hover i,
.feature-box-type-2 i,
#contact_form input,
#form_subscribe input[type='text'],
.table-pricing tbody tr,
.feature-box.f-boxed,
.feature-box.f-boxed:hover,
.fp-wrap,
.fp-wrap div,
.fp-wrap img,
.feature-box-type-2,
.feature-box.style-3 i,
.feature-box.style-3 h4,
.logo-carousel img,
.f-box,
.f-box *,
.fp-wrap *,
.d-gallery-item *,
.de-box-image *,
.de-image-hover * a.a-underline *,
.nft_coll *,
.nft__item,
.dropdown *,
.icon-box.style-2 *,
.nft__item img,
#quick_search,
.form-border *,
.nft_pic *,
.d-carousel *,
.nft__item_like *,
.nft__item_click span:after,
.nft__item_extra,
.nft__item_extra *,
.opt-create *,
.de-submenu,
#selector *,
.box-url * {
  -o-transition: 0.7s;
  -ms-transition: 0.7s;
  -moz-transition: 0.7s;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  outline: none;
}

a.btn-fx:after,
a.btn-fx:hover:after,
a.btn-fx:before,
a.btn-fx:hover:before,
#mainmenu li,
#mainmenu li ul,
.widget-post li .post-date,
.author_list_pp img,
.items_filter *,
.owl-prev,
.owl-next,
.play-pause {
  -o-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  outline: none;
}

.feature-box:hover i.wm,
#selector #demo-rtl {
  -o-transition: 2s ease;
  -ms-transition: 2s ease;
  -moz-transition: 2s ease;
  -webkit-transition: 2s ease;
  transition: 2s ease;
  outline: none;
}

header.header-mobile #mainmenu > li > span,
.menu_side_area *,
header #quick_search {
  -o-transition: none;
  -ms-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
  outline: none;
}

.nft_pic {
  overflow: hidden;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  position: relative;
}

.carousel_arrow {
  height: 100px;
  width: 105px;
  background: #fff;
  border-radius: 100px;
  border: none;
  padding-right: 20px;
  text-align: right;
}
.dark-scheme .carousel_arrow {
  background: #181818;
}
.dark-scheme .carousel_arrow svg path {
  stroke: #fff;
}
.carousel_arrow.right {
  padding-left: 20px;
  text-align: left;
}
.arrows_left_container,
.arrows_right_container {
  position: absolute;
  z-index: 10;
  top: 42%;
}
.arrows_left_container {
  left: -60px;
}
.arrows_right_container {
  right: -60px;
}
.nft_pic.style-2 {
  margin: 25px;
}

/* .nft_pic:hover * {
    -o-transition: 3s;
    -ms-transition: 3s;
    -moz-transition: 3s;
    -webkit-transition: 3s;
    transition: 3s;
    outline: none;
} */

@-webkit-keyframes scaleIn {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleIn {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
}

@-webkit-keyframes scaleInFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleInFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleInFade {
  -webkit-animation-name: scaleInFade;
  animation-name: scaleInFade;
}

@-webkit-keyframes scaleOutFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleOutFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleOutFade {
  -webkit-animation-name: scaleOutFade;
  animation-name: scaleOutFade;
}

@-webkit-keyframes widthInFade {
  from {
    opacity: 0;
    width: 1px;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

@keyframes widthInFade {
  from {
    opacity: 0;
    width: 1px;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

.widthInFade {
  -webkit-animation-name: widthInFade;
  animation-name: widthInFade;
}

/* ================================================== */

/* video */

/* ================================================== */

object {
  display: inline-block;
  vertical-align: baseline;
  /* min-width: 95%;
    min-height: 95%; */
  max-width: 95%;
  max-height: 95%;
  object-fit: cover;
  border-radius: 8px;
}

.nft-image-wrapper video {
  display: inline-block;
  vertical-align: baseline;
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
  border-radius: 8px;
}

.de-video-container {
  top: 0%;
  left: 0%;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.de-video-content {
  width: 100%;
  position: absolute;
  z-index: 10;
}

.de-video-overlay {
  position: absolute;
  width: 100%;
  min-height: 100%;
  background: rgba(20, 20, 20, 0.5);
}

.de-video-overlay.dotted {
  /* background: url(../images/dotted.png); */
}

.video-fixed {
  width: 100%;
  position: fixed;
  top: 0;
}

/* youtube, vimeo */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.de-video-container .btn-line:hover:after,
.de-video-container a.btn-line:hover:after,
.de-video-container a.btn-line.hover:after {
  color: #eceff3;
}

/* ================================================== */

/* map */

/* ================================================== */

/* --------------- map --------------- */

#map {
  width: 100%;
  height: 500px;
}

.map iframe {
  width: 100%;
  border: solid 1px #ccc;
  padding: 2px;
  background: #fff;
}

.map-container {
  width: 100%;
  height: 380px;
  overflow: hidden;
}

.map-container iframe {
  width: 100%;
}

/* ================================================== */

/* 1A1C26 to action */

/* ================================================== */

.call-to-action-box {
  background: #f6f6f6;
  border: solid 1px #ddd;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(242, 242, 242, 1))
  );
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0);
}

.call-to-action-box {
  background: #27282b;
  border: solid 1px #202220;
}

.call-to-action-box .inner {
  padding: 20px 20px 20px 20px;
}

.call-to-action-box .text {
  margin-right: 20px;
  float: left;
}

.call-to-action-box .act {
  float: right;
}

.call-to-action-box h4 {
  margin: 0;
  margin-top: 10px;
}

.call-to-action-box .btn {
  color: #eceff3;
}

.call-to-action-box {
  background: #202220;
  color: #eceff3;
}

.call-to-action-boxh4 {
  color: #eceff3;
}

/* ================================================== */

/* overide bootstrap */

/* ================================================== */

.dark-scheme .modal-header,
.nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .modal-content {
  background: #212121;
}

.dark-scheme .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .accordion-body {
  background: #21273e;
  border-color: var(--dark-scheme-color-2);
}

.nav-tabs .nav-link {
  font-weight: 600;
}

.tab-default .tab-content {
  margin-top: 20px;
}

.container-fluid {
  padding: 0px;
  margin: 0px;
}

.btn-primary {
  text-shadow: none;
  border: none;
}

.btn.btn-primary:hover {
  background: #555;
}

.img-responsive {
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

/* shadow */
.de-submenu {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

img.img-auto {
  width: auto;
  height: auto;
}

img.img-rounded {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

#upload_profile_img,
#upload_banner_img {
  display: none;
}

.d-profile-img-edit:active,
.d-banner-img-edit:active,
.de-menu-profile:active img,
.de-menu-notification:active {
  transform: scale(0.8);
  opacity: 0.3;
}

.d-profile-img-edit {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.d-banner-img-edit {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.box-rounded {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.img-profile {
  border: solid 3px #fff;
}

.label {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.table_main_holder {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
}
.detail_page_table {
  margin-top: 40px;
}
.dark-scheme .table_main_holder {
  background: rgba(255, 255, 255, 0.04);
}

.table-responsive {
  overflow-y: auto;
  max-height: 300px;
}
.cancel_cart_btn {
  padding: 8px 12px !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
.table-responsive .table_div {
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 0 10px;
  margin: 0 10px;
}
.table-responsive.scroll_transaction {
  max-height: 500px;
}
.table-responsive.detail_scroll_transaction {
  max-height: 270px;
}
.table_main_holder .table-responsive .table > tbody {
  vertical-align: middle;
}
.dark-scheme .table-responsive .table_div {
  border: 1px solid rgba(241, 245, 249, 0.05);
}
.vanry_text {
  color: #121212;
  padding-left: 2px;
}
.dark-scheme .vanry_text {
  color: #fff;
}

table thead {
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f9f9f9;
}

.dark-scheme thead {
  background: #212121;
}

p.lead {
  font-size: 18px;
  line-height: 1.7em;
  margin-top: 0;
  font-weight: 400;
  min-height: 100px;
}

.main-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-truncate-1 {
  display: -webkit-box;
  max-width: 700px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-slider {
  display: -webkit-box !important;
  max-width: 700px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

p.lead.big {
  font-size: 18px;
}

p.sub {
  font-size: 14px;
}

p.p-info {
  font-size: 16px;
  margin: 0;
  padding: 0 0 10px 0;
}

.panel-group {
  opacity: 0.8;
}

.panel-heading,
.panel-body {
  background: #27282b;
  border: solid 1px #202220;
  border-radius: 0;
}

.panel-body {
  border-top: none;
}

.progress {
  background: #27282b;
}

.panel-heading h4 a {
  display: block;
}

.panel-heading h4 a:hover {
  color: #eceff3;
}

.panel-default,
.panel-collapse,
.panel-collapse,
.panel-group .panel {
  border: none;
  border-radius: 0;
}

.panel-heading {
  background: #222;
}

.table-bordered,
.table-bordered td {
  border: solid 1px #202220;
}

blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left: solid 3px #333333;
  padding-left: 30px;
  color: #606060;
}

.rtl blockquote {
  border-left: none;
  border-right: solid 3px #333333;
  padding-left: 0px;
  padding-right: 30px;
}

.dark-scheme blockquote {
  color: #ffffff;
}

blockquote span {
  font-family: var(--body-font);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
}

blockquote.s2 {
  border: none;
  text-align: left;
  background: #f2f2f2;
  padding: 30px 30px 30px 60px;
  margin-top: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

blockquote.s2:before {
  font-family: 'FontAwesome';
  content: '\f10e';
  font-style: normal;
  position: absolute;
  margin-left: -30px;
}

blockquote.s1 {
  padding: 0px;
}

blockquote.s1:before {
  font-family: 'FontAwesome';
  content: '\f10d';
  display: block;
  color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  font-size: 40px;
  top: 0;
  left: 0;
}

.collapsible-link::before {
  content: '';
  width: 14px;
  height: 2px;
  background: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
  content: '';
  width: 2px;
  height: 14px;
  background: #333;
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
}

.text-light .collapsible-link:before,
.text-light .collapsible-link:after {
  background: #ffffff;
}

.rtl .collapsible-link::before {
  right: auto;
  left: 0px;
}

.rtl .collapsible-link::after {
  right: auto;
  left: 6px;
}

.rtl .rtl-show {
  display: block;
}

.rtl .rtl-hide {
  display: none;
}

.collapsible-link[aria-expanded='true']::after {
  transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded='true']::before {
  transform: rotate(180deg);
}

.collapsible-link.text-white {
  color: #ffffff;
}

.collapsible-link.text-white::after,
.collapsible-link.text-white::before {
  background: #ffffff;
}

.card h6 {
  font-weight: 500;
}

.card-header.text-light h6 {
  color: #ffffff;
}

.accordion-style-1 {
  overflow: visible;
}

.accordion-style-1 .card {
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}

.phone-num-big i {
  font-size: 48px;
  display: block;
}

.pnb-text {
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.pnb-num {
  display: block;
  font-size: 36px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.row.display-table {
  display: table;
}

.row.display-table [class*='col-'] {
  float: none;
  display: table-cell;
}

.row.display-table .th {
  text-align: center;
}

/* ======================================== */

/* toggle */

/* ======================================== */

.toggle-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.toggle-list li {
  margin-bottom: 10px;
  background: #fff;
  border: solid 1px #ddd;
  border-bottom: solid 3px #ddd;
}

.toggle-list li h2 {
  -moz-user-select: none;
  -html-user-select: none;
  user-select: none;
  color: #555;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Inter', Arial, Helvetica, sans-serifr;
  padding: 10px 20px 0px 60px;
  font-weight: 700;
  display: block;
  letter-spacing: normal;
}

.toggle-list li .acc_active:hover {
  color: #777;
  /* background: url(../images/icon-slide-plus.png) 20px center no-repeat; */
  padding-left: 80px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.toggle-list li .acc_noactive:hover {
  color: #777;
  /* background: url(../images/icon-slide-min.png) 20px center no-repeat; */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.toggle-list .ac-content {
  display: none;
  padding: 20px;
  border-top: solid 1px #e5e5e5;
}

.acc_active {
  /* background: url(../images/icon-slide-plus.png) 20px center no-repeat; */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 32px;
}

.acc_noactive {
  /* background: url(../images/icon-slide-min.png) 20px center no-repeat; */
}

/* ================================================== */

/* image position */

/* ================================================== */

.img-left {
  float: left;
  margin: 5px 15px 15px 0;
}

.img-right {
  float: right;
  margin: 5px 0 15px 15px;
}

/* ================================================== */

/* flickr */

/* ================================================== */

#flickr-photo-stream img {
  width: 48px;
  height: auto;
  margin: 0 12px 12px 0;
  float: left;
}

#flickr-photo-stream a img {
  border: solid 2px #fff;
}

#flickr-photo-stream a:hover img {
  border: solid 2px #777777;
}

hr {
  border-top: solid 1px #555;
}

hr.blank {
  border: none;
  margin: 20px 0 20px 0;
}

/* ================================================== */

/* font awesome icons */

/* ================================================== */

i {
  display: inline-block;
}

i.large {
  font-size: 72px;
  text-align: center;
  display: inline-block;
  float: none;
}

i.medium {
  font-size: 20px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  float: none;
}

i.small {
  font-size: 12px;
  padding: 4px;
  text-align: center;
  display: inline-block;
  float: none;
}

i.circle-icon {
  border-radius: 75px;
  -moz-border-radius: 75px;
  -webkit-border-radius: 75px;
}

.fa-android {
  color: #469159;
}

.fa-apple {
  color: #0ec3f7;
}

/* extra ^ */

.de_center p {
  width: 100%;
}

.de_center .de_icon {
  float: none;
  margin: 0 auto;
}

/* ================================================== */

/* transition */

/* ================================================== */

a,
a:hover,
.test-column,
.test-column:hover {
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.de_contact_info {
  font-size: 40px;
  font-weight: bold;
}

.de_contact_info i {
  float: none;
  margin-right: 20px;
}

.center-div {
  margin: 0 auto;
}

/* ================================================== */

/* gallery */

/* ================================================== */

#gallery-carousel .item {
  text-align: center;
  font-size: 12px;
  width: 100%;
  cursor: e-resize;
}

.de_carousel .item {
  width: 100%;
  display: block;
}

#gallery-carousel .item img {
  width: 100%;
  height: auto;
}

#gallery-carousel .item h4 {
  margin-bottom: 0;
  font-size: 14px;
}

.carousel-item {
  width: 100%;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.overlay-v {
  text-align: center;
  width: 100px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overlay-v i {
  background: #333;
  padding: 10px;
  width: 48px;
  height: 48px;
  font-weight: normal;
  font-size: 32px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  margin-top: 100%;
  color: #222;
  opacity: 0;
}

.overlay-v:hover i {
  margin-top: 25%;
  opacity: 1;
}

.overlay-v span {
  background: #1a1c26;
  padding: 40px;
  width: 100%;
  height: 100%;
  font-weight: normal;
  margin-top: 100%;
  color: #222;
  opacity: 0;
  position: absolute;
  left: 0;
}

.overlay-v:hover span {
  margin-top: 0px;
  opacity: 0.9;
}

.carousel-item .pf_text .title {
  display: block;
  position: absolute;
  width: 100%;
  top: 80%;
  font-size: 20px;
  font-weight: 700;
  height: 0;
  color: rgba(255, 255, 255, 0);
  letter-spacing: 0;
}

.overlay-v:hover {
  background: rgba(30, 30, 30, 0.5);
}

.carousel-item:hover .pf_text .title {
  color: rgba(255, 255, 255, 1);
  top: 75%;
}

.carousel-item .pf_text .title .type {
  display: block;
  color: rgba(255, 255, 255, 0);
  font-size: 12px;
  font-weight: 400;
}

.carousel-item:hover .pf_text .title .type {
  color: rgba(255, 255, 255, 1);
}

.pf_text {
  color: #eceff3;
  letter-spacing: 5px;
}

.pf_text h4 {
  color: #eceff3;
  margin-bottom: 0;
  font-size: 14px;
}

.project-info {
  color: #ccc;
  margin-left: 20px;
}

.de_light .project-info {
  color: #606060;
}

.project-info h2 {
  margin-top: 0;
  font-size: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.project-info h4 {
  font-weight: bold;
}

.project-info .details {
  padding: 20px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.de_light .project-info .details {
  background: #eee;
  border-color: #ddd;
}

.project-infoproject-info span {
  display: block;
}

.project-info span.title {
  display: inline-block;
  min-width: 80px;
}

.project-info span.val {
  margin-bottom: 15px;
  font-weight: bold;
}

.project-view {
  padding: 120px 0 80px 0;
}

.project-images img {
  width: 100%;
}

.picframe {
  position: relative;
  overflow: hidden;
}

.picframe img {
  width: 100%;
}

#d-btn-close-x {
  width: 100px;
  height: 100px;
  display: block;
  /* background: url(../images/close.png) center no-repeat; */
  margin: 0 auto;
  margin-bottom: 80px;
  cursor: poInter;
  padding: 20px;
}

#d-btn-close-x {
  /* background: url(../images/close-2.png) center no-repeat; */
}

.project-name {
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.info-details .info-text {
  margin-bottom: 20px;
}

.info-details .title {
  display: block;
  font-weight: bold;
}

span.overlay {
  font-family: var(--body-font);
  text-align: center;
  display: block;
  width: 100px;
  background: rgba(var(--primary-color-rgb), 0.8);
  position: absolute;
  opacity: 0.75;
  z-index: 500;
}

span.icon {
  cursor: poInter;
}

.f_box {
  display: block;
  position: relative;
  float: left;
  font-size: 16px;
  overflow: hidden;
  z-index: 1;
}

.f_box:hover .f_bg {
  opacity: 0.3;
}

.grid-item .f_box img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.8;
}

.f_box:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  transform: scale(1.2);
  opacity: 1;
}

.f_box .f_bg {
  background: #111111;
  display: block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.f_box .f_bg.s1 {
  opacity: 0;
}

.f_box:hover .f_bg.s1 {
  opacity: 0.2;
}

.f_box .f_cap_wrap {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
  color: #fff;
  z-index: 2;
  text-align: center;
  font-weight: bold;
}

.f_box .f_cap_wrap .f_cap {
  vertical-align: middle;
  display: table-cell;
}

.f_box:hover .f_cap {
  transform: scale(1.05);
}

.f_box .f_text {
  height: 0;
  overflow: hidden;
  font-family: Helvetica, Arial;
  font-weight: normal;
  display: inline-block;
  width: 70%;
  font-size: 14px;
}

.f_box:hover .f_text {
  height: auto;
}

.f_box img {
  width: 100%;
}

.f_box.s2:hover .f_bg {
  opacity: 0.4;
}

.f_box.s2 .f_bg {
  opacity: 0;
}

.f_box.s2:hover img {
  transform: scale(1.2);
}

.f_box.s2 {
  position: static;
}

.f_box.s2 .f_cap_wrap {
  opacity: 0;
}

.f_box.s2:hover .f_cap_wrap {
  opacity: 1;
  margin-top: 0;
}

.f_box .f_cap h1,
.f_box .f_cap h4 {
  color: #ffffff;
}

.f_box.s2 .f_cap {
  font-size: 20px;
  transform: scale(1.2);
}

.f_box.s2:hover .f_cap {
  transform: scale(1);
}

h2 {
  display: inline-block;
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
  letter-spacing: -1px;
}

h2 span.underline {
  display: block;
  width: 0;
  height: 2px;
  background: #ffffff;
  position: relative;
  left: 0;
}

.f_box:hover h2 span.underline {
  width: 100%;
}

.overlay .info-area {
  display: block;
  margin-top: 10%;
}

.overlay .sb-icons {
  display: block;
  margin-top: 200px;
}

.de-gallery .overlay {
  text-align: center;
}

.de-gallery .overlay .pf_text {
  display: inline-block;
  opacity: 0;
}

.de-gallery .project-name {
  font-size: 16px;
  letter-spacing: 0;
}

.de-gallery .overlay .icon {
  display: inline-block;
  margin-top: 200px;
  text-align: center;
}

.de-gallery .overlay .icon i {
  text-shadow: none;
  color: #fff;
  border: solid 1px rgba(255, 255, 255, 0.1);
  font-size: 22px;
  padding: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  margin-left: 10px;
}

.de-gallery .overlay .icon i.icon-info {
  margin-left: 0px;
}

.de-gallery .overlay .icon i:hover {
  color: #222;
  background: #fff;
}

.grid_gallery .item {
  margin-bottom: 30px;
}

a.img-icon-url,
a.img-icon-zoom {
  display: inline-block;
  width: 36px;
  height: 36px;
  /* background: url(../images/icon-url.png) center no-repeat; */
  margin: 0 auto;
  margin: 0 2px 0 2px;
}

a.img-icon-zoom {
  /* background: url(../images/icon-zoom.png) center no-repeat; */
}

.pf_full_width {
  width: 100%;
}

.pf_full_width .item {
  float: left;
  width: 24.99%;
}

.pf_4_cols {
  width: 101%;
}

.pf_full_width.pf_3_cols .item {
  width: 33.3%;
}

.pf_full_width.pf_2_cols .item {
  width: 49.9%;
}

.pf_full_width.gallery_border .item,
.pf_full_width.gallery_border.pf_2_cols .item,
.pf_full_width.gallery_border.pf_3_cols .item {
  float: left;
  width: 23.9%;
  margin-right: 1%;
  margin-bottom: 1%;
}

.pf_full_width.gallery_border.pf_3_cols .item {
  width: 32.3%;
}

.pf_full_width.gallery_border.pf_2_cols .item {
  width: 48.9%;
}

.pf_full_width img {
  float: left;
  width: 100%;
}

.pf_full_width.grid {
  padding: 0.75%;
  padding-bottom: 0;
}

.pf_full_width.grid .item {
  margin: 0 0.75% 0.75% 0;
  width: 32%;
}

.pf_full_width.grid.pf_4_cols .item {
  margin: 0 0.75% 0.75% 0;
  width: 23.9%;
}

.pf_full_width.grid.pf_2_cols .item {
  margin: 0 0.75% 0.75% 0;
  width: 48.5%;
}

.gallery-cols .item {
  margin-bottom: 30px;
}

.pic-grey {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* ---- grid ---- */

.grid {
  width: 100%;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .element-item ---- */

/* 5 columns, percentage width */

.grid-sizer {
  width: 5%;
}

.grid-item {
  position: relative;
  float: left;
  border: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* ================================================== */

/* widget */

/* ================================================== */

#sidebar {
  padding-left: 30px;
}

#sidebar.post-left {
  padding-left: 0px;
  padding-right: 30px;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.widget h4 {
  font-size: 18px;
  margin-top: 0;
  letter-spacing: normal;
}

.widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget ul li {
  margin: 5px 0;
}

.widget .small-border {
  height: 2px;
  width: 30px;
  background: #fff;
  margin: 0px 0 20px 0;
  display: block;
}

.widget_category li {
  padding: 8px 20px 8px 20px;
  margin-bottom: 8px;
  background: #111;
}

.widget_category li:before {
  font-family: 'FontAwesome';
  content: '\f07b';
  margin-right: 10px;
}

.de_light .widget_category li {
  background: none;
  border: solid 1px #eee;
}

.widget_category li a {
  color: #606060;
}

.widget_recent_post li {
  padding-left: 20px;
  /* background: url(../images/list-arrow.png) left 2px no-repeat; */
  border-bottom: solid 1px #222;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.widget_recent_post li a {
  color: #555;
}

.widget-post li {
  border-bottom: solid 1px #f2f2f2;
  padding-top: 4px;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.rtl .widget-post li a {
  padding-left: 0px;
  padding-right: 80px;
}

.widget-post li a {
  padding-left: 70px;
  display: block;
  text-decoration: none;
  color: #606060;
}

.dark-scheme .widget-post li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-scheme .widget-post li a {
  color: #ffffff;
}

.widget-post .post-date {
  position: absolute;
  color: #222;
  margin-right: 10px;
  text-align: center;
  width: 60px;
  font-size: 10px;
  text-transform: uppercase;
}

.widget-post li:hover > .post-date {
  background: #222;
  color: #fff;
}

.widget-post .date {
  background: #333;
  color: #fff;
  display: inline-block;
  padding: 0px 5px 0px 5px;
  font-size: 12px;
  width: 60px;
  font-weight: bold;
  text-align: center;
  position: absolute;
}

.rtl .widget-post .date {
  float: right;
}

.widget .comments li {
  padding-left: 20px;
  /* background: url(../images/list-arrow.png) left 2px no-repeat; */
  border-bottom: solid 1px #eee;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.widget .tiny-border {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.footer-light .widget .tiny-border {
  background: rgba(0, 0, 0, 0.1);
}

.widget_tags li {
  text-shadow: none;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 16px;
}

.widget_tags li a {
  font-size: 12px;
  text-decoration: none;
  margin-bottom: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #fff !important;
  padding: 7px 10px 7px 10px;
}

.de_light .widget_tags li a {
  color: #606060;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.widget_tags li a:hover {
  color: #555;
  border-color: #555;
}

.de_light .widget_tags li a:hover {
  color: #111;
  border-color: #111;
}

.widget_top_rated_product ul {
  padding: 0;
}

.widget_top_rated_product li {
  min-height: 80px;
}

.widget_top_rated_product li .text {
  padding-top: 5px;
  padding-left: 75px;
}

.widget_top_rated_product li img {
  position: absolute;
  width: 60px;
  height: 60px;
}

.widget_tags li a {
  border-color: #555;
}

footer .widget {
  border: none;
  margin-bottom: 0;
}

.widget_tags ul {
  margin-top: 30px;
}

footer .widget.widget_tags li a {
  border-color: #222;
}

footer .widget_recent_post li {
  padding: 0 0 10px 0;
  margin: 0;
  margin-bottom: 10px;
  background: none;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

footer .widget_recent_post li a {
  color: #ccc;
}

footer .widget h5 {
  margin-bottom: 20px;
}

footer .widget_list li {
  padding: 0 0 10px 0;
  margin: 0;
  margin-bottom: 10px;
  background: none;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

footer .widget_list li a {
  color: #ccc;
}

footer .tiny-border span {
  background: #ffffff;
  display: block;
  height: 1px;
  width: 30px;
}

.side-layout header {
  width: 20%;
  height: 100%;
  background: #222;
  position: fixed;
  padding: 30px;
  top: 0;
}

.side-layout header.header-light {
  background: #fff;
  border-right: solid 1px #eee;
}

.side-layout #content {
  width: 80%;
  float: right;
}

.side-layout footer {
  width: 80%;
  float: right;
}

.side-layout .container {
  width: 90%;
}

.side-layout section {
  width: 100%;
  float: right;
}

.side-layout header .info {
  display: none;
}

.side-layout #mainmenu {
  padding: 0;
  margin-top: 40px;
}

.side-layout #mainmenu > li {
  display: block;
  width: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.side-layout header.smaller #mainmenu > li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.side-layout header.smaller {
  border-right: solid 1px #f6f6f6;
}

.side-layout header.header-dark.smaller {
  border-right: none;
}

.side-layout #mainmenu > li:last-child {
  border-bottom: none;
}

.side-layout #mainmenu > li > a {
  padding: 0;
  margin: 0;
}

.side-layout #mainmenu > li > a span {
  display: none;
}

.side-layout #mainmenu > li > ul {
  top: 0;
  margin-left: 120%;
}

.side-layout #mainmenu li ul {
  border: solid 1px #888;
}

.side-layout header div#logo {
  display: block;
  float: none;
  text-align: center;
}

.side-layout header div#logo img {
  width: 100%;
}

.side-layout #subheader {
  width: 80%;
  float: right;
  text-align: left;
}

.side-layout #subheader h1 {
  margin-top: 0;
  font-size: 24px;
}

.side-layout #subheader .small-border-deco span {
  margin: 0;
}

.side-layout.side-custom-bg header {
  /* background: url(../images/background/bg-header.jpg) top left; */
  background-size: cover;
}

.bg-color-secondary h1,
.bg-color-secondary h2,
.bg-color-secondary h3,
.bg-color-secondary h4,
.bg-color-secondary h5,
.bg-color-secondary h6,
.p-title.invert,
.p-tagline,
i.bg-color-secondary {
  color: #ffffff;
}

.audio-control {
  width: 100%;
}

/* mdb carousel */

/* Carousel styling */
#de-carousel,
#de-carousel .carousel-inner,
#de-carousel .carousel-item,
#de-carousel .carousel-item.active {
  height: 100vh;
}

#de-carousel .carousel-control-prev-icon:after {
  content: '\f104';
  font-family: 'FontAwesome';
}

#de-carousel .carousel-control-next-icon:after {
  content: '\f105';
  font-family: 'FontAwesome';
}

#de-carousel .carousel-indicators li {
  height: 8px;
  margin: 0 6px;
  display: block;
}

#de-carousel .mask {
  background: rgba(30, 30, 30, 0.5);
}

#de-carousel .carousel-control-next,
#de-carousel .carousel-control-prev {
  width: 100px;
}

#de-carousel h1 {
  font-size: 60px;
}

/* mdb carousel */

/* ================================================== */

/* misc */

/* ================================================== */

.d-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: rgba(0, 0, 0, 0.025);
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.025) 0%,
    rgba(0, 0, 0, 0.05) 20%,
    rgba(0, 0, 0, 0.025) 40%,
    rgba(0, 0, 0, 0.025) 100%
  );
  background-size: 500px 500px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.dark-scheme .d-placeholder {
  background: rgba(255, 255, 255, 0.05);
  background-image: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 0.05) 40%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-size: 500px 500px;
}

@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

/* box-url */

.box-url {
  position: relative;
  padding: 30px;
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.2);
  display: block;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
}

.dark-scheme .box-url {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
}

.box-url p {
  color: #727272;
  margin-bottom: 0;
}

.dark-scheme .box-url p {
  color: var(--dark-scheme-font-color);
  margin-bottom: 0;
}

.box-url:hover {
  background: rgba(0, 0, 0, 0.05);
}

.box-url-label {
  font-weight: bold;
  position: absolute;
  right: 30px;
  color: #0d0c22;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.rtl .box-url-label {
  right: auto;
  left: 30px;
}

.dark-scheme .box-url-label {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.box-url.style-2 {
  text-align: center;
}

.box-url.style-2 h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.box-url.style-2.alt-1 {
  box-shadow: none;
}

.soft-shadow {
  box-shadow:
    0 0.25rem 0.5rem rgb(0 0 0 / 5%),
    0 1.5rem 2.2rem rgb(0 0 0 / 10%);
}

.tooltip-inner {
  background: #ffffff;
  color: #0d0c22;
  font-weight: 500;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #ffffff;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #ffffff;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #ffffff;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #ffffff;
}

.tooltip-inner,
.tooltip.bs-tooltip-top .tooltip-arrow::before,
.tooltip.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip.bs-tooltip-start .tooltip-arrow::before,
.tooltip.bs-tooltip-end .tooltip-arrow::before {
  box-shadow:
    0 0.25rem 0.5rem rgb(0 0 0 / 5%),
    0 1.5rem 2.2rem rgb(0 0 0 / 10%);
}

#item-carousel-big {
  padding: 0 30px;
}

#item-carousel-big.no-padding {
  padding: 0;
}

.de-flex {
  display: flex;
  justify-content: space-between;
}

.de-flex > .de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadmore {
  display: inline-block;
  margin-top: 10px;
}

/* .d-item {
    display: none;
} */

.v-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.br-10 {
  overflow: hidden;
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
}

.br-15 {
  overflow: hidden;
  border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
}

.f-logo {
  margin-right: 30px;
}

.rtl .f-logo {
  margin-right: 0;
  margin-left: 30px;
}

.text-light .card {
  color: #ffffff !important;
  background: rgba(255, 255, 255, 0.05);
}

.filter__l,
.filter__r {
  font-family: var(--title-font);
}

.filter__l {
  font-weight: bold;
}

.filter__r {
  float: right;
  cursor: pointer;
}

.de-modal .btn-close {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 100;
}

.dark-scheme .de-modal .btn-close {
  background: url(../images/ui/btn-close-light.png) center no-repeat;
}

.de-modal b {
  color: #0d0c22;
}

.dark-scheme .de-modal b {
  color: #ffffff;
}

/*Cookie Consent Begin*/
#cookieConsent {
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 26px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  padding: 10px 30px 10px 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999;
}

#cookieConsent a {
  text-decoration: none;
}

#closeCookieConsent {
  display: inline-block;
  cursor: poInter;
  height: 20px;
  width: 20px;
  margin: -15px 0 0 0;
  font-weight: bold;
}

#closeCookieConsent:hover {
  color: #fff;
}

#cookieConsent a.cookieConsentOK {
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 0 20px;
  cursor: poInter;
}

#cookieConsent a.cookieConsentOK:hover {
  opacity: 0.8;
}

/*Cookie Consent End*/

#cookit {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0.6rem 2rem;
  display: flex;
  z-index: 1000;
}

#cookit.hidden {
  display: none;
}

#cookit #cookit-container {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 30px;
}

#cookit #cookit-container > * {
  margin: 0.4rem;
}

#cookit #cookit-container p {
  line-height: 1.4rem;
}

#cookit #cookit-container a {
  text-decoration: none;
}

#cookit #cookit-container a:hover {
  opacity: 0.9;
}

#cookit #cookit-container #cookit-button {
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin-left: auto;
}

.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

#purchase-now {
  position: fixed;
  bottom: 80px;
  left: 20px;
  z-index: 10000;
}

#purchase-now a {
  color: #ffffff;
  z-index: 1;
  position: absolute;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  background: #78a938;
  display: block;
  font-size: 24px;
  font-weight: bold;
  width: 60px;
  height: 60px;
  padding: 17px;
  text-decoration: none;
  animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(120, 169, 56, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(120, 169, 56, 0);
  }
}

#purchase-now a span {
  font-size: 14px;
  font-weight: 500;
  left: 8px;
  position: absolute;
  top: 18px;
  width: 100px;
}

#purchase-now .pn-hover {
  position: absolute;
  font-weight: bold;
  font-size: 16px;
  background: #ffffff;
  color: #78a938;
  width: 120px;
  padding: 10px;
  padding-left: 20px;
  text-align: center;
  top: 8px;
  border-radius: 5px;
  left: 30px;
  opacity: 0;
}

#purchase-now:hover .pn-hover {
  opacity: 1;
  left: 40px;
}

.de-images {
  position: relative;
  width: 100%;
}

.de-images img {
  border-radius: 3px;
  -webkit-box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
  -moz-box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
  box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
}

.di-small {
  position: absolute;
  width: 40%;
  z-index: 1;
}

.di-small-2 {
  position: absolute;
  width: 40%;
  right: -0;
  bottom: -15%;
  z-index: 1;
}

.di-big {
  width: 80%;
  height: 80%;
  margin-top: 15%;
  margin-left: 10%;
}

div.round,
.rounded {
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
}

.opt-1 {
  display: inline-block;
}

.size96 {
  width: 96px;
  height: auto;
}

.domain-ext {
  margin-top: 15px;
}

.ext {
  border: solid 1px rgba(255, 255, 255, 0.2);
  display: inline-block;
  padding: 8px 10px 0px 10px;
  font-size: 12px;
  border-radius: 3px;
  margin: 0 5px 10px 5px;
}

.ext h4 {
  font-size: 18px;
  line-height: 10px;
  margin-bottom: 0;
}

.border {
  border-color: #efefef !important;
}

.tparrows {
  background: none !important;
}

.fc-header-toolbar {
  font-size: 14px;
}

.fc-content {
  color: #ffffff;
  padding: 3px;
}

.fc-view {
  background: #ffffff;
}

.fc-day-number {
  padding: 4px 8px !important;
}

.f-profile {
  margin-bottom: 0px;
}

.f-profile h4 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.fp-wrap {
  position: relative;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}

.fpw-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.fpw-overlay-btm {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.f-invert .fpw-overlay-btm {
  opacity: 0;
}

.fpwo-wrap {
  width: 100%;
  position: absolute;
  bottom: 30px;
  text-align: center;
}

.fpwow-icons {
  display: inline-block;
  background: #ffffff;
  padding: 5px 10px 7px 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.fpwow-icons a {
  color: #ffffff;
  padding: 0 5px;
}

.fpwow-icons i {
  font-size: 15px;
}

.fp-icon {
  margin-top: 20%;
  z-index: 2;
  opacity: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  display: table;
  text-align: center;
}

.fp-icon i {
  display: none;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
  font-size: 36px;
  color: #ffffff;
  padding: 13px 0;
  background: rgba(var(--primary-color-rgb), 0.8);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gray img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.demo-icon-wrap {
  margin-bottom: 30px;
}

.demo-icon-wrap i {
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  position: relative;
  background: rgba(var(--secondary-color-rgb), 0.1);
  width: 48px;
  height: 48px;
  border-radius: 5px;
  padding: 15px 0;
}

.rtl .demo-icon-wrap i {
  margin-right: 0;
  margin-left: 20px;
}

.demo-icon-wrap-s2 {
  margin-bottom: 30px;
}

.demo-icon-wrap-s2 span {
  display: inline-block;
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  position: relative;
  background: rgba(var(--secondary-color-rgb), 0.1);
  width: 48px;
  height: 48px;
  border-radius: 5px;
  padding: 15px 0;
}

.rtl .demo-icon-wrap-s2 span {
  margin-right: 0px;
  margin-left: 20px;
}

.demo-icon-wrap .text-grey {
  display: none;
}

.fp-wrap:hover {
  -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
  box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
}

.fp-wrap:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);

  transform: scale(1.05);
}

.fp-wrap:hover .fpw-overlay-btm {
  opacity: 0;
}

.fp-wrap:hover .fpwow-icons a i {
  color: #fff;
}

.fp-wrap:hover .fp-icon {
  opacity: 1;
  margin-top: 0;
  margin-right: 0;
}

.fp-wrap:hover .fp-icon i {
  transform: scale(1.5);
}

i.big {
  display: inline-block;
  font-size: 48px;
}

.text-light .border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.container-full {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}

.one-fourth {
  width: 24.9%;
  display: table-cell;
  vertical-align: middle;
}

.one-third {
  width: 33.3%;
  float: left;
}

.three-fourth {
  position: relative;
  width: 74.9%;
  height: 100%;
  display: table-cell;
}

.owl-carousel {
  position: relative;
  margin-top: -5px;
  bottom: -5px;
  margin-bottom: -15px;
}

.owl-stage-outer .feature-box-type-2 {
  overflow: none;
  margin: 30px 0 30px 0;
}

.owl-item.active.center .feature-box-type-2 {
  transform: scale(1.05);
}

.owl-item.active.center .feature-box-type-2,
.owl-item.active.center .feature-box-type-2 h4,
.owl-item.active.center .feature-box-type-2 i {
  color: #ffffff;
}

.owl-carousel.owl-center .owl-stage-outer {
  padding: 3% 0;
}

.owl-carousel.owl-center .owl-item.active.center {
  position: relative;
  z-index: 101;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0calc ());
  -moz-box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0calc ());
  box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0calc ());
}

.owl-carousel.owl-center .owl-item .nft_pic_title,
.owl-carousel.owl-center .owl-item .nft_pic_by {
  opacity: 0;
}

.owl-carousel.owl-center .owl-item .mod-b .nft_pic_title,
.owl-carousel.owl-center .owl-item .mod-b .nft_pic_by {
  opacity: 1;
}

.owl-carousel.owl-center .owl-item.active.center .nft_pic_title,
.owl-carousel.owl-center .owl-item.active.center .nft_pic_by {
  opacity: 1;
}

.rtl .owl-carousel,
.rtl .bx-wrapper {
  direction: ltr;
}

.rtl .owl-carousel .owl-item {
  direction: rtl;
}

.schedule-item {
  padding: 40px 0 30px 0;
  border-bottom: solid 1px #ddd;
}

.schedule-listing:nth-child(even) {
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
}

.schedule-item > div {
  float: left;
}

.schedule-item .sc-time {
  width: 160px;
  padding-top: 25px;
  color: #333;
}

.schedule-item .sc-pic {
  width: 120px;
}

.schedule-item .sc-pic img {
  width: 80px;
  height: auto;
}

.schedule-item .sc-name {
  font-weight: normal;
  font-size: 14px;
  width: 160px;
  padding-top: 15px;
}

.schedule-item .sc-name h4 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  line-height: 1.6em;
}

.schedule-item .sc-name span {
  font-weight: normal;
  font-size: 12px;
}

.schedule-item .sc-info {
  float: none;
  display: inline-block;
  width: 60%;
}

.schedule-item .sc-info h4 {
  margin-bottom: 10px;
}

.de_tab.tab_style_4 .de_nav {
  -webkit-touch-1a1c26out: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

strong {
  font-weight: bold;
}

.text-light strong {
  color: #fff;
}

.bg-white {
  background: #ffffff;
}

.bg-white-10 {
  background: rgba(255, 255, 255, 0.1);
}

.wh80 {
  width: 80%;
  height: 80%;
}

.border-top {
  border-top: solid 1px #e5e5e5;
}

.border-bottom {
  border-bottom: solid 1px #e5e5e5;
}

.dark-scheme header.border-bottom {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
}

.color-overlay {
  background: rgba(157, 130, 235, 0.3);
}

section .color-overlay {
  padding: 60px 0 60px 0;
}

.color-overlay-2 {
  background: rgba(26, 37, 48, 0.85);
}

.fontsize24 {
  font-size: 24px;
}

.fontsize32 {
  font-size: 32px;
}

.fontsize36 {
  font-size: 36px;
}

.fontsize40 {
  font-size: 40px;
}

.fontsize48 {
  font-size: 48px;
}

.overlay50 {
  background: rgba(0, 0, 0, 0.5);
}

.overlay60 {
  background: rgba(0, 0, 0, 0.6);
}

.overlay70 {
  background: rgba(0, 0, 0, 0.7);
}

.overlay80 {
  background: rgba(0, 0, 0, 0.8);
}

.hidden-xy {
  overflow: hidden;
}

.border-box {
  border: solid 1px #dddddd;
}

.dark-scheme .border-box {
  border: none;
}

.display-table {
  display: table;
}

.display-table [class*='col-'] {
  float: none;
  display: table-cell;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

#popup-box {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: -100%;
  overflow-y: auto;
}

#popup-box.popup-show {
  top: 0;
}

#popup-box.popup-hide {
  top: -100%;
}

.overlay-s1 {
  background: rgba(217, 42, 224, 0.5);
}

.overlay-s2 {
  background: rgba(255, 135, 140, 0.5);
}

.overlay-s3 {
  background: rgba(91, 35, 108, 0.5);
}

.circle-x {
  background: #333;
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  color: #fff;
  margin-top: 140px;
  width: 70px;
  height: 70px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
}

#services-list {
  padding: 0;
  margin: 0;
}

#services-list li a {
  color: #fff;
}

#services-list li.active a:after {
  font-family: 'FontAwesome';
  content: '\f054';
  float: right;
}

.de_light #services-list li a {
  color: #999;
}

#services-list li {
  font-weight: bold;
  list-style: none;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 2px;
}

.de_light #services-list li {
  background: #eee;
}

#services-list li a {
  display: block;
  padding: 15px 20px 15px 20px;
  text-decoration: none;
}

#services-list li a:hover {
  color: #111;
}

.pic-services img {
  margin-bottom: 30px;
}

#services-list li.active a,
#services-list li.active a:hover {
  color: #fff;
  background: #333;
}

.project-images img {
  margin-bottom: 60px;
}

.teaser-text {
  font-family: var(--body-font);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
  line-height: normal;
  font-weight: 400;
}

#navigation {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  right: 20px;
  text-align: center;
  margin-bottom: 60px;
  z-index: 1000;
}

.nav-prev:before,
.nav-next:before,
.nav-exit:before {
  font-family: 'FontAwesome';
  content: '\f177';
  color: #fff;
  padding: 20px;
  font-size: 24px;
  cursor: poInter;
}

.nav-exit:before {
  width: 100%;
  display: block;
}

.nav-next:before {
  content: '\f178';
  width: 100%;
  background: #555;
}

.nav-exit:before {
  content: '\f00d';
}

.de_light .nav-exit:before {
  color: #222;
}

.container-4 .de-team-list {
  width: 24.9%;
  float: left;
  position: relative;
}

.container-3 .de-team-list {
  width: 33.3%;
  float: left;
  position: relative;
}

.no-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

p {
  margin-bottom: 20px;
}

p.intro {
  color: #555;
  font-size: 16px;
  line-height: 1.5em;
}

.bg-grey {
  background: #f6f6f6;
}

.no-bottom {
  padding-bottom: 0 !important;
}

.no-top {
  padding-top: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.nopadding {
  padding: 0;
  margin: 0;
}

#filters i {
  margin-right: 10px;
}

.bg-side {
  position: relative;
  overflow: hidden;
}

.image-container {
  position: absolute;
  padding: 0;
  margin: 0;
  background-size: cover;
}

.image-container.pos-right {
  right: 0;
}

.rtl .image-container.pos-right {
  right: auto;
  left: 0;
}

.image-slider {
  cursor: move;
}

.inner-padding {
  padding: 90px;
}

.inner-padding.pos-left {
  padding: 90px;
  padding-left: 0;
}

.rtl .inner-padding.pos-left {
  padding-left: 90px;
  padding-right: 0;
}

.no-padding,
section.no-padding {
  padding: 0;
}

.float-left {
  float: left;
}

#loader-area {
  display: none;
  background: #fff;
}

#loader-area {
  display: none;
  background: #111;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  /* background: url('../images/page-loader.gif') 50% 50% no-repeat rgb(249, 249, 249); */
}

.page-overlay {
  display: none;
  width: 120px;
  height: 120px;
  /* background: url(../images/page-loader.gif) center no-repeat #fff; */
  border-radius: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  margin: auto;
}

.teaser {
  font-size: 16px;
  line-height: 1.8em;
  font-weight: 400;
}

.teaser.style-2 {
  letter-spacing: 20px;
  text-transform: uppercase;
}

.teaser.style-3 {
  font-size: 14px;
}

.teaser.style-4 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.d-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.d-custom-nav {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  z-index: 100;
}

.d-arrow-left,
.d-arrow-right {
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: -10px;
  z-index: 100;
  width: 30px;
  height: 60px;
  background-color: rgba(255, 255, 255, 1);
}

.d-arrow-left {
  left: 25px;
  border-top-right-radius: 110px;
  border-bottom-right-radius: 110px;
}

.d-arrow-right {
  right: 25px;
  border-top-left-radius: 110px;
  border-bottom-left-radius: 110px;
}

.d-arrow-left i,
.d-arrow-right i {
  position: absolute;
  top: 20px;
  color: #111111;
  font-size: 20px;
  font-weight: bold;
}

.d-arrow-left i {
  left: 8px;
}

.d-arrow-right i {
  right: 8px;
}

.d-arrow-left.s2 {
  left: 0;
}

.d-arrow-right.s2 {
  right: 0;
}

.d-arrow-left.mod-a {
  left: 0;
}

.d-arrow-right.mod-a {
  right: 0;
}

#d-coll-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.owl-nav {
  display: flex;
}

.owl-prev,
.owl-next,
.d-nav-left,
.d-nav-right {
  cursor: pointer;
  position: absolute;
  top: 37.5%;
  z-index: 100;
  width: 45px;
  height: 45px;
  background-color: rgba(255, 255, 255, 1);
  border: solid 1px #ccc;
}

#items-carousel-big .owl-prev,
#items-carousel-big .owl-next {
  opacity: 0;
  top: 48%;
}

#items-carousel-big:hover .owl-prev,
#items-carousel-big:hover .owl-next {
  opacity: 1;
}

.dark-scheme .owl-prev,
.dark-scheme .owl-next {
  background: var(--dark-scheme-color);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

.owl-prev:hover,
.owl-next:hover {
  transform: scale(1.1);
  -webkit-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
}

.owl-prev,
.d-nav-left {
  left: -12px;
  border-radius: 60px;
  padding-left: 4px;
}

.owl-next,
.d-nav-right {
  right: -12px;
  border-radius: 60px;
  padding-right: 4px;
}

.d-nav-left i,
.d-nav-right i {
  position: absolute;
  top: 20px;
  color: #111111;
  font-size: 20px;
  font-weight: bold;
}

.owl-next i,
.d-nav-left i {
  top: 12px;
  left: 16px;
}

.d-nav-right i {
  top: 12px;
  right: 16px;
}

.owl-arrow {
  z-index: 100;
  background: #fff;
  margin-top: 48%;
  display: none;
  cursor: poInter;
}

.owl-arrow span {
  display: block;
  background: #555;
  position: absolute;
}

.owl-arrow .prev {
  left: 0;
}

.owl-arrow .next {
  right: 0;
}

.owl-arrow .prev:before,
.owl-arrow .next:before {
  font-family: 'FontAwesome';
  content: '\f177';
  color: #222222;
  padding: 20px;
  font-size: 32px;
  display: block;
}

.owl-arrow .next:before {
  content: '\f178';
}

.owl-pagination {
  margin-top: 0px;
  height: 40px;
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  background-color: #555;
  opacity: 1;
}

.owl-theme .owl-controls .owl-page.active span {
  background: none;
}

.owl-theme .owl-controls .owl-page.active span:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  position: relative;
  border: solid 2px #555;
  top: -2px;
  left: -2px;
}

/* new owl */

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}

.owl-dots button {
  border: none;
  outline: none;
  padding: 0;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot span {
  display: none;
}

/* new owl close */

.text-light .owl-theme .owl-controls .owl-page span {
  background-color: #fff;
}

.text-light .owl-theme .owl-controls .owl-page.active span {
  background: none;
}

.text-light .owl-theme .owl-controls .owl-page.active span:before {
  border: solid 2px #fff;
}

.text-slider {
  color: #fff;
  display: inline-block;
}

.text-slider.med-text {
  font-size: 72px;
  line-height: 84px;
  font-weight: bold;
}

.text-slider.big-text {
  font-size: 120px;
  line-height: 120px;
  font-weight: bold;
}

.border-deco .text-item {
  display: inline-block;
}

.text-slider.dark {
  color: #222;
}

.text-item i {
  font-size: 60px;
  margin-top: 5px;
}

.text-slider.big-text i {
  font-size: 90px;
  margin-top: 15px;
}

.text-slider h1 {
  font-size: 60px;
}

.text-rotate-wrap {
  display: inline-block;
}

.text-rotate-wrap .text-item {
  display: none;
}

.red {
  color: #ff0000;
}

.teal {
  color: #009999;
}

.purple {
  color: #663366;
}

.green {
  color: #009900;
}

.bg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.center-y {
  position: relative;
}

.overlay-bg {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(var(--primary-color-rgb), 0.9);
  top: 0;
  padding: 100px 0 100px 0;
}

.overlay-bg.p60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.overlay-gradient {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
}

.overlay-solid {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
}

.overlay-bg.t0 {
  background: rgba(var(--primary-color-rgb), 0);
}

.overlay-bg.t20 {
  background: rgba(var(--primary-color-rgb), 0.2);
}

.overlay-bg.t30 {
  background: rgba(var(--primary-color-rgb), 0.3);
}

.overlay-bg.t40 {
  background: rgba(var(--primary-color-rgb), 0.4);
}

.overlay-bg.t50 {
  background: rgba(var(--primary-color-rgb), 0.5);
}

.overlay-bg.t60 {
  background: rgba(var(--primary-color-rgb), 0.6);
}

.overlay-bg.t70 {
  background: rgba(var(--primary-color-rgb), 0.7);
}

.overlay-bg.t80 {
  background: rgba(var(--primary-color-rgb), 0.8);
}

.overlay-bg.t90 {
  background: rgba(var(--primary-color-rgb), 0.9);
}

.overlay-bg.c1 {
  background: rgba(var(--primary-color-rgb), 0.8);
}

.overlay-bg.dark {
  background: rgba(20, 20, 20, 0.8);
}

.overlay-bg.gradient-blue-aqua {
  background: -moz-linear-gradient(45deg, rgba(2, 68, 129, 0.9) 0%, rgba(52, 176, 188, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(2, 68, 129, 0.9) 0%, rgba(52, 176, 188, 1) 100%);
  background: linear-gradient(45deg, rgba(2, 68, 129, 0.9) 0%, rgba(52, 176, 188, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc024481', endColorstr='#cc34b0bc', GradientType=1);
}

.full-height .overlay-bg {
  display: table;
}

.full-height .overlay-gradient {
  display: table;
}

.full-height .center-y {
  display: table-cell;
  vertical-align: middle;
}

.pic-hover {
  width: 100%;
  position: relative;
  overflow: hidden;
}

i.btn-action-hide {
  opacity: 0;
}

.pic-hover:hover .bg-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.pic-hover:hover i.btn-action-hide {
  opacity: 1;
}

.pic-hover.hover-scale:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.center-xy {
  position: absolute;
  z-index: 5;
}

.center-xy i.btn-action {
  font-size: 24px;
  border: solid 2px rgba(255, 255, 255, 0.3);
  padding: 16px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  text-align: center;
  color: #fff;
}

.center-xy i.btn-action:hover {
  border-color: rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0.3);
}

.center-xy i.btn-action.btn-play {
  padding-left: 20px;
}

.bg-grey,
section.bg-grey {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

/* .wow {
    visibility: hidden;
} */

#back-to-top {
  background: #ffffff;
  position: fixed;
  bottom: -40px;
  right: 10px;
  z-index: 1020;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: poInter;
  border: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  outline: none;
  opacity: 0;
}

#back-to-top:hover {
  transform: scale(1.1);
}

#back-to-top.show {
  bottom: 20px;
  opacity: 1;
}

#back-to-top.hide {
  bottom: -40px;
}

#back-to-top:before {
  font-family: 'FontAwesome';
  font-size: 18px;
  content: '\f106';
  color: #fff;
  position: relative;
}

/* mouse scroll icon begin */

.mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 40px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 2px solid #fff;
  -webkit-animation: intro 1s;
  animation: intro 1s;
}

.scroll {
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: finger 1s infinite;
  animation: finger 1s infinite;
}

.mouse.m-black {
  border-color: #222222;
}

.mouse.m-black .scroll {
  background: #222222;
}

@-webkit-keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

/* mouse scroll icon close */

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.arrow-anim {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  background-size: contain;
}

.arrow-anim:before {
  content: '\f107';
  font-family: 'FontAwesome';
  font-size: 32px;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

a.read_more {
  display: inline-block;
  font-weight: bold;
  color: #222222;
  text-decoration: none;
}

a.read_more:hover {
  color: #707070;
}

a.read_more i {
  margin-left: 8px;
}

.text-light a.read_more {
  color: #fff;
}

.text-black {
  color: #000 !important;
}

#de-arrow-nav-set {
  text-align: center;
}

#de-arrow-nav-set i {
  color: #222;
  font-size: 32px;
}

#de-arrow-nav-set i.icon_menu {
  display: inline-block;
}

.profile_pic h4 {
  margin-bottom: 0px;
}

.profile_pic .subtitle {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
  color: #999;
}

.profile_pic .tiny-border {
  margin-bottom: 20px;
}

.profile_pic span {
  color: #fff;
}

.owl-custom-nav {
  position: absolute;
  z-index: 100;
  right: 4px;
  margin-top: 80px;
  width: 100%;
}

.owl-custom-nav .btn-next,
.owl-custom-nav .btn-prev {
  opacity: 0;
}

.owl-custom-nav .btn-next {
  right: -50px;
  position: absolute;
}

.owl-custom-nav .btn-prev {
  left: -50px;
  position: absolute;
}

.pf-hover:hover .btn-next {
  right: 30px;
  opacity: 1;
}

.pf-hover:hover .btn-prev {
  left: 30px;
  opacity: 1;
}

.owl-custom-nav .btn-next:before,
.owl-custom-nav .btn-prev:before {
  font-size: 18px;
}

.owl-custom-nav .btn-next:before {
  font-family: 'FontAwesome';
  content: '\f178';
  color: #fff;
  padding: 12px;
}

.owl-custom-nav .btn-prev:before {
  font-family: 'FontAwesome';
  content: '\f177';
  color: #fff;
  padding: 12px;
}

.owl-custom-nav a {
  text-decoration: none;
}

.owl-custom-nav .btn-prev:hover:before,
.owl-custom-nav .btn-next:hover:before {
  cursor: poInter;
}

#custom-owl-slider {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  background: #fff;
}

#custom-owl-slider .items {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  visibility: inherit;
  opacity: 1;
}

#custom-owl-slider .item img {
  display: block;
  width: 100%;
  height: 100%;
}

.owl-slider-nav {
  position: absolute;
  width: 100%;
}

.owl-slider-nav,
.owl-slider-nav div {
  position: absolute;
  z-index: 10;
  cursor: poInter;
}

.owl-slider-nav .next {
  right: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid transparent;
}

.owl-slider-nav .prev {
  left: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 50px solid transparent;
}

.owl-slider-nav .next:before {
  font-family: 'FontAwesome';
  font-size: 24px;
  content: '\f0da';
  color: #fff;
  position: absolute;
  right: -40px;
  top: -13px;
}

.owl-slider-nav .prev:before {
  font-family: 'FontAwesome';
  font-size: 24px;
  content: '\f0d9';
  color: #fff;
  position: absolute;
  left: -40px;
  top: -13px;
}

.owl-dots {
  text-align: center;
}

.owl-dot {
  background: #ccc;
  border: none;
  margin: 5px;
  width: 6px;
  height: 6px;
  padding: 0;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
}

.dark-scheme .owl-dot {
  background: rgba(255, 255, 255, 0.3);
}

.owl-item.active div blockquote {
  background: rgba(var(--secondary-color-rgb), 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.owl-item.active div blockquote,
.owl-item.active div blockquote .de_testi_by {
  opacity: 1;
}

.owl-item.active div blockquote:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 0 0 15px 0;
}

.owl-stage {
  overflow: none;
}

/* new table */

.de_table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  color: #1a1b1e;
  font-weight: 400;
}

.de_table .tr {
  display: table-row;
  border-collapse: collapse;
  border-bottom: solid 1px #eee;
}

.de_table:not(.no-heading) .tr:first-child {
  font-weight: 600;
  color: #111111;
}

/*.de_table .tr:nth-child(even) {
     background-color: rgba(var(--secondary-color-rgb), .1);
}*/
.de_table .tr .td {
  display: table-cell;
  padding: 16px 0;
  /*border-left: 1px solid #ddd;*/
}

.de_table .tr .td:nth-child(2) {
  font-weight: 600;
}

.de_table .tr .td:nth-child(2) span {
  display: inline-block;
  margin-left: 10px;
  color: #bbb;
  padding: 0 7px;
  border-radius: 5px;
  font-size: 14px;
}

.de_table .tr .td:nth-child(3) {
  font-size: 18px;
  font-weight: 600;
}

.de_table:not(.no-heading) .tr .td:first-child {
  border-left: 0;
}

.de_table.cols-2 .btn-main {
  padding: 5px 10px;
}

.de_table.table-style-2 {
  font-size: 15px;
}

.de_table.table-style-2 .tr .td {
  border: none;
  padding: 10px;
}

.de_table.table-style-2:not(.no-heading) .tr:first-child {
  color: #ffffff;
  border: none;
}

.de_table.cols-2 .tr .td:last-child {
  text-align: right;
}

.de_table .t-plus {
  color: #16c784;
}

.de_table .t-min {
  color: #ea3943;
}

.de_table .bc-btn {
  position: relative;
  display: inline-block;
  left: 0;
}

.de_table .tr .td:nth-child(2) img {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

/* close new table */
.table {
  white-space: nowrap;
}

.table tr td {
  font-size: 19px;
  color: #000;
  padding: 0.8rem 0.5rem;
}
.dark-scheme .table tr td {
  border-color: rgba(241, 245, 249, 0.05);
}
.table tr:last-child {
  border: none !important;
}
.table tr:last-child td {
  border: none !important;
}

.dark-scheme .table tr td,
.dark-scheme .table tr th {
  color: #fff;
  font-weight: 400;
}

.table tr th {
  font-size: 20px;
  color: #000;
  font-weight: 800;
  padding: 0.8rem 0.5rem;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #f0f0f0;
}
.dark-scheme .table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(241, 245, 249, 0.05);
}

/* .table [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
    padding: 0;
    height: 100%;
} */

.table-custom-1 {
  position: absolute;
  height: 100%;
}

.table .table {
  background: none;
}

.table-pricing {
  font-size: 18px;
  border-radius: 3px;
  border: none;
}

.table-pricing thead {
  color: #ffffff;
  font-size: 18px;
}

.table-pricing thead tr th {
  padding: 20px 0 20px 0;
}

.table-pricing tbody tr {
  width: 100%;
  background: #f2f2f2;
  border-top: solid 8px #ffffff;
}

.table-pricing tbody tr:hover {
  background: #e2e2e2;
}

.table-pricing tbody tr td,
.table-pricing tbody tr th {
  border: none;
  padding: 20px 0 20px 0;
}

.table-pricing tbody tr th .lbl,
.table-pricing tbody tr td .lbl {
  display: none;
}

.text-middle,
.table [class*='col-'].text-middle {
  vertical-align: middle;
}

.typed,
.typed-strings {
  display: inline-block;
}

.typed-strings p {
  position: absolute;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mask {
  width: 100%;
  position: relative;
  overflow: hidden !important;
  display: inline-block;
}

.mask img {
  width: 100%;
  height: 100%;
}

.mask .cover {
  display: table;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 70px;
  padding: 25px;
  background: #000000;
  bottom: 0px;
  top: 100%;
  margin-top: -70px;
}

.mask .cover .c-inner {
  display: table-cell;
  vertical-align: middle;
}

.mask.light .cover {
  background: #ffffff;
}

.mask .cover h3 {
  font-size: 18px;
  color: #fff;
  line-height: 1em;
  padding-left: 30px;
}

.mask.light .cover h3 {
  color: #000000;
}

.mask .cover p {
  color: #ffffff;
  padding-top: 15px;
  padding-right: 25px;
}

.rtl .mask .cover p {
  padding-right: 0;
}

.mask.light .cover p {
  color: #777777;
}

.mask:hover .cover {
  height: 100%;
  top: 0;
  margin-top: -0px;
  background: rgba(35, 37, 42, 0.8);
}

.mask:hover .cover p {
  opacity: 1;
}

.mask:hover .cover p {
  padding-top: 0px;
}

.mask .cover h3 span {
  margin-left: 20px;
}

.rtl .mask .cover h3 span {
  margin-right: 50px;
}

.mask .cover h3 i {
  font-size: 32px;
  position: absolute;
  left: 30px;
  margin-top: -8px;
}

.rtl .mask .cover h3 i {
  left: auto;
  right: 30px;
}

.mask.s1 .cover {
  top: 0;
  padding: 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0);
}

.mask.s1 .cover .desc {
  opacity: 0;
}

.mask.s1:hover .cover {
  background: rgba(0, 0, 0, 0.8);
}

.mask.s1:hover .cover .desc {
  opacity: 1;
}

.mask.s1 h3 {
  font-size: 24px;
}

.mask .cover a.btn-main {
  display: block;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
}

.mask .s-gradient img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.mask .s-gradient {
  position: relative;
}

.mask .s-gradient .gradient-fx {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: -moz-linear-gradient(
    left,
    rgba(var(--primary-color-rgb), 0.75) 0%,
    rgba(var(--primary-color-rgb), 0.2) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(var(--primary-color-rgb), 0.75) 0%,
    rgba(var(--primary-color-rgb), 0.2) 100%
  );
  background: linear-gradient(
    to right,
    rgba(var(--primary-color-rgb), 0.75) 0%,
    rgba(var(--primary-color-rgb), 0.2) 100%
  );
}

.style-2 .date-box {
  text-align: center;
  position: absolute;
  right: 0;
  padding: 15px 5px 5px 5px;
  color: #fff;
}

.style-2 .date-box .d {
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 5px;
}

.style-2 .date-box .m {
  font-family: var(--body-font);
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-left: 8px;
}

.sitemap {
  margin: 0;
  padding: 0;
}

.sitemap.s1 li {
  list-style: none;
}

.sitemap.s1 li:before {
  font-family: FontAwesome;
  content: '\f016';
  margin-right: 10px;
}

.list {
  margin: 0;
  padding: 0;
}

.list li {
  list-style: none;
}

.rtl .list.s1 li:after,
.list.s1 li:before {
  font-family: FontAwesome;
  content: '\f00c';
  margin-right: 10px;
}

.rtl .list.s1 li:after {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .list.s1 li:before {
  display: none;
}

.list.s2 li:before {
  font-family: FontAwesome;
  content: '\f055';
  margin-right: 10px;
}

.list.s3 {
  display: inline;
  margin: 0;
  padding: 0;
}

.list.s3 li {
  display: inline;
  margin-right: 20px;
}

.list.s3 a {
  font-weight: bold;
}

#owl-logo img {
  opacity: 0.8;
}

#owl-logo img:hover {
  opacity: 1;
}

.switch-set {
  direction: ltr !important;
}

.dark-scheme .switch {
  background: rgba(0, 0, 0, 0.5);
}

.switch-set div {
  display: inline-block;
  padding: 0 5px;
  direction: ltr !important;
}

.switch {
  margin-bottom: -5px;
  -webkit-appearance: none;
  height: 24px;
  width: 44px;
  background-color: #eeeeee;
  border-radius: 60px;
  position: relative;
  cursor: poInter;
}

.switch::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: gray;
  position: absolute;
  border-radius: 100%;
  transition: 0.5s;
  margin: 2px 0 0 2px;
}

.de-switch {
  display: block;
}

.de-switch h3 {
  font-weight: 400;
  padding-bottom: 6px;
}

.de-switch input[type='checkbox'] {
  display: none;
}

.de-switch input[type='checkbox']:checked + label {
  background-color: #2f7df9;
}

.de-switch input[type='checkbox']:checked + label:after {
  left: 23px;
}

.de-switch label {
  transition: all 200ms ease-in-out;
  display: inline-block;
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 40px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  color: transparent;
}

.dark-scheme .de-switch label {
  background-color: rgba(255, 255, 255, 0.1);
}

.de-switch label:after {
  transition: all 200ms ease-in-out;
  content: ' ';
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;
  top: 3px;
  left: 3px;
  right: auto;
}

.de-switch.colored input[type='checkbox']:checked + label {
  background-color: #55c946;
}

.de-switch.colored label {
  background-color: #ff4949;
}

.switch-with-title h5 {
  float: left;
}

.rtl .switch-with-title h5 {
  float: right;
}

.switch-with-title .de-switch {
  float: right;
}

.rtl .switch-with-title .de-switch {
  float: left;
}

.switch-with-title {
  padding: 20px;
  padding-bottom: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.dark-scheme .switch-with-title {
  border: solid 1px rgba(255, 255, 255, 0.1);
}

/*
 .switch:checked {
     background-color: blue;
}
*/
.switch:checked::after {
  transform: translateX(20px);
}

.switch:focus {
  outline-color: transparent;
}

.box-highlight {
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.1);
}

.box-highlight,
.box-highlight .content {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 5px;
}

.box-highlight .heading {
  padding: 5px 10px 0 10px;
}

.box-highlight .content {
  margin: 5px;
  padding: 20px;
  background: #ffffff;
}

.box-highlight .content,
.box-highlight .content strong {
  color: #111111;
}

.box-highlight .content.v1 p {
  font-size: 20px;
}

.box-cc {
  color: #1a1b1e;
  border: solid 1px #eeeeee;
  padding: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: 500;
  position: relative;
  background: #ffffff;
  -webkit-box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
  box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
}

.box-cc .bc-price span {
  display: inline-block;
  margin-left: 10px;
}

.box-cc img {
  margin-bottom: 10px;
  width: auto !important;
  height: auto !important;
}

.bc-btn {
  display: block;
  position: absolute;
  right: 20px;
  background: rgba(0, 0, 0, 0.05);
  padding: 0 8px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

#switch_scheme {
  display: inline-block;
  width: 40px;
  height: 40px;
  /* font-size: 20px; */
  line-height: 0.6em;
  /* padding-top: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  color: #ffffff;
  /* border: solid 1px rgba(255, 255, 255, .3); */
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 0px 2px #727273 inset;
  cursor: pointer;
}

#switch_scheme .fa-moon-o {
  color: #727273;
  font-weight: 600;
}
#switch_scheme .ss_dark svg path {
  fill: #000;
}

.dark-scheme #switch_scheme {
  color: #fff;
  /* border: solid 1px rgba(0, 0, 0, .3); */
  background: transparent;
  box-shadow: 0px 0px 0px 2px rgba(238, 241, 249, 0.6) inset;
}

#switch_scheme .ss_light {
  display: none;
}

.dark-scheme #switch_scheme .ss_dark {
  display: none;
}

.dark-scheme #switch_scheme .ss_light {
  display: block;
}

#section-hero .carousel-control-next,
.carousel-control-prev {
  width: max-content !important;
  opacity: 1 !important;
}

#section-hero .carousel {
  overflow: hidden;
}

#section-hero .carousel-control-prev {
  left: -60px !important;
}
#section-hero .carousel-control-next {
  right: -60px !important;
}

#section-hero .carousel-control-prev-icon,
#section-hero .carousel-control-next-icon {
  background-image: url(https://cdn.bazaa.io/bazaa/images/arrow-left-1.svg) !important;
  height: 100px;
  width: 105px;
  background-color: #fff;
  background-size: 15px;
  background-position: 70px 38px;
  border-radius: 100px;
}
.dark-scheme #section-hero .carousel-control-prev-icon {
  background-image: url(https://cdn.bazaa.io/bazaa/images/dark-arrow-left.svg) !important;
  background-color: #181818;
}

/* .dark-scheme #section-hero .carousel-control-prev-icon,
#section-hero .carousel-control-next-icon {
  background-image: url(https://vanar-cdn.bimtvist.com/bazaa/images/dark-arrow-left.svg) !important;
} */

#section-hero .carousel-control-next-icon {
  background-position: 20px 38px;
  background-image: url(https://cdn.bazaa.io/bazaa/images/arrow-right-1.svg) !important;
}
.dark-scheme #section-hero .carousel-control-next-icon {
  background-color: #181818;
  background-image: url(https://cdn.bazaa.io/bazaa/images/dark-arrow-right.svg) !important;
}

/* .dark-scheme #section-hero .carousel-control-next-icon {
  background-image: url(https://vanar-cdn.bimtvist.com/bazaa/images/dark-arrow-right.svg) !important;
} */

#selector {
  width: 500px;
  position: fixed;
  right: 0;
  top: 25%;
  padding: 5px;
  z-index: 1000;
}

#selector .opt {
  margin-top: 10px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  background: #ffffff;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  cursor: poInter;
  border: solid 1px rgba(255, 255, 255, 0.5);
}

#selector .opt:hover {
  border-color: #ffffff;
}

#selector .opt.tc1 {
  background: #ffde00;
}

#selector .opt.tc2 {
  background: #43baff;
}

#selector .opt.tc3 {
  background: #6ac70d;
}

#selector .opt.tc4 {
  background: #758fff;
}

#selector .opt.tc5 {
  background: #ff654c;
}

#selector .opt.tc6 {
  background: #ff3535;
}

#selector .opt.tc7 {
  background: #ae9749;
}

#selector .opt:after {
  position: relative;
  display: inline-block;
  content: '\f00c';
  font-family: 'FontAwesome';
  top: -5px;
  opacity: 0;
  transform: scale(2);
}

#selector .opt.active:after {
  opacity: 1;
  transform: scale(1);
}

#selector #dark-mode,
#selector #related-items {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: -155px;
  width: 200px;
  height: 39px;
  margin-top: 45px;
  position: absolute;
  background: #21273e;
  padding: 8px;
  padding-top: 6px;
  cursor: poInter;
  overflow: hidden;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding-left: 45px;
  text-align: left !important;
}

#selector #related-items {
  margin-top: 100px;
  background: #82b440;
  color: #ffffff;
}

#selector #dark-mode:hover,
#selector #related-items:hover {
  right: 0;
}

#selector #dark-mode:before,
#selector #related-items:before {
  font-weight: normal;
  display: inline-block;
  content: '\f186';
  font-family: 'FontAwesome';
  font-size: 20px;
  padding: 6px;
  padding-top: 0;
  margin-right: 10px;
  position: absolute;
  left: 10px;
}

.dark-scheme #selector #dark-mode {
  color: #0d0c22;
  background: #ffffff;
}

.dark-scheme #selector #dark-mode:before {
  content: '\f185';
}

#selector #related-items:before {
  content: '\f05a';
}

#selector #dark-mode.active {
  background: #fdd32a;
  color: #222222;
}

.disable-dark #selector .dark-mode {
  display: none;
}

#selector .sc-opt {
  cursor: poInter;
  color: #fff;
  font-size: 14px;
  height: 38px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  max-width: 45px;
  padding-right: 20px;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  right: 0;
  font-weight: 500;
}

#selector .sc-opt:hover {
  max-width: 100%;
}

#selector .sc-opt .sc-icon {
  width: 45px;
  height: 38px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding: 12px 10px;
  position: relative;
}

#demo-rtl,
.rtl #demo-rtl {
  direction: ltr;
}

.hide-rtl #demo-rtl {
  display: none;
}

#selector .sc-mt {
  margin-top: 45px;
}

#selector .sc-val {
  display: inline-block;
  margin-top: 5px;
}

.de-box-image {
  padding: 10px;
  text-align: center;
}

.de-box-image img {
  margin-bottom: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: solid 1px #eeeeee;
  -webkit-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
  -moz-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
  box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
}

.de-box-image:hover img {
  margin-top: -20px;
  -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
  -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
  box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
}

.de-box-image:hover h4 {
  margin-top: 20px;
}

.de-image-hover {
  position: relative;
  overflow: hidden;
}

.dih-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eee;
  opacity: 0;
}

.dih-title-wrap {
  z-index: 2;
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
}

.dih-title {
  opacity: 0;
  font-size: 16px;
  z-index: 2;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
}

.de-image-hover:hover .dih-overlay {
  opacity: 0.8;
}

.de-image-hover:hover .dih-title {
  opacity: 1;
  transform: scale(1.2);
}

.de-image-hover:hover img {
  transform: scale(1.1);
}

.de-image-hover:hover {
  -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.img-fullwidth {
  width: 100%;
  height: auto;
}

.nft_coll {
  background: #ffffff;
  padding-bottom: 10px;
  border: solid 1px #dddddd;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-bottom: 10px;
}

.nft_coll.style-2 {
  border: none;
  background: none !important;
}

.nft_coll.style-2 .nft_wrap {
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.dark-scheme .nft_coll {
  background: var(--dark-scheme-color-2);
  border-color: var(--dark-scheme-color-2);
}

.text-light .nft_coll {
  background: rgba(255, 255, 255, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.nft_wrap {
  position: relative;
  width: 100%;
  height: 200%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
}

.nft_coll:hover img {
  transform: scale(1.05);
}

.nft_coll {
  text-align: center;
}

.nft_coll_info {
  padding-bottom: 10px;
}

.nft_coll h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.nft_coll span {
  font-size: 14px;
}

.nft_coll p {
  margin-top: 10px;
}

.nft_coll_by {
  font-weight: bold;
}

.nft_coll_pp {
  width: 60px;
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 10px;
  position: relative;
}

.nft_coll_pp img {
  width: 60px;
  height: auto;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: solid 5px #ffffff;
  background: #ffffff;
}

.nft_coll_pp i {
  font-size: 10px;
  color: #ffffff;
  background: #333;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  padding: 3px;
  position: absolute;
  bottom: 4px;
  right: 5px;
}

/* .author_list:not(.alt-1) {
    -moz-column-count: 4;
    -moz-column-gap: 20px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
    column-gap: 20px;
}

.author_list.d-col-3:not(.alt-1) {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 60px;
} */

.author_list.d-col-3 li:before {
  font-weight: bold;
}

.author_list.alt-1 {
  margin: 0;
  padding: 0;
}

.author_list.alt-1 li {
  list-style: none;
  counter-increment: step-counter;
  margin-bottom: 30px;
  padding-left: 40px;
}

.author_list.alt-1 li:before {
  font-family: var(--title-font);
  font-weight: bold;
  position: absolute;
  content: counter(step-counter);
  font-size: 40px;
  border-radius: 11px;
  left: 0;
  padding: 13px 0;
  text-align: center;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  width: 52px;
  height: 52px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  background: -webkit-linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.author_list.alt-2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  display: flex;
}

.seller_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.author_list.alt-2 li {
  font-size: 15px;
  list-style: none;
  /* counter-increment: step-counter; */
  margin-bottom: 10px;
  padding: 10px 20px 10px 0;
  display: flex;
  align-items: center;
  width: 25%;
}

.author_list.alt-2 li:hover {
  background: #eee;
  border-radius: 10px;
}

.dark-scheme .author_list.alt-2 li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.author_list.alt-2 a {
  display: flex;
  align-items: center;
  width: 100%;
  color: #000;
}

.dark-scheme .author_list.alt-2 a {
  color: #fff;
}

.rtl .author_list.alt-2 li {
  padding-left: 0;
  padding-right: 40px;
}

#section-collections {
  padding: 100px 0 20px;
}

.table_top_content {
  width: 100%;
  color: #000;
  padding: 0 12px 15px 10px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 8px;
  font-weight: 400;
}

.dark-scheme .table_top_content {
  color: #fff;
  border-bottom: 1px solid rgb(238 238 238 / 9%);
}

.table_top_content .first_col {
  width: 80px;
  padding-left: 10px;
}

.flex-1 {
  flex: 1;
}

.dark-scheme .author_list.alt-2 li:before {
  color: #ffffff;
}

.rtl .author_list.alt-2 li:before {
  right: 0;
  left: auto;
}

.rtl .author_list.alt-1 li {
  padding-left: 0;
  padding-right: 50px;
}

.rtl .author_list.alt-1 li:before {
  left: auto;
  right: 0;
}

.author_list li {
  position: relative;
  margin-bottom: 30px;
}

.author_list.alt-2 .author_list_pp {
  position: relative !important;
  margin-left: 0;
}

.top_seller_img {
  width: 100%;
  height: 100%;
}

.counter_seller span {
  color: #222;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 19px;
  font-weight: 700;
}

.dark-scheme .counter_seller span {
  color: #fff;
}

.author_list_pp {
  margin-top: 2px;
  position: absolute;
  display: inline-flex;
  width: 70px;
  height: 70px;
  align-items: center;
  margin-left: 10px;
  background: #e8e8e8;
  border-radius: 100px;
  z-index: 10;
  overflow: hidden;
  justify-content: center;
}
.dark-scheme .author_list_pp {
  background: rgb(238 238 238 / 10%);
}

.author_list_pp img {
  position: relative;
  z-index: 1;
  background-color: transparent !important;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}

.author_list_pp a {
  width: 50px;
  height: 50px;
  display: block;
}

.author_list_pp i {
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
}

.de-pp-group a {
  position: absolute;
}

.de-pp-group a:nth-child(2) {
  left: 30px;
  width: 50px;
}

.de-pp-group a:nth-child(3) {
  left: 60px;
  width: 50px;
}

.de-pp-group a:hover {
  z-index: 10;
}

.author_list.alt-2 .author_list_info {
  padding-left: 20px;
}

.author_list.alt-2 .author_list_info .user_name {
  max-width: 176px;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 4px;
}

.author_list_info {
  font-weight: bold;
  padding-left: 70px;
}

.rtl .author_list_info {
  padding-left: 0;
  padding-right: 70px;
}

.author_list_info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2em;
}

.author_list_info a {
  color: #1a1b1e;
}

.author_list_info_e {
  /* float: right; */
  /* position: absolute; */
  top: 0;
  right: 0;
  text-align: left;
  font-size: 12px;
  line-height: 1.8em;
  margin-left: auto;
}

.rtl .author_list_info_e {
  text-align: left;
  right: auto;
  left: 0;
}

.author_list_info_e span.title {
  margin-right: 14px;
}

.author_list_info_e span {
  font-size: 16px;
  display: block;
  font-weight: 400;
  color: #222;
}

.dark-scheme .author_list_info_e span {
  color: #ffffff;
}

.coll_list_pp {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: auto;
  margin-left: 10px;
  margin-top: -12px;
  z-index: 10;
  left: 0;
}

.rtl .coll_list_pp {
  left: auto;
  right: 0;
}

.coll_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  position: relative;
  z-index: 1;
}

.coll_list_pp i {
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
}

.single_collection_holder {
  max-width: 1377px;
  margin: 0 auto;
  padding-bottom: 120px;
}

.opt-create {
  display: inline-block;
  text-align: center;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 116px 40px;
  min-width: 48%;
  margin: 1%;
  border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
}

.dark-scheme .opt-create {
  background: rgba(241, 245, 249, 0.05);
  border-color: rgba(238, 241, 249, 0.1);
}

.opt-create img {
  position: relative;
  width: 100px;
}

.opt-create:hover img {
  transform: scale(1.2);
}

.opt-create h3 {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

.de-table.table-rank th,
.de-table.table-rank tr {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05) !important;
}

.dark-scheme .de-table.table-rank th,
.dark-scheme .de-table.table-rank tr {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
}

.de-table.table-rank th,
.de-table.table-rank td {
  padding: 30px 0;
}

.de-table.table-rank .d-plus {
  color: #34c77b;
}

.de-table.table-rank .d-min {
  color: #eb5757;
}

.de-table.table-rank tbody th {
  position: relative;
  padding-left: 75px;
}

.rtl .de-table.table-rank tbody th {
  padding-left: 0;
  padding-right: 75px;
}

.dark-scheme .de-table {
  color: #ffffff;
}

.dark-scheme .author_list_info a {
  color: #ffffff;
}

.text-light .author_list_info a {
  color: #ffffff;
}

.p_list {
  margin-bottom: 30px;
}

.p_list_pp {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: auto;
  margin-left: 0px;
  margin-top: -3px;
  z-index: 10;
}

.p_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
}

.p_list_pp i {
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.p_list_info {
  font-weight: 400;
  padding-left: 70px;
}

.p_list_info h5 {
  font-size: 16px;
  margin-bottom: 0;
}

.rtl .p_list_info {
  padding-left: 0;
  padding-right: 70px;
}

.dark-scheme .p_list_info b {
  color: #ffffff;
}

.p_list_info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2em;
}

.p_list_info a {
  color: #1a1b1e;
}

.text-light .p_list_info a {
  color: #ffffff;
}

.item_info {
  padding-left: 20px;
}

.detail_left_side {
  display: flex;
  flex-direction: column;
  height: 640px;
}

.rtl .item_info {
  padding-left: 0;
  padding-right: 20px;
}

.item_info_counts {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}

.item_info_counts > div {
  margin-right: 10px;
  font-weight: 400;
  padding: 2px 10px;
  font-size: 14px;
  text-align: center;
  min-width: 80px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.item_info_counts > div i {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.5);
}

.rtl .item_info_counts > div i {
  margin-right: 0;
  margin-left: 5px;
}

.dark-scheme .item_info_counts > div i {
  color: rgba(255, 255, 255, 0.7);
}

.item_info h6 {
  margin-bottom: 0;
  font-size: 16px;
  color: #727273;
}

.fw-600 {
  font-weight: 600;
}

.detail_page_description {
  max-height: 160px;
  padding-right: 10px;
  overflow-y: auto;
  font-size: 20px;
  overflow-wrap: break-word;
}

.item_info .de_countdown {
  position: relative;
  display: inline-block;
  margin: 0;
  right: 0;
  border: none;
  padding: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.item_info .de_countdown .countdown-section {
  font-size: 16px;
}

.item_info h2 {
  display: block;
  margin-bottom: 30px;
}

.item_author .author_list_pp {
  margin-left: 0;
  width: 50px;
  height: 50px;
}

.item_author img {
  border-radius: 100px !important;
}

.item_author img.detail_creator {
  width: 48px;
  height: 48px;
  object-fit: cover;
  background: #e8e8e8;
}
.dark-scheme .item_author img.detail_creator {
  background: rgb(238 238 238 / 10%);
}

.item_author_image {
  background: #e8e8e8;
}
.dark-scheme .item_author_image {
  background: rgb(238 238 238 / 10%);
}

.refresh-meta {
  color: #727273;
  font-weight: 500;
  border-top: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
  padding: 20px;
  padding-left: 0;
}

.dark-scheme .refresh-meta {
  border-top: 1px solid rgb(238 241 249 / 14%);
  border-bottom: 1px solid rgb(238 241 249 / 14%);
}

.detail_buttons .btn-main {
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.refresh-meta img {
  margin-right: 8px;
}

.item_author .author_list_info {
  padding-top: 10px;
  padding-left: 70px;
}

#nft-item-details {
  margin-top: 60px;
}

#nft-item-details .item_author .title_text h6 {
  color: #727273;
  font-size: 16px;
  font-weight: 400;
}

#nft-item-details .item_author .title_text a {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  min-height: 24px;
  display: block;
  padding-right: 64px;
}

.dark-scheme #nft-item-details .item_author .title_text a {
  color: #fff;
}

/* #nft-item-details .image-autosize{
    position: fixed;
    max-width: 100%;
} */

#nft-item-details .nft-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 12px;
}

#nft-item-details .nft-image-wrapper img.nft__item_preview_detail {
  object-fit: contain;
  width: 100% !important;
  height: auto !important;
}

img.nft__item_preview_detail {
  object-fit: contain;
}

.dark-scheme #nft-item-details .nft-image-wrapper {
  background: rgba(255, 255, 255, 0.04);
}

#nft-item-details audio {
  position: absolute;
  width: 95%;
  padding-left: 5%;
  margin-top: 5%;
}

.filter-tab {
  /* width: 100%;
  flex-basis: 100%; */
  padding: 11px 22px;
  border-radius: 100px;
  border: 1.2px solid #eef1f9;
  background: #fefefe;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  /* margin-bottom: 24px; */
}

.filter-tab i {
  font-size: 40px;
  margin-right: 20px;
}

.filter-tab:hover {
  transform: translateY(-4px);
}

.filter-tab h6 {
  color: #141416;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.44px;
}

.dark-scheme .filter-tab h6 {
  color: #fff;
}

.filter-tab img {
  width: 55px;
  height: 55px;
  border-radius: 6px;
  margin-right: 20px;
  object-fit: cover;
  object-position: center;
}

/* .filter-tab.active {
  border: 1px solid #eef1f9;
  background: rgba(187, 187, 187, 0.2);
} */
.filter-tab.active {
  /* background:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #ffb800, #f35af6) border-box; */
  background: linear-gradient(to right, #ffb800, #f35af6) border-box;
}
.filter-tab.active h6 {
  color: #121212;
}

.nft__item:hover .nft__item_extra_hover {
  opacity: 1;
}

.nft__item.style-2,
.dark-scheme .nft__item.style-2 {
  border: none;
}

.dark-scheme .nft__item {
  background: rgba(241, 245, 249, 0.1);
}

.nft__item_action {
  font-size: 14px;
}

.text-light .nft__item {
  background: rgba(255, 255, 255, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.nft__item:hover {
  -webkit-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  -moz-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}

/* .nft__item:hover img.nft__item_preview {
    transform: scale(1.03);
} */

.dark-scheme .nft__item_wrap {
  background: rgb(238 238 238 / 10%);
  /* background: transparent; */
}

.collection-body-loader {
  height: calc(100vh - 300px) !important;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.nft__item_wrap a {
  display: block;
  width: 100%;
}

.nft__item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
}

img.nft__item_preview {
  /* max-width: 95%;
    max-height: 95%;
    border-radius: 8px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

img.nft__item_preview_detail {
  max-width: 90%;
  max-height: 90%;
}

.object-fit-cover {
  object-fit: cover;
}

.nft__item .author_list_pp {
  margin: 0;
}

.nft__item h4 {
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 10px;
}

.nft__item_info {
  position: relative;
  min-height: 40px;
  padding: 0 15px;
}

.floor-item {
  color: #121212;
  font-weight: 600;
}

.dark-scheme .floor-item {
  color: #fff;
}

.dark-scheme .floor-item svg .dark {
  fill: #fff;
}

.floor-item span {
  line-height: normal;
}

.floor-item span.text-gray {
  color: #9b9b9b;
  font-weight: 500;
  font-size: 17px;
}

.nft__item_info .d-author {
  font-weight: 600;
  font-size: 12px;
  color: #727272;
}

.nft__item_info a {
  font-weight: bold;
}

.nft__item_price {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.nft__item_price span {
  margin-left: 10px;
  color: #0d0c22;
  font-weight: 700;
}

.dark-scheme .nft__item_price span {
  color: #ffffff;
}

.nft__item .portrait {
  height: 100% !important;
  width: auto;
}

.nft__item_like {
  position: relative;
  bottom: 22px;
  font-size: 14px;
  color: #ddd;
  float: right;
}

.rtl .nft__item_like {
  float: left;
}

.dark-scheme .nft__item_like {
  color: rgba(255, 255, 255, 0.2);
}

.nft__item_like span {
  color: #808080;
  margin-left: 5px;
  font-size: 12px;
}

.dark-scheme .nft__item_like span {
  color: #ffffff;
}

.nft__item_like i.active {
  color: #ec7498;
}

.nft__item_like:hover i {
  color: #ec7498;
}

.rtl .nft__item_like i {
  margin-left: 5px;
}

.nft__item_click {
  position: absolute;
  top: -6px;
  right: 0;
  text-align: center;
}

.rtl .nft__item_click {
  right: auto;
  left: 0;
}

.nft__item_click span:after {
  display: block;
  font-size: 16px;
  content: '...';
  position: relative;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  color: #0d0c22;
  line-height: 0;
  width: 24px;
  height: 24px;
  padding: 8px 0 0 2px;
  border-radius: 30px;
}

.dark-scheme .nft__item_click span:after {
  color: #ffffff;
}

.nft__item_click span:hover:after {
  background: rgba(0, 0, 0, 0.1);
}

.dark-scheme .nft__item_click span:hover:after {
  background: rgba(255, 255, 255, 0.1);
}

.nft__item_extra {
  position: absolute;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  left: 0;
  display: table;
  transform: scale(1.03);
  -moz-backdrop-filter: saturate(180%) blur(6px);
  -webkit-backdrop-filter: saturate(180%) blur(6px);
  backdrop-filter: saturate(180%) blur(6px);
  background: rgba(black, 0.1);
  visibility: hidden;
}

.nft__item_extra_hover {
  /* background: linear-gradient(104deg, #03D9AF 10.18%, #50FDDB 100.53%); */
  display: flex;
  align-items: center;
  /* border-radius: 0 0 12px 12px; */
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30%;
  z-index: 10;
  opacity: 0;
  justify-content: center;
  gap: 9px;
  transform: translate(0, -50%);
  /* -moz-backdrop-filter: saturate(180%) blur(6px);
    -webkit-backdrop-filter: saturate(180%) blur(6px);
    backdrop-filter: saturate(180%) blur(6px); */
}

.dark-scheme .nft__item_extra {
  background: rgba(22, 29, 48, 0.8);
}

.nft__item_buttons {
  /* display: table-cell; */
  vertical-align: middle;
}

.nft__item_extra button {
  width: 80%;
  display: block;
  margin: 5px auto;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}

.nft__item_extra_hover button {
  width: 126px;
  height: 40px;
  display: block;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  /* padding: 11px 26px; */
  border-radius: 8px;
  /* border: 1px solid #ffb800; */
  /* background: #263b80; */
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
}
.btn-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  line-height: 16px;
  color: #fff;
  padding: 11px 11px;
  border-radius: 8px;
  /* border: 1px solid #4d72ed; */
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
}
.btn-label.listing_cart {
  font-size: 16px;
  line-height: 18px;
  color: #000;
  font-weight: 700;
  min-width: 100px;
  text-align: center;
}

.dark-scheme .nft__item_extra button {
  border-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.nft__item_extra button:hover {
  border-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}

.nft__item_share {
  margin-top: 20px;
}

.nft__item_share a {
  font-size: 12px;
  display: inline-block;
  margin: 0 3px 0 3px;
  width: 32px;
  height: 32px;
  padding: 3px;
  padding-top: 4px;
  text-align: center;
  border-radius: 30px;
  color: #ffffff;
}

.nft__item_share a:hover {
  color: #ffffff;
}

.nft__item_share h4 {
  font-size: 12px;
}

.nft__item_lg img {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.nft__item_lg h2 {
  font-size: 48px;
  line-height: 60px;
}

.nft__item_lg h3 {
  font-size: 32px;
  margin: 0;
  margin-top: 4px;
}

.nft__item_lg h5 {
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.dark-scheme .nft__item_lg h5 {
  color: rgba(255, 255, 255, 0.4);
}

.nft__item_lg .d-title {
  font-size: 16px;
  display: inline-block;
  margin-bottom: 5px;
}

.nft__item_lg .d-desc {
  padding-left: 30px;
}

.rtl .nft__item_lg .d-desc {
  padding-left: 0px;
  padding-right: 30px;
}

.nft__item_lg .d-attr {
  display: flex;
}

.nft__item_lg .d-attr > div:nth-child(2) {
  margin-left: 40px;
  border-left: solid 1px rgba(0, 0, 0, 0.3);
  padding-left: 40px;
}

.rtl .nft__item_lg .d-attr > div:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
  margin-right: 40px;
  padding-right: 40px;
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.3);
}

.rtl.dark-scheme .nft__item_lg .d-attr > div:nth-child(2) {
  border-right-color: rgba(255, 255, 255, 0.3);
}

.dark-scheme .nft__item_lg .d-attr > div:nth-child(2) {
  border-left-color: rgba(255, 255, 255, 0.3);
}

.nft__item_lg .d-buttons {
  margin-top: 30px;
}

.nft__item_lg .de_countdown {
  padding-top: 10px;
  float: none;
  background: none;
  border: none;
  position: relative;
}

.nft__item_lg .countdown-section {
  font-size: 32px;
  margin-left: 8px;
  font-weight: bold;
}

.nft__item_lg .d-author {
  margin-bottom: 30px;
}

.nft__item_lg .d-author .author_list_info {
  padding-top: 5px;
}

.nft__item_lg .d-author .author_list_pp {
  margin-left: 0;
}

.nft-item-price {
  margin-bottom: 30px;
}

.nft-item-price span {
  color: #0d0c22;
  font-weight: bold;
  font-size: 32px;
}

.dark-scheme .nft-item-price span {
  color: #ffffff;
}

.nft-item-price img {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.nft_pic.style-2 {
  margin: 25px;
}

.nft_pic_info {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  padding: 40px;
  top: 0;
  z-index: 1;
  font-size: 28px;
  color: #ffffff;
}

.nft_alt_slider .nft_pic_info {
  background: none;
}

.nft_pic_title {
  display: block;
  font-weight: bold;
}

.nft_pic_by {
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

.nft_pic.nft_multi a {
  position: relative;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  width: calc(50% - 10px);
  float: left;
  overflow: hidden;
}

.nft_pic video {
  width: 100%;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.nft_pic.nft_multi.masonry a {
  float: none;
}

.nft_pic.nft_multi a.pic-wide {
  width: 100%;
}

.nft_pic.nft_multi a:nth-child(1) {
  margin: 0 10px 10px 0;
}

.nft_pic.nft_multi a:nth-child(2) {
  margin: 0 0 10px 10px;
}

.nft_pic.nft_multi a:nth-child(3) {
  margin: 10px 10px 0 0;
}

.nft_pic.nft_multi a:nth-child(4) {
  margin: 10px 0 0 10px;
}

.nft_pic.nft_multi:hover img {
  transform: scale(1);
}

.nft_pic.nft_multi a:hover img {
  transform: scale(1.2);
}

.nft_pic.nft_multi .nft_pic_title {
  position: absolute;
  margin: 20px;
  z-index: 1;
}

.nft_pic.nft_multi .nft_pic_title h4 {
  font-size: 18px;
  color: #ffffff;
  margin: 0;
}

.nft_pic.nft_multi .nft_pic_title span {
  color: rgba(255, 255, 255, 0.5);
}

.nft_pic.nft_multi .nft_pic_title.text-dark h4 {
  color: #000000 !important;
}

.nft_pic.nft_multi .nft_pic_title.text-dark span {
  color: rgba(0, 0, 0, 0.5);
}

.nft_pic.mod-a {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.nft_pic.mod-b {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.nft_pic .nft_pic_wrap {
  height: 750px;
}

/* @media all and (min--moz-device-pixel-ratio:0){
    .nft_pic .nft_pic_wrap::before {
        position: absolute;
        content: "";
        background: rgba(0, 0, 0, 0.25);
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.80);
    }
} */

.nft_pic .nft_pic_wrap img {
  height: 100%;
  object-fit: cover;
}

.custom-slider .react-multi-carousel-track {
  margin: 0 -20px !important;
}

/* .custom-slider ul li{
    width: 304px !important;
} */
.custom-slider .react-multi-carousel-item {
  padding: 20px 0 20px 20px;
}

/* @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none) or (-moz-backdrop-filter: none)) {
    .nft_pic.mod-b .nft_pic_info {
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(60px);
    }
  } */

.nft_pic.mod-b .nft_pic_info {
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  position: absolute;
  background: none;
  padding: 30px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(60px);
  -moz-backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(60px);
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .nft_pic.mod-b .nft_pic_info {
    background: rgba(0, 0, 0, 0.8);
  }
}

.nft_pic.mod-b .nft_pic_info .carousel-container {
  max-width: 1220px;
  margin: 0 auto;
  display: flex;
  gap: 100px;
  align-items: center;
  height: 100%;
}

.nft_pic.mod-b .nft_pic_info .carousel-container .image-holder {
  width: 520px;
  height: 520px;
}

.nft_pic.mod-b .nft_pic_info .nft_pic_title {
  font-size: 60px;
  line-height: 64px;
  margin: 0 0 20px;
}

.nft_pic.mod-b .nft_pic_info .description {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 0 0 30px;
}

.nft_pic.mod-b .nft_pic_info .nft_pic_by {
  font-size: 14px;
  display: block;
}

.nft_attr {
  display: block;
  padding: 20px 10px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid rgba(229, 232, 240, 0.9);
  background: #fff;
  margin-bottom: 8px;
  display: flex;
  /* min-height: 110px; */
  flex-direction: column;
  justify-content: center;
}

.dark-scheme .nft_attr {
  background: transparent;
  border: 1px solid rgba(241, 245, 249, 0.05);
}

/* .dark-scheme .nft_attr {
    border: solid 1px rgba(255, 255, 255, .15);
} */

.nft_attr h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727273;
}

.nft_attr h4 {
  margin-bottom: 0px;
  font-size: 18px;
}

.nft_attr span {
  font-size: 13px;
  color: #727272;
}

.menu_side_area {
  margin-left: 20px;
}

.rtl .menu_side_area {
  margin-left: 0;
  margin-right: 10px;
}

.owner_icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #e8e8e8;
}
.owner_icon .owner_svg {
  fill: transparent;
}
.owner_icon path {
  stroke: #000;
}
.owner_icon path.owner_svg_1 {
  fill: #000;
  stroke: none;
}
.dark-scheme .owner_icon path {
  stroke: #fff;
}
.dark-scheme .owner_icon path.owner_svg_1 {
  fill: #fff;
  stroke: none;
}
.dark-scheme .owner_icon {
  background: rgb(224 233 242 / 14%);
}

/* .dark-scheme .owner_icon svg path {
  fill: #CECECE;
} */

.owner_main_holder {
  padding: 1rem 1rem;
  max-height: 490px;
  overflow-y: auto;
}

.dark-scheme .owner_holder {
  border-bottom: 1px solid rgb(238 241 249 / 14%) !important;
}

.de_countdown {
  position: absolute;
  right: 20px;
  background: #ffffff;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border: solid 2px #dddddd;
  z-index: 100;
  color: #0d0c22;
  min-width: 150px;
  padding-left: 10px;
  text-align: center;
}

.de_countdown.s2 {
  text-align: left;
}

.de_countdown.bg-color-secondary {
  border: none;
}

.rtl .de_countdown {
  right: auto;
  left: 20px;
}

.nft_layout-2 .de_countdown {
  width: 100%;
  position: static;
  text-align: center;
  margin-bottom: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dark-scheme .de_countdown {
  color: #fff;
  background: none;
}

.text-light .de_countdown {
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.countdown-section {
  font-size: 14px;
  margin-right: 8px;
  font-weight: bold;
}

.nft_layout-2 .countdown-section {
  font-size: 15px;
  padding: 5px;
}

.d_profile {
  margin-top: -50px;
  margin-bottom: 95px;
}

.profile_avatar {
  display: flex;
}

.d_coll .profile_avatar {
  display: block;
  text-align: center;
}

.profile_avatar img,
.profile_name {
  display: flex;
  align-items: center;
}

.d_coll .profile_avatar img,
.d_coll .profile_name {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.profile_avatar img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 100%;
  height: 100%;
}

.d_coll .profile_avatar img {
  display: inline-block;
  margin-top: -100px;
  margin-bottom: 30px;
}

.profile_name {
  margin-left: 20px;
}

.rtl .profile_name {
  margin-left: 0;
  margin-right: 20px;
}

.profile_name h4 {
  font-size: 24px;
  line-height: 1.3em;
}

.profile_follower {
  margin-right: 20px;
  font-weight: 600;
}

.rtl .profile_follower {
  margin-right: 0;
  margin-left: 20px;
}

.dark-scheme .profile_follower {
  color: #ffffff;
}

.profile_username,
.profile_wallet {
  font-size: 16px;
  font-weight: 500;
}

.profile_username {
  display: block;
  font-weight: 600;
  color: gray !important;
}

.profile_wallet {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  float: left;
  color: #727272;
}

.rtl .profile_wallet {
  float: right;
}

.dark-scheme .profile_wallet {
  color: var(--dark-scheme-font-color);
}

.d_coll .profile_wallet {
  display: inline-block;
  float: none;
}

#btn_copy {
  position: absolute;
  font-size: 12px;
  padding: 4px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;
}

.dark-scheme #btn_copy {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  padding: 6px 12px;
}

#btn_copy.clicked {
  color: #ffffff;
}

.d_coll .d_profile_img {
  position: relative;
  margin: 0 auto;
}

.d_coll .d_profile_img i {
  color: #ffffff;
  margin: 10px 0 0 -40px;
  float: none;
  display: inline-block;
}

.rtl .d_coll .d_profile_img i {
  margin: 10px -40px 0 0;
  float: none;
}

.tabs_wrapper {
  margin-top: 40px;
}

.hide-content {
  display: none;
}

.display-none {
  display: none;
}

.nft_type_wrap {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-pause {
  position: relative;
  cursor: pointer;
  height: 60px;
  width: 60px;
  padding: 17px 0;
  text-align: center;
  z-index: 2;
  background: #333333;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.play-pause:before {
  font-family: FontAwesome;
  content: '\f04b';
  font-size: 24px;
  color: #ffffff;
  padding-left: 5px;
}

.play-pause:active {
  transform: scale(0.9);
}

.play-pause.pause:before {
  content: '\f04c';
  padding-left: 0;
}

.circle-ripple {
  display: none;
  position: absolute;
  background-color: #35ffc3;
  z-index: 1;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 0.7s linear infinite;
}

@-webkit-keyframes ripple {
  0% {
    box-shadow:
      0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 1em rgba(255, 255, 255, 0.3),
      0 0 0 3em rgba(255, 255, 255, 0.3),
      0 0 0 5em rgba(255, 255, 255, 0.3);
  }

  100% {
    box-shadow:
      0 0 0 1em rgba(255, 255, 255, 0.3),
      0 0 0 3em rgba(255, 255, 255, 0.3),
      0 0 0 5em rgba(255, 255, 255, 0.3),
      0 0 0 8em rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  0% {
    box-shadow:
      0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 1em rgba(255, 255, 255, 0.3),
      0 0 0 3em rgba(255, 255, 255, 0.3),
      0 0 0 5em rgba(255, 255, 255, 0.3);
  }

  100% {
    box-shadow:
      0 0 0 1em rgba(255, 255, 255, 0.3),
      0 0 0 3em rgba(255, 255, 255, 0.3),
      0 0 0 5em rgba(255, 255, 255, 0.3),
      0 0 0 8em rgba(255, 255, 255, 0);
  }
}

.wallet-images-group-1 img {
  margin: 0 5px 10px 5px;
  width: 60px;
  padding: 10px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

/* 5 columns */

@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
  }

  .col-md-2-5 {
    width: 40%;
  }

  .col-md-3-5 {
    width: 60%;
  }

  .col-md-4-5 {
    width: 80%;
  }

  .col-md-5-5 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
  }

  .col-lg-2-5 {
    width: 40%;
  }

  .col-lg-3-5 {
    width: 60%;
  }

  .col-lg-4-5 {
    width: 80%;
  }

  .col-lg-5-5 {
    width: 100%;
  }
}

.text-border {
  font-weight: bold;
  font-size: 60px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 1);
}

/* ================================================== */

/* quick-search */

/* ================================================== */

#quick_search {
  margin-left: 30px;
  padding: 4px 10px 4px 20px !important;
  height: auto !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  border: 2px solid #ededed !important;
  background: transparent;
  width: 300px !important;
  font-family: var(--title-font) !important;
  outline: none !important;
}

.rtl #quick_search {
  margin-left: 0;
  margin-right: 30px;
}

#quick_search.style-2 {
  background: rgba(0, 0, 0, 0.05) !important;
}

.dark-scheme #quick_search.style-2 {
  background: rgba(255, 255, 255, 0.05) !important;
}

header:not(.header-light) #quick_search {
  color: #ffffff;
}

header:not(.header-light) #quick_search::-moz-input-placeholder {
  color: #ffffff;
}

header:not(.header-light) #quick_search::-webkit-input-placeholder {
  color: #ffffff;
}

/* .header-light #quick_search {
    background: #fff;
} */
.dark-scheme .header-light #quick_search {
  color: #fff;
  border: 1.5px solid rgba(238, 241, 249, 0.2) !important;
}

.quick-search {
  display: inline-block;
  margin-left: 10px;
  padding: 10px 0 0 0;
}

.quick-search input {
  width: 120px;
  border-radius: 40px;
}

.side-bg {
  position: relative;
}

.image-container {
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}

.side-bg .background-image {
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
}

.side-bg.right .image-container {
  right: 0;
}

#search {
  float: left;
  width: 70%;
  margin-bottom: 0;
}

#btn-search {
  border: none;
  padding: 7px;
  background: #222;
  float: left;
  width: 30%;
  height: 42px;
}

#btn-search:before {
  font-family: FontAwesome;
  content: '\f002';
  font-size: 24px;
  color: #111;
}

#btn-search:hover {
  background: #fff;
}

/* dropdown */
/* ------------------------------ */
.dropdown {
  position: relative;
  z-index: 0;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  text-align: left;
}

.dark-scheme .btn-close {
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTS4yOTMuMjkzYTEgMSAwIDAxMS40MTQgMEw4IDYuNTg2IDE0LjI5My4yOTNhMSAxIDAgMTExLjQxNCAxLjQxNEw5LjQxNCA4bDYuMjkzIDYuMjkzYTEgMSAwIDAxLTEuNDE0IDEuNDE0TDggOS40MTRsLTYuMjkzIDYuMjkzYTEgMSAwIDAxLTEuNDE0LTEuNDE0TDYuNTg2IDggLjI5MyAxLjcwN2ExIDEgMCAwMTAtMS40MTR6Jy8+PC9zdmc+')
    center/1em auto no-repeat;
}

/* .dropdown select {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
} */

.rtl .dropdown {
  text-align: right;
}

.dropdown a,
.dropdown a:hover {
  text-decoration: none;
  outline: none;
  display: inline-block;
  pointer-events: none;
}

.dropdown a,
.header-light .dropdown a:hover {
  color: #333;
}

.dark-scheme .dropdown a,
.dark-scheme .header-light .dropdown a:hover {
  color: #ffffff;
}

.dropdown > a {
  display: inline-block;
  padding: 7px 12px 7px 12px;
  min-width: 140px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: bold;
}

.dark-scheme .dropdown > a {
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.dropdown.alt-1 {
  float: right;
}

.rtl .dropdown.alt-1 {
  float: left;
}

.dropdown.alt-1 > a {
  border: none;
}

.dropdown > a:after {
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f107';
  position: relative;
  float: right;
  margin-left: 10px;
}

.rtl .dropdown > a:after {
  float: left;
}

.dropdown ul,
.dropdown li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.dropdown ul {
  z-index: 10;
  position: absolute;
  min-width: 140px;
  display: none;
  right: 0;
  top: 25px;
  width: max-content;
  background: rgb(238 238 238 / 70%);
  cursor: pointer;
  border-radius: 4px;
}

.dark-scheme .dropdown ul {
  background: #21273e;
}

.dropdown li span {
  /* background: #ffffff; */
  display: block;
  padding: 5px 10px 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
  width: 100%;
  font-weight: 400;
}

/* .dark-scheme .dropdown li span {
    background: #21273e;
    border: solid 1px rgba(255, 255, 255, .1);
} */

/* .dark-scheme .dropdown li span:hover {
    background: var(--dark-scheme-color);
    color: #fff;
} */

/* .dropdown li span:hover {
    background: #eeeeee;
} */

.dropdown li.active {
  display: none;
}

.text-light .dropdown {
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.text-light .dropdown a {
  color: #ffffff;
}

.items_filter {
  font-size: 14px;
  margin-bottom: 30px;
}

.items_filter .dropdown {
  margin-right: 10px;
}

.dropdown.fullwidth,
.dropdown.fullwidth a,
.dropdown.fullwidth ul,
.dropdown.fullwidth li {
  width: 100%;
}

.dropdown.alt-2 {
  display: inline-block;
  position: absolute;
  padding-top: 3px;
}

.dropdown.alt-2 > a {
  font-size: 30px;
  border: none;
}

.dropdown.alt-2 li span {
  border: none;
}

.icon_padlock {
  margin-right: 10px;
}

.rtl .icon_padlock {
  margin-right: 0;
  margin-left: 10px;
}

/* ================================================== */

/* review */

/* ================================================== */

.de_review {
  text-align: center;
  min-height: 260px;
}

.de_review .de_nav {
  overflow: hidden;
  padding-left: 0;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: center;
}

.de_review .de_nav li {
  list-style: none;
  background: #eee;
  margin: 0 10px 0 10px;
  margin-bottom: 20px;
}

.de_review .de_nav li {
  display: inline-block;
}

.de_review .de_nav li img {
  width: 100px;
  height: auto;
}

.de_review .de_nav li span {
  padding: 8px 16px 8px 16px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: block;
  background: #f6f6f6;
  text-decoration: none;
  color: #646464;
  font-size: 13px;
  cursor: poInter;
}

.de_review blockquote {
  border: none;
  font-family: 'Inter', Georgia, 'Times New Roman', Times, serif;
}

.de_review .de_nav li span {
  border: 1px solid #202220;
  background: #222;
}

.de_review .de_nav li:first-child span {
  border-left: 1px solid #ddd;
}

.de_review .de_nav li:first-child span {
  border-left: 1px solid #202220;
}

.de_review .de_review_content {
  border: 1px solid #ddd;
  padding: 15px 20px 20px 20px;
  margin-top: -1px;
  background: #fff;
}

.de_review .de_review_content {
  border: 1px solid #202220;
  background: #27282b;
}

.de_review .de_nav li.active span {
  background: #fff;
  border-bottom: 1px solid #fff;
  color: #222;
  margin-bottom: -3px;
}

.separator {
  line-height: 0.5;
  text-align: center;
  margin: 30px 0 30px 0;
}

.separator span {
  display: inline-block;
  position: relative;
}

.separator span:before,
.separator span:after {
  content: '';
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  top: 5px;
  width: 50px;
}

.separator span:before {
  right: 100%;
  margin-right: 15px;
}

.separator span:after {
  left: 100%;
  margin-left: 15px;
}

.separator span i {
  font-size: 12px;
  padding: 0;
  margin: 0;
  float: none;
}

.de_light .separator span:before,
.de_light .separator span:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.text-light .separator span:before,
.text-light .separator span:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* --------------------------------------------------
     * plugin
     * --------------------------------------------------*/

/* --------------------------------------------------
* revolution slider setting
* --------------------------------------------------*/

#revolution-slider {
  background: #222;
}

.tp-caption {
  color: #fff;
  font-weight: 400;
}

.tp-caption.teaser {
  letter-spacing: 5px;
}

.tp-caption br {
  margin-bottom: 5px;
}

.tparrows {
  top: 50%;
}

.med {
  font-size: 24px;
  letter-spacing: -0.5px;
}

.med-white {
  color: #fff;
  font-size: 24px;
  letter-spacing: -0.5px;
}

.med-green {
  color: #1a8b49;
  font-size: 24px;
  letter-spacing: -0.5px;
}

.small-white {
  color: #fff;
  line-height: 1.7em;
}

.big-black {
  font-size: 52px;
  letter-spacing: -3px;
  color: #222;
}

.big-white {
  font-size: 16px;
  color: #fff;
  line-height: normal;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.very-big-white {
  font-family: var(--body-font);
  font-size: 64px;
  color: #fff;
  margin: 0;
  font-weight: 500;
}

.ultra-big-white {
  font-size: 120px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.ultra-big-black {
  font-size: 96px;
  font-weight: 400;
  color: #222;
  margin: 0;
  line-height: 72px;
}

a.btn-slider:hover {
  color: #222;
}

.tp-caption.separator {
  width: 100px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}

/* ================================================== */

/* isotope */

/* ================================================== */

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -ms-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

#filters {
  font-family: var(--body-font);
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
}

#filters li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  font-size: 11px;
  text-transform: uppercase;
}

#filters a {
  background: none;
  outline: none;
  color: #888;
  text-decoration: none;
  color: #888;
  padding: 5px 20px 5px 20px;
  border-radius: 3px;
  -moz-border-radius: 300px;
  -webkit-border-radius: 300px;
}

.text-light #filters a {
  color: rgba(255, 255, 255, 0.5);
}

#filters a:hover {
  color: #111;
}

#filters a.selected {
  color: #fff;
}

.text-light #filters a:hover {
  color: #fff;
}

.text-light #filters a.selected {
  color: #fff;
}

.text-light #filters a.selected {
  color: #fff;
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/* -------------------------------------------------- */

/* flex slider */

/* -------------------------------------------------- */

.flexslider {
  width: 100%;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0px;
}

.control-slider {
  display: inline-block;
  margin-right: -10px;
}

.prev-slider,
.next-slider {
  float: left;
  display: block;
  text-align: center;
  margin-top: 10px;
  cursor: poInter;
}

.prev-slider i,
.next-slider i {
  border: solid 1px #ccc;
  padding: 5px 10px 5px 10px;
}

.prev-slider i:hover,
.next-slider i:hover {
  color: #fff;
  border: solid 1px #333;
  background: #333;
}

.prev-slider {
  margin-right: -5px;
}

.project-carousel ul.slides,
.project-carousel-3-col ul.slides {
  margin-top: 10px;
  margin-bottom: 20px;
}

.logo-carousel .flex-direction-nav,
.project-carousel .flex-control-nav,
.project-carousel .flex-direction-nav,
.project-carousel-3-col .flex-control-nav,
.project-carousel-3-col .flex-direction-nav {
  display: none;
}

.logo-carousel.no-control .flex-control-nav {
  display: none;
}

.logo-carousel li img {
  width: auto;
}

/* flex slider - testi slider */

#testimonial-full {
  text-align: center;
  padding: 10px 0 10px 0;
  text-shadow: none;
}

#testimonial-full blockquote {
  color: #fff;
  border: none;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
}

#testimonial-full span {
  letter-spacing: 0;
  margin-top: 20px;
  text-transform: none;
}

.testi-slider .flex-control-nav {
  position: absolute;
  margin-top: -50px;
}

.testi-slider .flex-direction-nav {
  display: none;
}

/* Control Nav */

.testi-slider .flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.testi-slider .flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  display: inline;
}

.testi-slider .flex-control-paging li a {
  background: none;
  width: 8px;
  height: 8px;
  display: block;
  border: solid 1px #fff;
  cursor: poInter;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.testi-slider .flex-control-paging li a:hover {
  border: solid 1px #fff;
  background: #fff;
}

.testi-slider .flex-control-paging li a.flex-active {
  border: solid 1px #fff;
  cursor: default;
  background: #fff;
}

.size10 {
  font-size: 10px;
}

.size20 {
  font-size: 20px;
}

.size30 {
  font-size: 30px;
}

.size40 {
  font-size: 40px;
}

.size50 {
  font-size: 50px;
}

.size60 {
  font-size: 60px;
}

.size70 {
  font-size: 70px;
}

.typed-strings p {
  position: absolute;
}

.deco-big {
  display: block;
  font-size: 120px;
  font-family: 'Miama';
  margin-top: 48px;
  margin-left: -20px;
}

.deco-md {
  display: block;
  font-size: 120px;
  font-family: 'Miama';
  margin-top: 32px;
  margin-left: -20px;
}

.social-icons-sm i {
  color: #fff;
  margin: 0 5px 0 5px;
  border: solid 1px #555;
  width: 32px;
  height: 32px;
  padding: 8px;
  text-align: center;
  border-radius: 30px;
}

.social-icons-sm i:hover {
  color: #ffffff;
}

.arrowup {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #fff;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  margin-top: -30px;
}

.collection-detail-page .collection-image-holder {
  height: 400px;
  background: #e8e8e8;
}
.dark-scheme .collection-detail-page .collection-image-holder {
  background: rgb(238 238 238 / 10%);
}

.collection-detail-page .collection-image-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.arrowdown {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #ffffff;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
}

.arrowright {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid green;
}

.arrowleft {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid blue;
}

blockquote.very-big {
  border: none;
  font-family: var(--body-font);
  text-align: center;
  border: none;
  font-size: 32px;
  font-weight: 400;
  background: none;
  font-style: normal;
}

blockquote.very-big span {
  font-family: var(--body-font);
}

a,
img {
  outline: 0;
}

.z1 {
  z-index: 1;
}

.z10 {
  z-index: 10;
}

.rtl-content {
  text-align: right;
}

/* overwrite bootstrap */

.nav-pills .nav-link {
  font-weight: 500;
}

.de-preloader {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background: #ffffff;
  z-index: 5000;
}

.dark-scheme .de-preloader {
  background: var(--dark-scheme-color);
}

.dot-loader {
  width: 80px;
  height: 16.6666666667px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.dot-loader-dot {
  will-change: transform;
  height: 16.6666666667px;
  width: 16.6666666667px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  -webkit-animation: grow 0.5s ease-in-out infinite alternate;
  animation: grow 0.5s ease-in-out infinite alternate;
}

.dot-loader-dot.dot1 {
  left: 0;
  transform-origin: 100% 50%;
}

.dot-loader-dot.dot2 {
  left: 50%;
  transform: translateX(-50%) scale(1);
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.dot-loader-dot.dot3 {
  right: 0;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

/* -------------------------Line Preloader--------------------- */

.line-preloader {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 16.6666666667px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.line-preloader .p-line {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0);
}

.line-preloader .p-line:nth-child(1) {
  position: absolute;
  width: 25px;
  height: 25px;
  border-right: 2px solid gold;
  border-bottom: 2px solid gold;
  animation: anim4 600ms linear infinite;
}

.line-preloader .p-line:nth-child(2) {
  border-left: 2px solid gold;
  border-top: 2px solid gold;
  animation: anim4 1200ms linear infinite;
}

.line-preloader .p-line:nth-child(3) {
  position: absolute;
  width: 75px;
  height: 75px;
  border-right: 2px solid gold;
  border-top: 2px solid gold;
  animation: anim4 900ms linear infinite;
}

@keyframes anim4 {
  to {
    transform: rotate(360deg);
  }
}

/* ================================================== */

/* R E S P O N S I V E */

/* ================================================== */

@media (max-width: 1660px) {
  .container {
    padding: 0 30px;
  }
  .max_250 {
    max-width: 180px;
  }
  .height_loader {
    height: 31vw;
  }
}
@media (max-width: 1550px) {
  .trending_holder_section .col-sec {
    width: 25%;
  }
}

@media (max-width: 1600px) {
  .wide .container,
  .wide .container-lg,
  .wide .container-md,
  .wide .container-sm,
  .wide .container-xl {
    min-width: calc(100% - 40px);
  }

  .col-item-card {
    width: 25%;
    padding: 0 8px;
  }

  .container {
    padding: 0 30px;
  }

  .col-left-side {
    width: 320px;
  }

  .filter-tab h6 {
    font-size: 18px;
  }

  .filter-tab img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1440px) {
  /* .listing_grid{
    flex-direction: column;
  }
  .listing_grid .chart_holder{
    width: 100%;
    margin: 0 0 14px
  }
  .scroll_section {
    max-height: 325px;
    min-height: 325px;
} */
  .col-item-card {
    width: 33.33%;
  }
  .col-item-card.preview_card {
    width: 30.33%;
  }
  .col-item-card.card_width {
    width: 33.33%;
  }

  .activity-list li {
    width: 49.4%;
  }
}
@media (max-width: 1300px) {
  .listing_grid {
    flex-direction: column;
  }
  .listing_grid .chart_holder {
    width: 100%;
    margin: 0 0 14px;
    height: 46.8%;
  }
  .scroll_section {
    /* max-height: 301px;
    min-height: 301px; */
    max-height: 22.5vw;
    min-height: 22.5vw;
  }
}

@media (max-width: 1200px) {
  .de_form input[type='radio'] + label,
  .de_form input[type='checkbox'] + label {
    font-size: 14px;
  }

  .wide .container,
  .wide .container-lg,
  .wide .container-md,
  .wide .container-sm,
  .wide .container-xl {
    min-width: calc(100% - 40px);
  }

  .col-item-card {
    width: 50%;
  }
  .col-item-card.card_width {
    width: 50%;
  }

  /* .col-left-side {
        width: 25%;
    }
    .col-right-side {
        width: 75%;
    } */
}

@media (min-width: 980px) and (max-width: 1199px) {
  #mainmenu li a:after {
    margin-left: 15px;
  }

  .pf_full_width.gallery_border .item,
  .pf_full_width.gallery_border.pf_2_cols .item,
  .pf_full_width.gallery_border.pf_3_cols .item {
    float: left;
    width: 23.95%;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    min-width: 95%;
  }
}

/* -------------------------------------------------- 
    design for 768px
    /* ================================================== */

@media (min-width: 768px) and (max-width: 979px) {
  .slider_text {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1090px) {
  #quick_search {
    width: 250px !important;
  }
  .w_600 {
    width: 100%;
  }

  /* #quick_search {
        position: absolute;
        top: 30px;
    } */

  .nft__item_lg h3,
  .nft__item_lg .countdown-section {
    font-size: 20px;
  }

  .nft__item_lg h3 {
    margin: 15px 0 17px 0;
  }
}

@media only screen and (max-width: 992px) {
  .grid_col {
    grid-template-columns: 52px 1fr;
    grid-template-rows: 52px;
  }
  /* .col_holder {
    flex-direction: column;
  }

  .holder_col {
    width: 100%;
  } */

  .section-col {
    width: 50%;
  }

  .author_list.alt-2 {
    flex-direction: column;
  }

  .seller_col {
    width: 100%;
  }

  .mobile_hide {
    display: none !important;
  }

  /* .col-item-card {
        width: 100%;
    } */
  /* .col-left-side {
        width: 30%;
    }
    .col-right-side {
        width: 70%;
    } */
  .author_list.d-col-3:not(.alt-1) {
    -moz-column-count: 1;
    -moz-column-gap: 60px;
    -webkit-column-count: 1;
    -webkit-column-gap: 60px;
    column-count: 1;
    column-gap: 60px;
  }

  .dropdown.alt-2 > a {
    font-size: 24px;
  }

  #content {
    padding: 0 0 90px 0;
  }

  #nft-item-details {
    padding-top: 0;
  }

  .de-submenu {
    position: fixed;
  }

  .de_table .tr .td:nth-child(4),
  .de_table .tr .td:nth-child(5),
  .de_table .tr .td:nth-child(6),
  .de_table .tr .td:nth-child(7) {
    display: none;
  }

  .col-right {
    position: absolute;
    top: -82px;
    right: 60px;
    width: 140px;
  }

  .mb-sm-20 {
    margin-bottom: 20px;
  }

  .mb-sm-30 {
    margin-bottom: 30px;
  }

  .sm-mt-0 {
    margin-top: 0;
  }

  .sm-pt-0 {
    padding-top: 0;
  }

  .sm-pt-25 {
    padding-top: 25px;
  }

  .p-sm-30 {
    padding: 40px;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  header .header-col {
    position: absolute;
    top: 0;
  }

  header .header-col.mid {
    position: absolute;
    top: 70px;
    width: 100%;
    padding: 0 0 0 0;
  }

  .text-center-sm {
    text-align: center;
  }

  .sm-pt10 {
    padding-top: 10px;
  }

  #menu-btn {
    display: block;
    float: right;
    z-index: 1000;
    top: 0;
    right: 0;
    background: #333;
    margin-left: 0;
  }

  .inner-padding {
    padding: 40px;
  }

  .header-col-left,
  .header-col-right {
    padding-top: 16px;
  }

  .header-col-mid #mainmenu {
    position: absolute;
    top: 90px;
    width: 100%;
    padding: 0 0 0 0;
    left: 20px;
  }

  header {
    display: none;
  }

  header .info {
    display: none;
  }

  header.header-bg {
    background: rgba(0, 0, 0, 1);
  }

  header,
  header.fixed {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    margin-top: 0;
    height: auto;
    background: #111111;
    padding: 15px 0 15px 0;
    /* overflow: hidden; */
  }

  #de-sidebar {
    position: absolute;
    width: 100%;
    right: 1px;
  }

  header.side-header,
  .de_light header.side-header {
    position: absolute;
    display: block;
    height: 60px;
    top: 0;
    background: #000;
  }

  header.side-header #mainmenu {
    position: absolute;
    background: #000;
    margin: 0;
    top: 60px;
    left: 0;
    width: 100%;
  }

  header.side-header #logo .logo {
    display: none;
  }

  header.side-header #logo .logo-2 {
    display: inline-block;
    position: absolute;
    height: 30px;
    top: 15px;
    left: 40px;
  }

  header.side-header #menu-btn {
    position: absolute;
    top: 8px;
    right: 30px;
    height: 0;
    z-index: 2000;
    height: 35px;
  }

  header.side-header #mainmenu li {
    display: block;
  }

  header.side-header #mainmenu a,
  .de_light.de-navbar-left header #mainmenu > li > a {
    display: block;
    text-align: center;
    color: #fff;
  }

  .de_light.de-navbar-left header #mainmenu > li {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  header.side-header #mainmenu a:hover {
    background: none;
  }

  header.side-header #mainmenu a:hover {
    background: none;
  }

  header.side-header .social-icons-2 {
    display: none;
  }

  header.autoshow {
    height: auto;
    top: 0;
  }

  header.autoshow #mainmenu li a {
    background: none;
  }

  header.header-mobile-sticky {
    position: fixed;
  }

  #subheader {
    padding: 80px 0;
  }

  .de-navbar-left #subheader {
    padding: 0;
    margin: 0;
  }

  #subheader h1 {
    margin-top: 0;
  }

  /* #subheader span {
        display: none;
    } */

  .collection-detail-page .meta-info {
    align-items: flex-start;
    flex-direction: column;
  }

  #subheader .top-content {
    margin: 0 0 30px;
    width: 100%;
  }

  #subheader .crumb {
    display: none;
  }

  .de-navbar-left #subheader {
    padding-top: 70px;
  }

  .slider_text h1 {
    font-size: 32px;
  }

  .slider_text .description {
    display: none;
  }

  #logo,
  #logo .inner {
    vertical-align: middle;
    height: auto;
  }

  #domain-check .text,
  #domain-check .button {
    width: 100%;
  }

  .container-4 .de-team-list {
    width: 49.9%;
    float: left;
    position: relative;
  }

  #testimonial-full blockquote {
    padding: 20px;
  }

  nav {
    height: 0;
  }

  #mainmenu {
    float: none;
    z-index: 200;
    width: 100%;
    margin: 0;
    padding: 0;
    display: none;
  }

  #mainmenu ul {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  #mainmenu a {
    text-align: left;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #mainmenu li.has-child:after {
    display: none;
  }

  #mainmenu li li a,
  #mainmenu li li li a {
    padding-left: 0;
  }

  #mainmenu a:hover {
    background: #111;
  }

  #mainmenu a:hover {
    background: #111;
  }

  #mainmenu li ul {
    display: block;
    position: inherit;
    margin: 0;
    width: 100%;
  }

  #mainmenu li {
    border-bottom: solid 1px #eee;
    margin: 0;
    width: 100%;
    display: block;
  }

  #mainmenu li a:after {
    display: none;
  }

  #mainmenu li:last-child {
    margin-bottom: 30px;
  }

  #mainmenu li ul {
    border-top: solid 1px #eee;
    top: auto;
    width: auto;
    height: auto;
    position: inherit;
    visibility: visible;
    opacity: 1;
  }

  #mainmenu > li.menu-item-has-children > a {
    position: relative;
    padding-right: 15px;
  }

  #mainmenu > li.menu-item-has-children > a:after {
    content: '\f107';
    font-family: 'FontAwesome';
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }

  #mainmenu li ul a {
    width: 100%;
    background: none;
    border: none;
  }

  #mainmenu li ul li {
    border: none;
    padding-left: 40px;
  }

  #mainmenu li ul li a {
    display: block;
  }

  #mainmenu li ul li:last-child {
    border-bottom: none;
    margin: 0;
  }

  #mainmenu li ul li:last-child a {
    border-bottom: none;
  }

  #mainmenu li a {
    color: #eceff3;
  }

  #mainmenu li li a:hover {
    color: #fff;
  }

  header.header-mobile {
    overflow: inherit;
    position: relative;
  }

  header.header-mobile #mainmenu {
    float: none;
    z-index: 0;
    width: 100%;
    margin: 0;
    display: none;
    padding: 0;
    text-align: left;
    padding-right: 30px;
  }

  header.header-mobile #mainmenu ul {
    background: none;
  }

  header.header-mobile #mainmenu a {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  header.header-mobile #mainmenu a:hover {
    background: none;
  }

  header.header-mobile #mainmenu li ul {
    display: block;
    position: inherit;
    margin: 0;
    width: 100%;
  }

  header.header-mobile #mainmenu li {
    border-bottom: solid 1px #eee;
    margin: 0;
    width: 100%;
    display: block;
  }

  .dark-scheme header.header-mobile #mainmenu li {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul.mega ul {
    height: auto;
  }

  header.header-mobile #mainmenu li ul.mega .menu-content {
    background: #fff;
  }

  header.header-light.header-mobile #mainmenu li,
  header.header-light.header-mobile #mainmenu li ul li a {
    border-color: #eee;
  }

  header.header-dark.header-mobile {
    background: #222;
  }

  header.header-dark.header-mobile #mainmenu li {
    border-color: #333;
  }

  header.header-dark.header-mobile #mainmenu li a {
    color: #fff;
  }

  header.header-mobile #mainmenu li a:after {
    display: none;
  }

  header.header-mobile #mainmenu li:last-child {
    margin-bottom: 30px;
  }

  header.header-mobile #mainmenu li ul {
    border-top: none;
    top: auto;
  }

  header.header-mobile #mainmenu li ul a {
    width: 100%;
    background: none;
    border: none;
  }

  header.header-mobile #mainmenu li ul li {
    border: none;
    padding-left: 40px;
  }

  header #mainmenu li ul li:first-child {
    border-top: solid 1px #eee;
  }

  .dark-scheme header #mainmenu li ul li:first-child {
    border-top-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul li a {
    display: block;
    border-bottom: solid 1px #eee;
  }

  header.header-mobile #mainmenu li ul li:last-child {
    border-bottom: none;
    margin: 0;
  }

  header.header-mobile #mainmenu li ul li:last-child a {
    border-bottom: none;
  }

  header.header-mobile #mainmenu li a {
    color: #888;
  }

  .dark-scheme header.header-mobile #mainmenu li a {
    color: #ffffff;
  }

  header.header-mobile #mainmenu li a:hover {
    color: #333;
  }

  .dark-scheme header.header-mobile #mainmenu li a:hover {
    color: #fff;
  }

  header.header-mobile #mainmenu li li a:hover {
    color: #888;
  }

  header.header-mobile #mainmenu li ul {
    height: 0;
    overflow: hidden;
    position: relative;
    left: 0;
  }

  header.header-mobile #mainmenu li ul li ul li:last-child {
    border-bottom: solid 1px #eee;
  }

  header.header-mobile #mainmenu ul.mega > li:first-child {
    padding-top: 15px;
  }

  header.header-mobile #mainmenu ul.mega li:last-child {
    border-bottom: none;
  }

  header.header-mobile #mainmenu li ul li ul {
    margin-bottom: 10px;
  }

  header.header-mobile #mainmenu > li > span {
    width: 36px;
    height: 36px;
    background: url(../images/ui/arrow-down.png) center no-repeat;
    position: absolute;
    right: 0;
    margin-top: 10px;
    z-index: 1000;
    cursor: poInter;
  }

  .dark-scheme header.header-mobile #mainmenu > li > span {
    background: url(../images/ui/arrow-down-light.png) center no-repeat;
  }

  header.header-mobile #mainmenu li span.active {
    background: url(../images/ui/arrow-up.png) center no-repeat;
  }

  .dark-scheme header.header-mobile #mainmenu li span.active {
    background: url(../images/ui/arrow-up-light.png) center no-repeat;
  }

  header.header-mobile #mainmenu li > ul > li > span {
    width: 36px;
    height: 36px;
    background: url(../images/ui/arrow-down.png) center no-repeat;
    position: absolute;
    right: 0;
    margin-top: -45px;
    z-index: 1000;
    cursor: poInter;
  }

  header.header-mobile #mainmenu > li > ul > li > span.active {
    background: url(../images/ui/arrow-up.png) center no-repeat;
  }

  header.header-mobile #mainmenu li ul li a {
    border-bottom: solid 1px #eee;
  }

  .dark-scheme header.header-mobile #mainmenu li ul li a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul li:last-child > a {
    border-bottom: none;
  }

  header.header-mobile .h-phone {
    color: #202020;
  }

  header.header-light.header-mobile #mainmenu > li > span,
  header.header-light.header-mobile #mainmenu li > ul > li > span {
    background: url(../images/ui/arrow-down.png) center no-repeat;
  }

  header.header-light.header-mobile #mainmenu > li > span.active,
  header.header-light.header-mobile #mainmenu li > ul > li > span.active {
    background: url(../images/ui/arrow-up.png) center no-repeat;
  }

  #mainmenu li ul.mega {
    position: fixed;
    left: 0;
    width: 100%;
    margin-top: 30px;
    display: block;
  }

  #mainmenu li ul.mega > li {
    width: 100%;
  }

  #mainmenu li ul.mega > li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    left: 0;
    float: none;
    width: 100%;
  }

  #mainmenu li ul.mega .inner {
    background: #1a1c26;
  }

  #mainmenu ul.mega > li ul li {
    margin: 0;
    padding: 0;
    border: none;
  }

  #mainmenu li ul.mega .menu-content {
    background: #202020;
    padding: 30px;
  }

  #mainmenu li ul.mega ul li.menu-title {
    padding-left: 0;
    margin-left: 0;
  }

  /* header light */
  .header-light #mainmenu ul li a,
  .header-light #mainmenu ul li a:hover {
    color: #333;
  }

  .de_tab.tab_methods .de_nav {
    text-align: center;
  }

  .de_tab.tab_methods .de_nav li {
    margin: 5px;
    padding: 0;
  }

  .de_tab.tab_methods .de_nav li span {
    margin: 0;
    display: inline-block;
  }

  .de_tab.tab_methods .de_nav li .v-border {
    border: none;
  }

  .hidden-phone {
    display: none;
  }

  .grid_gallery .item {
    width: 100%;
  }

  .table [class*='col-'] {
    float: none;
    display: block;
  }

  .table-pricing thead tr,
  .table-pricing tbody tr {
    display: block;
    width: 100%;
  }

  .table-pricing thead tr {
    display: none;
  }

  .table-pricing tbody tr th,
  .table-pricing tbody tr td {
    text-align: left;
    display: block;
    width: 100%;
  }

  .table-pricing tbody tr td {
    padding: 10px 30px;
  }

  .table-pricing tbody tr td:nth-child(2) {
    padding-top: 30px;
  }

  .table-pricing tbody tr td:last-child {
    padding-bottom: 40px;
    text-align: center;
  }

  .table-pricing tbody tr th span,
  .table-pricing tbody tr td span {
    min-width: 50%;
  }

  .table-pricing tbody tr th .lbl,
  .table-pricing tbody tr td .lbl {
    display: inline-block;
    min-width: 50%;
  }

  .table-pricing tbody tr th {
    color: #ffffff;
    padding: 20px 30px;
  }

  .sm-hide {
    display: none;
  }

  .btn-rsvp {
    position: absolute;
  }

  .d-btn-close {
    position: relative;
  }

  .schedule-item .sc-info {
    padding-top: 20px;
  }

  .de_tab.tab_style_4 .de_nav li {
    min-width: 100px;
  }

  #mainmenu a span {
    display: none;
  }

  .pf_full_width.grid {
    margin-left: 1.5%;
  }

  .de_tab.tab_methods.style-2 .de_nav li.active .arrow {
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .table-custom-1 {
    position: relative;
    height: auto;
  }

  h1.very-big {
    font-size: 9vw;
    letter-spacing: 0;
  }

  .timeline > li > .timeline-panel {
    width: 100%;
  }

  .timeline-badge {
    display: none;
  }

  .timeline:before {
    background: none;
  }

  .tp-caption {
    padding-left: 40px;
    padding-right: 40px;
  }

  .author_list:not(.alt-1) {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
  }

  .subfooter .de-flex,
  .subfooter .de-flex-col {
    display: block;
  }

  .subfooter .social-icons {
    margin-top: 20px;
  }

  .subfooter span.copy {
    margin-top: 20px;
    display: block;
  }

  .dropdown {
    display: inline-block;
    margin-bottom: 10px;
  }

  .nft__item_lg h2 {
    margin-top: 40px;
  }

  #items-carousel-big .owl-prev,
  #items-carousel-big .owl-next {
    top: 30%;
  }

  #mainmenu li ul.mega .menu-content,
  #mainmenu ul.mega,
  #mainmenu ul.mega ul,
  #mainmenu ul:not(.mega) {
    border: none;
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  #mainmenu .mega .container {
    padding: 0;
    margin: 0;
  }
  .listing_grid .chart_holder {
    height: 43.8%;
  }
}

/* -------------------------------------------------- 
    custom for 320px & 480px 
    /* -------------------------------------------------- */

@media only screen and (min-width: 767px) {
  .cross_mobile_view {
    display: none;
  }

  .button_listing {
    display: none;
  }

  .col-left-side .filter-section.d-none {
    display: flex !important;
  }
}

@media only screen and (max-width: 768px) {
  .chart_listing_view{
    width: 100% !important;
  }
  .profile_name h4 {
    max-width: 220px;
  }
  .height_loader {
    height: 63vw;
  }
  .listing_grid .chart_holder {
    height: auto;
  }
  .col_holder {
    flex-direction: column;
  }
  .first_col {
    width: 100%;
  }

  .holder_col {
    width: 100%;
  }

  .grid_col {
    grid-template-columns: 62px 1fr;
    grid-template-rows: 62px;
  }
  .nft__item_info {
    padding: 0 5px;
  }
  .grid_col .nft__item_info {
    padding: 2px 5px 0;
  }
  .max_250 {
    max-width: 120px;
  }
  .form-border .field-set {
    flex-wrap: wrap;
  }

  .de-table.table-rank th,
  .de-table.table-rank td {
    padding: 0.5rem 0.5rem;
  }

  .col-item-card {
    width: 50%;
  }

  .col-left-side {
    width: 100%;
  }

  .col-left-side .filter-section.d-flex {
    display: flex !important;
    padding: 0 20px 20px;
    overflow-y: auto;
  }

  .col-left-side .filter-section {
    background: #fff;
    position: fixed;
    z-index: 9999;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    display: none !important;
  }

  .dark-scheme .col-left-side .filter-section {
    background: #070a15;
  }

  .cross_mobile_view {
    text-align: right;
    padding: 20px 0;
  }

  .dark-scheme .cross_mobile_view svg path {
    fill: #fff;
  }

  .button_listing {
    width: 50px;
    height: 50px;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dark-scheme .button_listing svg path {
    fill: #fff;
  }

  .dark-scheme .button_listing {
    border: 1px solid rgba(241, 245, 249, 0.05);
  }

  .dark-scheme .button_listing {
    background: rgba(241, 245, 249, 0.05);
  }

  .col-right-side {
    width: 100%;
  }

  .cart-aside {
    top: 10px;
    right: 10px;
    height: calc(((100% - 15px) - 0px) - 0px);
    width: 95%;
  }

  .mobile_row {
    flex-wrap: wrap-reverse !important;
  }

  #de-carousel h1 {
    font-size: 8vw;
  }

  /* .de-table.table-rank thead th:nth-child(5),
    .de-table.table-rank thead th:nth-child(6),
    .de-table.table-rank thead th:nth-child(7),
    .de-table.table-rank tbody td:nth-child(4),
    .de-table.table-rank tbody td:nth-child(5),
    .de-table.table-rank tbody td:nth-child(6) {
        display: none;
    } */

  .d_profile {
    display: block;
    margin-bottom: 24px;
  }
  .contract_holder {
    gap: 10px;
  }

  .d_profile .de-flex-col {
    display: block;
  }

  .profile_avatar {
    display: block;
  }

  .profile_avatar i {
    margin-top: -40px;
  }

  .profile_name {
    margin-left: 0;
    padding-top: 20px !important;
    margin-top: 0;
    display: block;
    width: 100%;
  }

  .profile_follow {
    margin-top: 40px;
    width: 100%;
  }

  .menu_side_area {
    margin-left: 10px;
    width: 100%;
    position: relative;
    right: 0;
    /* text-align: right; */
    display: block;
  }

  header .container {
    min-width: 100%;
  }

  .side-bg,
  .image-container {
    height: 400px;
    position: static;
    display: block;
  }

  .pricing-2-col .pricing-box,
  .pricing-3-col .pricing-box,
  .pricing-4-col .pricing-box,
  .pricing-5-col .pricing-box {
    width: 100%;
  }

  .container-4 .de-team-list {
    width: 100%;
    float: left;
    position: relative;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .feature-box-image,
  .pricing-box {
    margin-bottom: 30px;
  }

  #filters li.pull-right {
    float: none;
  }

  .pf_full_width .item {
    float: left;
    width: 49.9%;
  }

  .text-item {
    font-size: 48px;
    line-height: 48px;
  }

  .text-item i {
    font-size: 36px;
  }

  #de-sidebar {
    width: 100%;
    display: block;
    position: relative;
  }

  .gallery_border {
    margin-left: 0px;
  }

  .pf_full_width.gallery_border .item {
    width: 48.9%;
  }

  body.boxed {
    margin: 0px;
  }

  .schedule-item .sc-time {
    width: 100%;
    padding-bottom: 20px;
    float: none;
  }

  .schedule-item .sc-pic {
    width: 80px;
  }

  .schedule-item .sc-pic img {
    width: 60px;
  }

  .schedule-item .sc-name {
    padding-top: 5px;
  }

  .schedule-item .sc-info {
    float: none;
    display: block;
    width: 100%;
    clear: both;
    padding-top: 30px;
  }

  .schedule-item .sc-info h4 {
    margin-bottom: 10px;
  }

  .de_tab.tab_style_4 .de_nav {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .de_tab.tab_style_4 .de_nav li {
    min-width: 33.3%;
    width: 33.3%;
    border: none;
  }

  .de_tab.tab_style_4 .de_nav li:first-child,
  .de_tab.tab_style_4 .de_nav li:last-child {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .pf_full_width.grid {
    margin-left: 0.2%;
  }

  .pf_full_width.grid .item {
    width: 48.5%;
  }

  .display-table [class*='col-'] {
    display: block;
  }

  h1.big,
  h1_big {
    font-size: 8vw;
    line-height: 10vw;
  }

  .xs-hide {
    display: none;
  }

  .author_list:not(.alt-1) {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }

  .nft__item_lg h2 {
    font-size: 32px;
  }

  .nft__item_lg h3 {
    margin: 0;
  }

  .nft__item_lg h5 {
    display: none;
  }

  .nft__item_lg .d-attr {
    display: block;
  }

  .nft__item_lg .btn-main {
    display: block;
  }

  .nft__item_lg .d-attr > div:nth-child(2) {
    padding: 0;
    border: 0;
    margin: 0;
    margin-top: 30px;
  }

  .nft__item_lg .de_countdown {
    padding-top: 0;
  }

  #items-carousel-big .owl-prev,
  #items-carousel-big .owl-next {
    top: 22.5%;
  }

  #quick_search {
    display: none !important;
  }

  .dark-scheme .mobile_search #quick_search {
    color: #fff;
    border: 1.5px solid rgba(238, 241, 249, 0.2) !important;
  }

  .mobile_search #quick_search {
    display: block !important;
    margin-left: 0;
    width: 100% !important;
    height: 45px !important;
  }

  .custom_toastify_1 {
    max-width: 95%;
    width: 100%;
    margin-left: 10px;
    margin-top: 3px;
  }

  .custom_toastify {
    margin-bottom: 10px;
  }
}

/* -------------------------------------------------- 
    design for 480px
    /* -------------------------------------------------- */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .feature-box-image,
  .pricing-box {
    margin-bottom: 30px;
  }

  .timeline-panel {
    margin-bottom: 30px;
  }

  .timeline-pic {
    height: 300px;
  }

  #nft-item-details .image-autosize {
    position: relative;
    width: 100%;
  }
}

/* -------------------------------------------------- 
    design for 320px
    /* -------------------------------------------------- */

@media only screen and (max-width: 480px) {
  .transaction_activity_filter.mb-5 {
    margin-bottom: 0 !important;
  }
  .single_collection.md-w100 {
    width: 100%;
  }
  #nft-item-details .image-autosize {
    position: relative;
    width: 100%;
  }
  .height_loader {
    height: 310px;
  }
  .item_author.gap-3 {
    gap: 10px !important;
  }

  .referesh_meta_icon {
    width: 31px;
    height: 31px;
    padding: 4px 8px;
    right: 0;
    top: 4px;
    z-index: 10;
  }

  .pf_full_width .item {
    float: left;
    width: 100%;
  }

  .text-item {
    font-size: 32px;
    line-height: 32px;
  }

  .text-item i {
    font-size: 24px;
  }

  .pf_full_width.gallery_border .item {
    width: 100%;
  }

  h4.s2 span {
    display: block;
    margin: 0;
    padding: 0;
  }

  .h-phone {
    display: none;
  }

  .author_list {
    -moz-column-count: 1;
    -moz-column-gap: 20px;
    -webkit-column-count: 1;
    -webkit-column-gap: 20px;
    column-count: 1;
    column-gap: 20px;
  }

  #form_quick_search {
    margin-bottom: 0px;
  }

  header a.btn-main {
    padding: 7px 12px !important;
    border-radius: 2px !important;
    margin-top: -1px;
  }

  header a.btn-main i {
    display: inline-block;
  }

  header a.btn-main span {
    display: none;
  }

  #switch_scheme {
    /* display: none; */
  }
}

@media only screen and (max-width: 360px) {
  #topbar {
    display: none;
  }
}

/* section control */
@media only screen and (min-device-width: 768) and (max-device-width: 1024) and (orientation: portrait) {
  section {
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 1200px) {
  .activity-list li .containerStyle {
    width: 80px;
    height: 80px;
  }
  .list_view_grid ul li img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 992px) {
  #subheader {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
  }

  .activity-list li {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  section {
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

@media (min-width: 1200px) {
  .container-timeline ul {
    display: flex;
    padding-left: 0;
    padding-top: 20px;
  }

  .container-timeline ul li {
    flex: 1 1 0;
    border-left: 0;
    border-top: 1px solid green;
    padding: 50px 2em 0 0;
  }

  .container-timeline ul li::before {
    left: 0;
    top: -21px;
  }
}

.create-collection-options {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  position: absolute;

  .tile {
    height: 200px;
    width: 200px;
    background: #009999;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
}

.create-collection-options p {
  color: #000;
  font-weight: bold;
}

.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

/* connect-wallet-modal */

.connect-wallet-modal {
  padding: 50px 60px;
}

.img_holder {
  border-radius: 6px;
  background: #eaeaea;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
}

.dark-scheme .img_holder {
  background: rgba(255, 255, 255, 0.1);
}

.img_holder svg {
  margin-right: 0 !important;
}

.connect-wallet-modal .inner-content .modal-title {
  margin: 0 auto 20px !important;
  max-width: 400px;
}

.connect-wallet-modal .inner-content .title {
  font-size: 26px;
  line-height: 48px;
}

.connect-wallet-modal .inner-content p {
  margin: 0 auto 30px;
  max-width: 450px;
}

.connect-wallet-modal .wallet-modal-inner {
  border-radius: 8px;
  border: 1.236px solid rgba(229, 232, 240, 0.9);
  background: #fff;
  cursor: pointer;
  margin: 0 0 14px;
  padding: 12px 24px;
}

.dark-scheme .connect-wallet-modal .wallet-modal-inner {
  border: 1.236px solid rgba(241, 245, 249, 0.05);
  background: rgba(241, 245, 249, 0.05);
}

.wallet-modal-inner:hover {
  color: #000;
  border-radius: 8px;
  border: 1.236px solid rgba(229, 232, 240, 0.9);
  background: #f4f4f4;
}
.max-170 {
  max-width: 190px;
}

.dark-scheme .connect-wallet-modal .wallet-modal-inner:hover {
  color: #fff;
  border-radius: 8px;
  border: 1.236px solid rgba(229, 232, 240, 0.1);
  background: rgba(241, 245, 249, 0.1);
}

.connect-wallet-modal .wallet-modal-inner .connect-wallet {
  display: flex;
  align-items: center;
}

.connect-wallet-modal .wallet-modal-inner .wallet-icon img {
  margin: 0;
  width: 36px;
}

.connect-wallet-modal .wallet-modal-inner span {
  font-size: 18px;
  line-height: 24px;
  margin-left: 20px;
  color: #727273;
}

.modals-close-icon {
  /* background: #2f2f2f; */
  border: none;
  border-radius: 100%;
  height: 28px;
  /* padding: 6px; */
  position: absolute;
  right: 24px;
  top: 24px;
  width: 28px;
  z-index: 10;
  cursor: pointer;
}

.dark-scheme .modals-close-icon svg path {
  fill: #fff;
}

@media all and (max-width: 450px) {
  .connect-wallet-modal {
    padding: 40px 20px;
  }

  .connect-wallet-modal .wallet-modal-inner {
    margin: 0 0 15px;
    padding: 15px 20px;
  }

  .connect-wallet-modal .wallet-modal-inner .wallet-icon img {
    width: 25px;
  }

  .connect-wallet-modal .wallet-modal-inner span {
    font-size: 14px;
  }
}

.profile_container {
  position: relative;
}

.profile_container.banner_image {
  width: 100%;
  height: 400px;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark-scheme .profile_container.banner_image {
  background: rgba(241, 245, 249, 0.1);
}

.user_image_upload {
  margin: -60px 0 50px 60px;
}
.tooltip_owner {
  /* display: inline; */
  position: relative;
}
.tooltip_owner:hover:after {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #fff;
  color: #000;
  content: attr(title);
  font-size: 16px;
  padding: 13px;
  z-index: 10;
  top: -60px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 20px;
  box-shadow: 0px 0px 9.376522064208984px 0px #0000001a;
}
.profile_container.banner_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.image-profile-holder {
  width: 150px;
  height: 150px;
  position: relative;
}

.social_connection {
  /* border-radius: 6px; */
  /* background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 100px;
  background: #eaeaea !important;
}
.social_connection svg {
  width: 18px;
  height: 18px;
}
.dark-scheme .social_connection {
  background: #e7f4ff1a !important;
}

.collection_detail_social .social_connection {
  padding: 8px;
  border-radius: 100px;
  background: #eaeaea !important;
}

.dark-scheme .collection_detail_social .social_connection {
  background: #e7f4ff1a !important;
}

.collection_detail_social .social_connection svg path {
  fill: #000 !important;
}

.collection_detail_social .social_connection svg.twitter-ico {
  width: 13px;
  height: 13px;
}

.collection_detail_social .social_connection svg {
  width: 15px;
  height: 15px;
}

.dark-scheme .collection_detail_social .social_connection svg path {
  fill: #fff !important;
}

/* .collection_detail_social .social_connection svg{
  width: 17px;
  height: 17px;
} */

.disconnect-modal {
  padding: 10px 10px 20px;
}

.social_connection.banner_icon {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
}

.dark-scheme .social_connection.banner_icon {
  background: linear-gradient(94.51deg, #ffb800 -15.25%, #f35af6 122.2%);
}

.social_connection.banner_icon svg path {
  fill: #fff;
}

.social_input {
  position: absolute;
  top: 6px;
  left: 4px;
}

.social_input + input {
  padding-left: 50px !important;
}

/* .dark-scheme .social_connection {
  background: rgba(255, 255, 255, 0.1);
} */
.social_connection svg path {
  fill: #000;
}
.dark-scheme .social_connection svg path {
  fill: #fff;
}
.dark-scheme .social_connection svg.setting path {
  stroke: #fff;
}

/* Basic Rules */
.switch_toggle input {
  display: none;
}

.truncate_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 110px;
}

.switch_toggle {
  display: inline-block;
  width: 60px;
  height: 30px;
  /* margin:8px; */
  /* transform:translateY(50%); */
  position: relative;
}

/* Style Wired */
.slider_toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow:
    0 0 0 2px #777,
    0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}

.slider_toggle:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #777;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}

input:checked + .slider_toggle:before {
  transform: translateX(30px);
  background: #000;
}
.dark-scheme input:checked + .slider_toggle:before {
  transform: translateX(30px);
  background: #fff;
}
input:checked + .slider_toggle {
  box-shadow:
    0 0 0 2px #000,
    0 0 2px #000;
}
.dark-scheme input:checked + .slider_toggle {
  box-shadow:
    0 0 0 2px #fff,
    0 0 2px #fff;
}

.setting_text {
  font-size: 54px;
  line-height: 1.2;
  margin: 50px 0 8px;
}

.opacity-4 {
  opacity: 0.5;
}

.image-profile-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  background: #e8e8e8;
  border: 4px solid #fff;
}
.dark-scheme .image-profile-holder img {
  background: #181818;
  border: 4px solid #181818;
}

.text-right {
  text-align: right;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
}

.overlay-loader {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

.profile_container:hover .overlay {
  opacity: 1 !important;
}

#profile_banner:hover .overlay {
  opacity: 1 !important;
}

#profile_banner .overlay {
  border-radius: 0;
}

/* #profile_banner{
    padding: 80px 0;
  } */

.icon {
  color: white;
  font-size: 30px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.icon i {
  background: transparent !important;
}

.search-filter {
  gap: 20px;
  flex-wrap: wrap;
}

.tab-filter {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.tab-filter .tab-filter-holder {
  border-radius: 12px;
  color: #000;
  font-weight: 600;
  padding: 10px 30px 10px 12px;
  background-color: var(--white-smoke);
}

.close_filter {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tab-filter .clear {
  padding: 10px 16px !important;
}

.dark-scheme .tab-filter .tab-filter-holder {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.search-filter .auction {
  border-radius: 16px;
  background: var(--white-smoke);
  padding: 16px;
  flex-shrink: 0;
}

.search-filter .input-holder {
  flex: 1 1 0%;
}

.search-filter .input-holder input {
  height: 54px !important;
  border-radius: 12px !important;
  background: #fff;
  font-weight: 500;
}

.dark-scheme .search-filter .input-holder input {
  color: #fff !important;
  border: 1.289px solid rgba(238, 241, 249, 0.1);
  background: rgba(241, 245, 249, 0.05);
}

.search-filter select {
  font-weight: 500;
  color: #727273;
  height: 54px;
  width: 392px;
  border-width: 1px;
  border-style: solid;
  border-color: #ededed;
  padding: 0 12px;
  flex-shrink: 0;
  background: var(--white-smoke);
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
    94% / 18px no-repeat #fff;
}

.dark-scheme .search-filter select {
  color: #fff;
  border-color: var(--input-border);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
    94% / 18px no-repeat #232324;
}

.search-filter select:focus {
  outline: none;
  /* border: none; */
}

@media only screen and (max-width: 1200px) {
  .search-filter select {
    width: 270px;
  }
}

@media (max-width: 992px) {
  header.smaller {
    padding: 15px 0 15px 0;
  }
  .list_view_grid ul li:first-child {
    min-width: 200px;
  }
  .list_view_grid ul li {
    min-width: 120px;
    width: 120px;
  }
  .list_view_grid {
    width: 100%;
    overflow-x: auto;
  }

  .preview_card {
    display: none;
  }

  #section-category .col-md-2 {
    flex: 0 0 auto;
    width: 24.966667%;
  }

  /* #profile_banner{
        padding: 0 0 80px;
      } */
  .act_list_footer {
    flex-wrap: wrap;
  }

  .transaction-page {
    padding-top: 20px !important;
    text-align: left !important;
  }
}

@media (max-width: 768px) {
  .dark-scheme .table-responsive .table_div,
  .table-responsive .table_div {
    border: none;
  }
  .gap-100 {
    gap: 16px;
  }
  .arrows_left_container,
  .arrows_right_container {
    display: none;
  }
  #section-category .col-md-2 {
    width: 33.333333%;
  }

  .tab-filter {
    margin-top: 12px;
  }

  .profile_container:hover .overlay {
    opacity: 0 !important;
  }

  #profile_banner:hover .overlay {
    opacity: 0 !important;
  }
  .sections_tab p {
    font-size: 15px;
    padding: 6px 15px;
  }
  .sections_tab {
    margin: 0 auto 12px;
  }
  .sections_tab.detail_tab {
    margin: 0;
  }
  .sections_tab.detail_tab.ml-auto {
    margin-left: inherit !important;
    margin-bottom: 24px;
  }
}

@media (min-width: 600px) {
  .mobile_bazaa_logo {
    display: none;
  }

  .mobile_meta {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .no_graph_data {
    height: auto;
  }
  .on_sale_chart .h-100{
    height: calc(100% - 60px) !important;
  }
  .choose_type{
    flex-direction: column;
    text-align: center;
  }
  .nft__item_extra_hover {
    width: 85%;
    left: 7.5%;
    bottom: 34%;
    gap: 6px;
  }
  .nft__item_extra_hover button {
    height: 36px;
  }
  .btn-label {
    display: inline-flex;
    height: 36px;
  }
  #loadmore.btn-main {
    padding: 14px 24px;
    font-size: 15px;
  }
  .max-170 {
    max-width: 85px;
  }
  .detail_page_table {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .grid_col {
    grid-template-columns: 1fr;
    grid-template-rows: 40px;
    margin-top: -30px;
    position: relative;
  }
  .grid_col img {
    width: 40px;
  }
  .explore_page_social {
    right: 10px;
    bottom: 10px;
  }

  .disconnect-flex {
    flex-direction: column;
    gap: 10px !important;
  }

  .disconnect-flex button {
    width: 100% !important;
  }

  .mb_30 {
    margin-bottom: 0;
  }

  .token {
    margin-bottom: 20px !important;
  }

  .desktop_meta {
    display: none !important;
  }

  .mobile_meta {
    margin-top: 40px;
  }

  .slider_holder {
    margin-top: 50px;
  }

  .mobile_meta .refresh-meta {
    margin-bottom: 30px;
  }

  #nft-item-details .item_author .title_text a {
    font-size: 16px;
    padding-right: 40px;
  }

  .item_info.detail_left_side .d-flex.gap-4.mb-4 {
    flex-wrap: wrap;
  }

  .row_reverse {
    flex-direction: column-reverse;
  }

  .nft-image-wrapper.mobile_nft_wrapper {
    height: 400px !important;
  }

  .left_side {
    margin-bottom: 10px !important;
  }

  .detail_left_side {
    height: auto;
  }
  .item_info {
    padding-left: 0;
  }

  .item_author_image {
    width: 40px !important;
    height: 40px !important;
  }

  #nft-item-details {
    margin-top: 40px;
  }

  .item_info h2 {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 30px;
  }

  .table tr td {
    font-size: 14px;
  }

  .table tr th {
    font-size: 15px;
  }

  .collection-detail-page .description_overflow::-webkit-scrollbar,
  .table-responsive::-webkit-scrollbar,
  .detail_page_description::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .de-table {
    margin-bottom: 6px;
  }

  .collection-detail-page .collection-image-holder {
    height: 120px;
  }

  .user_image_upload {
    margin: -60px 0 50px 30px;
  }

  .col-item-card {
    padding: 0 6px;
  }

  .profile_container.banner_image {
    height: 140px;
  }

  #profile_banner {
    min-height: 140px;
  }

  .image-profile-holder {
    width: 120px;
    height: 120px;
  }

  .transaction-tag {
    padding: 2px 20px;
  }

  .activity-list li .containerStyle {
    width: 160px;
    height: 160px;
  }

  .setting_text {
    font-size: 34px;
    margin: 50px 0 20px;
  }

  .nft__item_wrap.collection_img_wrap,
  .nft__item_wrap {
    height: 150px;
    margin-bottom: 12px;
  }
  .nft__item_wrap.trending_colection {
    height: 130px;
  }

  .nft__item h4 {
    margin-bottom: 10px;
  }

  .filter-tab img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .filter-tab h6 {
    font-size: 16px;
  }

  .nft__item_price {
    font-size: 12px;
  }
  .floor-item {
    font-size: 10px;
    flex-direction: column;
  }
  .floor-item .flex-column {
    flex-direction: initial !important;
  }
  .floor-item .flex-column.ml-auto {
    margin-left: initial !important;
  }

  .floor-item span.d-flex.align-items-center.fw-bold {
    margin-left: auto;
  }
  .btn-wallet {
    font-size: 14px;
  }

  .floor-item svg,
  .nft__item_price svg {
    width: 16px;
    height: 16px;
  }

  .floor-item span.text-gray {
    font-size: 12px;
    padding-right: 3px;
  }

  .my_collection .nft__item_wrap {
    height: 145px;
  }

  .collection_filter {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 12px;
    margin-bottom: 10px;
  }

  .filter-tab:hover {
    transform: translateY(0);
  }

  .collection_filter::-webkit-scrollbar {
    display: none;
  }

  .collection_filter {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .collection_filter .filter-tab {
    padding: 11px;
  }

  .d-flex.flex-column.w-50.mb-30 {
    width: 100% !important;
  }

  .mb-40,
  .mb-10 {
    margin-bottom: 30px;
  }

  /* .modal-dialog.w-600{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 96%;
    } */
  .gap-16 {
    gap: 30px;
  }

  .col_holder_1.d-flex.gap-3.mb-3 {
    margin-bottom: 20px !important;
    gap: 30px !important;
  }

  h5.text-danger.fw-normal.m-0.mt-2.p-0 {
    min-height: inherit;
  }

  button#Create-Now {
    width: 100%;
  }

  .first_col {
    width: 100%;
  }

  header.smaller {
    padding: 2px 0 6px 0;
  }

  header,
  header.fixed {
    padding: 2px 0 8px 0;
  }

  /* .dark-scheme .mobile_bazaa_logo svg path {
    fill: #fff;
  } */

  .desktop_bazaa_logo {
    display: none;
  }

  .single_collection_holder {
    padding-bottom: 0;
  }

  .top-header-holder {
    margin: 30px 0 15px;
    font-size: 40px;
  }
  .top-header-holder.grid_view {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start !important;
    margin-bottom: 18px !important;
  }
  .listing_list:hover,.dark-scheme .listing_list:hover{
    background: inherit;
  }

  .top-header-holder h2 {
    font-size: 30px;
  }
  .transfer_nft_btn{
    position: fixed;
    bottom: 4px;
    left: 0;
    z-index: 100;
    width: 96%;
    right: 2%;
  }
  .col_holder_1 {
    flex-direction: column;
  }

  .col_holder_1.gap_last {
    gap: 20px;
  }

  .col_holder_1 .w-50 {
    width: 100% !important;
  }

  #section-trending,
  #section-collections,
  #section-category,
  #section-news {
    padding: 40px 0 0;
  }

  .mb-35 {
    margin-bottom: 30px;
  }

  .category-holder {
    margin: 0;
  }

  .section-col {
    padding: 0;
  }

  .section-col {
    width: 100%;
  }

  .activity-list li .containerStyle {
    margin-top: 35px;
  }

  .see_more_text {
    font-size: 14px;
  }

  .react-multiple-carousel__arrow {
    opacity: 0 !important;
  }

  #subheader .top-content img {
    width: 70px;
    height: 70px;
  }

  .text-heading-sm {
    font-size: 14px;
  }

  .text-md {
    font-size: 12px;
  }

  .d-flex.justify-content-start.gap-4.flex-wrap {
    gap: 0.8rem !important;
  }

  .collection-detail-page .description {
    font-size: 14px;
  }

  .opt-create {
    min-width: 96%;
    padding: 50px 40px;
    margin: 1% 1% 6%;
  }

  .opt-create h3 {
    margin-top: 0;
  }

  .opt-create svg {
    width: 70px;
    height: 90px;
  }

  .de_tab.tab_simple .de_nav li {
    min-width: 100px;
    margin-right: 5px;
  }
  .de_tab.tab_simple .de_nav li span {
    font-size: 17px;
    padding: 9px 22px;
  }

  .nft_pic.mod-b .nft_pic_info .nft_pic_by {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .de-menu-notification {
    width: 36px;
    height: 36px;
  }

  #switch_scheme {
    width: 36px;
    height: 36px;
  }

  .desktop_view#switch_scheme {
    display: none;
  }

  #menu-btn {
    margin-left: 10px;
  }

  .de-login-menu.mr10 {
    margin-right: 0;
  }

  header.header-light .logo-2 {
    max-width: 30px;
    width: 100%;
  }

  .author_list_pp {
    width: 55px;
    height: 55px;
  }

  .counter_seller {
    max-width: 60px;
  }

  .table_top_content .first_col {
    width: 60px;
  }

  .table_top_content {
    padding: 0 12px 15px 5px;
  }

  .author_list.alt-2 .author_list_info {
    padding-left: 10px;
  }

  .author_list.alt-2 .author_list_info .user_name {
    font-size: 14px;
    max-width: 120px;
  }

  .table_top_content {
    font-size: 14px;
  }

  .counter_seller span {
    width: 32px;
    height: 32px;
  }

  .collection-detail-page .meta-info {
    height: auto;
  }

  .collection-detail-page .meta-info ul {
    flex-direction: column;
    gap: 0;
  }

  .collection-detail-page .meta-info ul li {
    font-size: 14px;
  }

  #subheader .top-content {
    margin: 30px 0 20px;
  }

  .activity-list li {
    flex-direction: column;
    gap: 20px;
  }

  .act_list_text {
    padding-left: 0;
  }

  .search-filter {
    flex-direction: column;
  }

  .search-filter .input-holder {
    width: 100%;
  }

  .search-filter select {
    width: 100%;
  }

  .search-filter .select-holder {
    width: 100%;
  }

  #section-category .col-md-2 {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .activity-list li a.ml-auto {
    /* margin-left: inherit !important; */
    text-align: center;
  }
}

.filter-section .left-side-col {
  flex-shrink: 0;
  width: 300px;
}

.radius_16 {
  border-radius: 16px;
}

.filter-section .left-side-col button {
  min-width: max-content;
  margin-right: 10px;
  margin-bottom: 10px;
}

.filter-section .left-side-col .collapse-detail {
  background: var(--white-smoke);
}

.filter-section .right-side-col {
  flex: 1 1 0%;
}

.Collapsible {
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 1px;
}

.dark-scheme .filter-section .Collapsible {
  border: 1px solid rgba(238, 241, 249, 0.1);
}

.Collapsible__contentInner {
  padding: 0 10px 13px;
  border-top: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dark-scheme .Collapsible__contentInner input {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(155, 155, 155, 0.3);
}

.pl-10 {
  padding-left: 10px;
}

.pl-5 {
  padding-left: 5px;
}

.Collapsible__trigger {
  display: block;
  font-weight: 600;
  font-size: 21px;
  text-decoration: none;
  position: relative;
  padding: 20px 20px;
  /* background: var(--white-smoke); */
  color: var(--black);
  /* border-radius: 16px; */

  &:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 15px;
    top: 20px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.dark-scheme .Collapsible__trigger {
  color: var(--white);
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

/* profile badges */
.profile-badges {
  display: flex;
  gap: 60px;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .profile-badges {
    padding-bottom: 10px;
  }

  .w-600 {
    width: 96%;
  }
  .profile_tab_filter {
    flex-direction: column-reverse;
  }

  .w-560,
  .w-500 {
    width: 96%;
  }

  .dark-scheme .Collapsible__trigger:hover {
    background: inherit;
  }
}

.profile-badges .badge {
  text-align: left;
}

.profile-badges .badge .value {
  gap: 8px;
  margin-bottom: 8px;
}

.profile-badges .badge h5 {
  font-size: 24px;
}

.profile-badges .badge h6 {
  font-size: 14px;
  color: var(--body-txt-clr);
}

.trading-image-holder {
  height: 80px;
  width: 80px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.truncate_text {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate_text.max_220 {
  width: 218px;
}

.sale-confirmation-backdrop {
  z-index: 1060 !important;
  opacity: 0.8 !important;
}

.filters-holder .filter-main-bg {
  background: #eaeaea;
  border-radius: 6px;
  padding: 8px;
}

.dark-scheme .filters-holder .filter-main-bg {
  background: rgba(231, 244, 255, 0.1);
}

.filters-holder .custom-btn.transparent {
  background: transparent;
}

.dark-scheme .filters-holder .custom-btn.transparent {
  color: #fff;
}

.filters-holder .custom-btn {
  border: 1.5px solid transparent;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  padding: 0 18px;
  color: #000;
  height: 40px;
  border-radius: 4px;
  text-transform: lowercase;
  transition: all 0.25s;
}

.filters-holder .custom-btn:hover {
  background: #d8d8d8;
}
.filters-holder .custom-btn span.text- {
  text-transform: capitalize;
}

.dark-scheme .filters-holder .custom-btn:hover {
  background: rgba(231, 244, 255, 0.1);
  color: #fff;
}

.filters-holder .custom-btn.active {
  background: rgb(0 0 0 / 75%);
  box-shadow: none;
  color: #fff;
  cursor: default;
}

.dark-scheme .filters-holder .custom-btn.active {
  background: rgb(255 255 255 / 75%);
}

.dark-scheme .filters-holder .custom-btn.active {
  color: #000;
}

.filters-holder .custom-btn:not(:last-child) {
  margin-right: 10px;
}

.top-image-holder {
  width: 80px;
  height: 80px;
  flex-basis: 80px;
}

.top-image-holder img {
  max-width: 95%;
  max-height: 95%;
}

@media (max-width: 1550px) {
  .author_list.alt-2 .author_list_info .user_name {
    max-width: 120px;
  }
}

@media (max-width: 1199px) {
  #section-hero .carousel-control-prev-icon,
  #section-hero .carousel-control-next-icon {
    width: 35px;
  }

  .nft_pic.mod-b .nft_pic_info .carousel-container {
    gap: 50px;
  }

  .nft_pic.mod-b .nft_pic_info .nft_pic_title {
    font-size: 32px;
    line-height: 1.2;
    margin: 0 0 10px;
  }

  .nft_pic.mod-b .nft_pic_info .description {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 20px;
  }

  .post-text h4 a {
    font-size: 24px;
  }

  .author_list.alt-2 li {
    width: 33.33%;
  }

  .trending_holder_section .col-sec {
    width: 33.33%;
  }
}

@media (max-width: 992px) {
  .author_list.alt-2 li {
    width: 50%;
  }

  .trending_holder_section .col-sec {
    width: 50%;
    padding: 0 5px;
  }

  .author_list.alt-2 .author_list_info .user_name {
    max-width: 210px;
  }

  .w-65 {
    width: auto;
  }
}

@media (max-width: 768px) {
  .text-heading-md {
    font-size: 22px;
  }

  .filter-section {
    margin: 0 0 24px;
  }

  .top-content {
    flex-direction: column;
  }

  .nft_pic.mod-b .nft_pic_info .carousel-container {
    flex-direction: column;
    gap: 30px;
  }

  .nft_pic.mod-b .nft_pic_info .carousel-container .image-holder {
    width: 420px;
    height: 420px;
  }

  /* .nft_pic .nft_pic_wrap {
        height: 800px;
    } */
  .author_list.alt-2 .author_list_info .user_name {
    max-width: 56vw;
  }

  /* .trending_holder_section .col-sec{
        width: 100%;
    } */
  .filters-holder {
    flex-direction: column;
    align-items: initial !important;
  }

  .filters-holder .filter-main-bg {
    width: max-content;
    margin-left: initial !important;
    margin-bottom: 20px;
  }

  #section-trending .filters-holder .filter-main-bg {
    margin: 0 auto 10px !important;
  }
  #section-collections .filters-holder .filter-main-bg {
    margin: 0 auto 10px !important;
  }

  .author_list.alt-2 li {
    width: 100%;
    padding: 10px 0;
  }

  .search-filter select {
    width: 250px;
  }
  .scroll_section {
    max-height: 300px;
    min-height: 300px;
  }
}

@media (max-width: 600px) {
  .transfer_nft_holder {
    padding-bottom: 0;
    border-bottom: none;
}
  .column_width{
    width: 100%;
  }
  .transfer_nft input {
    width: 100%;
}
  .send_nft_btn{
    position: fixed;
    bottom: 6px;
    width: 96%;
    left: 0;
    right: 2%;
    z-index: 20;
  }
  .seperator{
    display: none;
  }
  .filters-holder .custom-btn {
    font-size: 15px;
  }
  .d-create-file {
    padding: 30px 16px;
    border-radius: 10px;
  }
  .scroll_owner {
    padding: 6px 5px 6px 0;
  }
  .btn-main.analytics_btn {
    height: 46px;
  }
  .list_col {
    padding: 10px;
  }

  /* .d_file_holder {
    height: auto;
  } */

  .single_collection_holder .d-create-file .video-uploader video,
  .single_collection_holder .d-create-file .video-uploader,
  .single_collection_holder .d-create-file img,
  .single_collection_holder .d-create-file .glb_format {
    height: 300px !important;
  }

  .top-content img {
    max-width: 120px;
    min-width: 120px;
    max-height: 120px;
    min-height: 120px;
    margin-top: -50px;
  }

  .search-filter select {
    width: 100%;
  }

  .nft_pic.mod-b .nft_pic_info .carousel-container .image-holder {
    width: 290px;
    height: 290px;
  }

  .nft_pic .nft_pic_wrap {
    height: 600px;
  }

  .filters-holder .custom-btn {
    padding: 0 14px;
  }

  .filters-holder .filter-main-bg {
    padding: 3px;
  }

  .no-item-display {
    font-size: 16px;
  }

  .post-text h4 a {
    font-size: 20px;
  }

  .post-content p {
    font-size: 16px;
  }

  .post-text .p-date {
    font-size: 12px;
  }

  .post-image {
    height: 200px;
  }

  .footer-logo {
    max-width: 176px;
    margin: 0 auto 30px;
  }

  svg.footer-logo path {
    fill: #fff;
  }
}

/* content pages style */

.content-page {
  padding-block: 100px 150px;
}

.content-page .container {
  max-width: 1360px !important;
  margin: 0 auto;
  padding: 0 15px;
}

.content-page .main-title {
  font-size: 56px;
  letter-spacing: -0.03em;
  line-height: 60px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}

.content-page p,
.content-page li {
  font-size: 18px;
  letter-spacing: -0.03em;
  line-height: 24px;
  color: #0d0c22;
}

.dark-scheme .content-page p,
.dark-scheme .content-page li {
  color: #ffffff;
}

.content-page .highlight {
  text-decoration: underline;
  /* color: rgb(255 184 0 / 80%); */
  color: #d63384;
}

.content-page .highlight:hover {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .content-page {
    padding-block: 120px 50px;
  }

  .content-page .main-title {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
  }

  .content-page p,
  .content-page li {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 767px) {
  .content-page {
    padding-block: 50px;
  }

  .content-page .main-title {
    font-size: 40px;
    line-height: 42px;
  }
}

.min-w141 {
  min-width: 141px;
}

.not-found {
  height: 100%;
}

@media (max-width: 767px) {
  .not-found {
    height: auto;
    padding-top: 10px;
  }
}

.grid-col {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 1430px) {
  .grid-col {
    grid-template-columns: repeat(1, 1fr);
  }
}

.edit_setting {
  gap: 10px;
}

.disable {
  opacity: 0.5;
}

#footer {
    background: var(--white-smoke);
    padding: 100px 0 40px;
    position: relative;
    color: var(--verified-black);
}
.dark-scheme #footer {
    background: rgb(19 19 19 / 40%);
    color: var(--white);
}

#footer .footer-content {
    max-width: 1660px;
    margin: 0 auto;
    /* padding: 0 75px; */
    position: relative;
}

#footer .footer-content .footer {
    display: flex;
    gap: 50px;
    justify-content: space-between;
}

#footer .footer-content .footer .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}

#footer .footer-content .footer .footer-left .footer-left-inner {
    display: flex;
}

#footer .footer-content .footer .footer-left .footer-left-inner .footer-col {
    flex: 1 1;
}

#footer strong {
    margin: 0 0 28px;
    font-size: 22px;
    display: inline-block;
}

#footer .footer-content .footer .footer-left .footer-left-inner ul li {
    margin: 0 0 16px;
    line-height: normal;
}

#footer li a {
    color: var(--verified-black);
}
.dark-scheme #footer li a {
    color: var(--white);
}

#footer .footer-content .footer .footer-left img {
    max-width: 48px;
}
.footer-logo{
    max-width: 246px;
    width: 100%;
    height: 100%;
    max-height: 246px;
    margin: 0 auto 40px;
}
/* .dark-scheme svg.footer-logo path{
    fill: #fff;
} */

#footer .footer-content .footer .footer-left span.title {
    font-size: 14px;
    margin-left: 10px;
    max-width: 780px;
    opacity: 0.7;
}

#footer .footer-content .footer .footer-right {
    /* width: 30%; */
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
}

#footer .footer-content .footer .footer-right .footer-right-inner {
    display: flex;
    flex-direction: column;
}

#footer .footer-content .footer .footer-right .footer-right-inner .right-inner-content {
    /* margin-bottom: 120px; */
    max-width: 700px;
    width: 100%;
    margin: 0 auto 40px;
    text-align: center;
}

#footer .footer-content .footer .footer-right .footer-right-inner .input-holder {
    position: relative;
    max-width: 500px;
    margin: 0 auto 30px;
    /* pointer-events: none; */
    /* opacity: 0.5; */
}

.input-holder-form .spinner-border,
.gaming-main-area .spinner-border {
    border: 0.2em solid var(--accent-hover) !important;
    border-right-color: transparent !important;
}

.input-holder input {
    display: block;
    width: 100% !important;
    height: 54px;
    padding-left: 0.95rem;
    padding-right: 0.95rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1rem;
    color: #121212 !important;
    background: padding-box transparent;
    border-width: 1px;
    border-style: solid;
    outline: none;
    border-color: rgba(155, 155, 155, 0.30);
    border-image: initial;
    border-radius: 100px !important;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.dark-scheme #footer .input-holder input {
    color: var(--white) !important;
}
.dark-scheme #footer .input-holder input::placeholder {
    color: #eee !important;
}

#footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn {
    align-items: center;
    background: var(--verified-bg-black);
    border-radius: 50px;
    color: var(--black);
    border: none;
    cursor: pointer !important;
    display: flex;
    font-size: 12px;
    justify-content: center;
    position: absolute;
    z-index: 0;
    right: 5px;
    top: 2px;
    width: 50px;
    height: 50px;
}

.input-holder-btn .spinner-border{
    color: var(--white);
}
.dark-scheme .input-holder-btn .spinner-border{
    color: var(--black);
}
#footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn:hover {
    background: rgb(255 184 0);
}
#footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn svg .path-color{
    fill: transparent; 
}
.dark-scheme #footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn {
    background: var(--white);
}
/* .dark-scheme #footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn svg .path-color {
    fill: var(--white);
} */
.dark-scheme #footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn:hover {
    background: rgb(255 184 0);
}
.dark-scheme #footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn:hover svg path {
    fill: #fff;
}
.dark-scheme #footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn svg path {
    fill: var(--black);
}

#footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn img {
    width: auto;
    height: auto;
}

.footer-social-icons {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    align-items: center;
}
 .footer-social-icons li:first-child {
    margin-left: 0;
}
 .footer-social-icons li:last-child {
    margin-right: 0;
}
 .footer-social-icons li {
    margin-left: 15px;
    /* margin-right: 15px; */
}
 .footer-social-icons li a {
    /* background: var(--orochimaru); */
    background: #D9D9D9;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    /* opacity: 0.5; */
    /* pointer-events: none; */
}
.dark-scheme .footer-social-icons li a {
    background: #212121;
}
.dark-scheme .footer-social-icons.contact_support li a {
    background: #333131;
}
.dark-scheme .footer-social-icons li a svg path{
    fill: #fff;
}
 .footer-social-icons li a:hover {
    background: #c4c3c3;
}
.dark-scheme .footer-social-icons li a:hover {
    background: rgba(241, 245, 249, 0.1);
}

.cgoodp {
    width: 20px;
    max-height: 20px;
}

#footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons {
    display: flex;
    justify-content: end;
}

#footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons a:first-child {
    margin-right: 15px;
}

.btn-footer .app-icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
}
.dark-scheme .btn-footer .app-icon svg, .dark-scheme .btn-footer .app-icon svg path {
    fill: #fff;
}

#footer .footer-content .footer .footer-right .footer-right-inner .mobile-footer-text {
    display: none;
}

.btn-footer {
    align-items: center;
    background: transparent;
    border: 2px solid var(--verified-black);
    border-radius: 100px;
    color: var(--verified-black);
    height: 54px;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    max-width: 180px;
    padding: 15px;
    text-transform: none;
    transition: all 0.3s ease;
    width: 100%;
}
.dark-scheme .btn-footer {
    border: 2px solid var(--white);
    color: var(--white);
}
.btn-footer:hover {
    color: var(--accent-hover) !important;
}

#footer li a:hover {
    color: var(--accent-hover) !important;
}

#footer:after {
    background: hsla(0, 0%, 100%, 0.1);
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
}

/* .user-guidelines-footer{
    background: var(--white-smoke);
} */

.user-guidelines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
/* .dark-scheme .user-guidelines-footer {
    background: #000027;
} */

.user-guidelines li {
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-guidelines li a:hover {
    color: var(--accent-hover) !important;
}

.user-guidelines li:first-child {
    margin-left: 0;
}

.user-guidelines li a {
    color: var(--verified-black);
}
.dark-scheme .user-guidelines li a {
    color: var(--white);
}
.dark-scheme .user-guidelines svg, .dark-scheme .user-guidelines svg .text-path {
    fill: #fff;
}


@media (max-width: 1550px) {
    #footer .footer-content .footer .footer-left {
        width: 67%;
    }

    /* #footer .footer-content .footer .footer-right {
        width: 33%;
    } */
}

/* @media (max-width: 1280px) {
    #footer .footer-content .footer .footer-right {
        width: 40%;
    }
} */

@media(max-width: 992px) {
    #footer .footer-content {
        padding: 0 15px;
    }

    #footer .footer-content .footer {
        flex-direction: column-reverse;
        gap: 0;
    }

    #footer .footer-content .footer .footer-right {
        width: 100%;
        text-align: center;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .input-holder {
        margin: 0 auto 30px;
        max-width: 400px;
    }

    .footer-social-icons {
        justify-content: center;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .footer-social-icons li {
        margin-left: 7.5px;
        margin-right: 7.5px;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .mobile-footer-text {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin-top: 20px;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .mobile-footer-text .title {
        padding-bottom: 18px;
        padding-top: 18px;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons {
        justify-content: center;
    }

    #footer .footer-content .footer .footer-left {
        width: 100%;
        margin-top: 40px;
    }

    .web-footer-text {
        display: none !important;
    }

    .btn-footer .icon {
        margin-right: 10px;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons a:first-child {
        margin-right: 10px;
    }

    .btn-footer {
        height: 48px;
        font-size: 13px;
        max-width: 150px;
        padding: 12px;
    }

    #footer .footer-content .footer .footer-right .footer-right-inner .right-inner-content {
        margin-bottom: 25px;
    }

    #footer strong {
        margin: 0 0 10px;
        font-size: 16px;
    }

    #footer .footer-content .footer .footer-left .footer-left-inner ul li {
        margin: 0 0 8px;
        font-size: 12px;
    }

    .user-guidelines li {
        font-size: 12px;
        margin-left: 7.5px;
        margin-right: 7.5px;
    }
}

@media (max-width:768px){
    #footer {
        padding: 40px 0 20px;
    }
    .user-guidelines {
        flex-direction: column;
        padding: 0 5px;
    }
    .suupport_help{
        order: 2;
    }
    .user-guidelines .ml-auto {
        margin-left: initial !important;
    }
    .btn-footer:hover {
        color: inherit !important;
    }
    #footer li a:hover {
        color: inherit !important;
    }
    #footer .footer-social-icons li a {
        width: 34px;
        height: 34px;
    }
    .input-holder input {
        height: 46px;
    }
    #footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn{
        width: 42px;
        height: 42px;
    }
    .footer-right-inner .title{
        font-size: 14px;
    }
}
.text-center {
  text-align: center;
}

.modal-wallet-error {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.modal-wallet-error-open {
  display: flex;
  z-index: 2000;
}

.modal-wallet-error-content {
  background-color: "#fff";
  background-color: aliceblue;
  background: '#fff';
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  margin: 0 14px;
  flex: content;
  flex-direction: column;
  position: relative; 
}
.modal-wallet-error-content p{
  color: #000;
}
.modal-wallet-error-content h5{
  color: #000 !important;
}
.modal-wallet-error-close-button {
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  color: #000;
  position:absolute;
}
.modal-common-close-button {
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  color: #000;
  position:absolute;
}